import { globalOperations } from "../../../duck";
import { api } from "../../../utilities/APIWrapper";
import Creators from "./actions";
const setLogout = globalOperations.setLogout;
const setLoading = globalOperations.setLoading;

const setCameraHomeInfo = Creators.setCameraHomeInfo;
const setCameraPages = Creators.setCameraPages;
const setCameraCurrentPage = Creators.setCameraCurrentPage;
const setCameraFeature = Creators.setCameraFeature;

const removeCurrentCameraFeature = Creators.removeCurrentCameraFeature;

const setFeatureItem = Creators.setFeatureItem;
const addFeatureItem = Creators.addFeatureItem;

const removeFeatureItem = Creators.removeFeatureItem;
const clearCurrentCameraPage = Creators.clearCurrentCameraPage;
const generateCurrPageObj = (dashboardItems, pageKey) => {
  const currPageItems = dashboardItems.filter(page => {
    if (page.parent === (pageKey ? pageKey : "home")) {
      return true;
    }
    return false;
  });

  if (!currPageItems) return null;
  let currPageName, parentKey;
  if (pageKey) {
    const currPageNameArray = dashboardItems.find(item => {
      return item.key === pageKey;
    }, null);

    if (currPageNameArray) {
      currPageName = currPageNameArray.name;
      parentKey = currPageNameArray.parent;
    } else {
      currPageName = "home";
      parentKey = null;
    }
  } else {
    currPageName = "home";
    parentKey = null;
  }

  return {
    pageKey,
    parentKey,
    currPageName,
    currPageItems
  };
};

const fetchInitCameraData = (homeId, pageKey, previousPath) => dispatch => {
  return new Promise((resolve, reject) => {
    const handleCameraPayload = data => {
      return new Promise((resolve, reject) => {
        const { houseName, guestId } = data.payload.propertyInfo;
        const { dashboard } = data.payload;

        if (houseName) dispatch(setCameraHomeInfo(houseName, guestId));

        dispatch(setCameraPages(dashboard.items));

        const currentPageObj = generateCurrPageObj(dashboard.items, "");

        //dispatch action to set current page info - set timeout to allow for page transition
        if (!currentPageObj || currentPageObj.currPageItems.length === 0) {
          reject("Couldnt find page");
        }

        resolve();
      });
    };

    dispatch(setLoading("init", "REQUEST"));

    api
      .getCameraInfo(homeId)
      .then(cameraPayload => {
        handleCameraPayload(cameraPayload).then(data => {
          dispatch(setLoading("init", "SUCCESS"));
          resolve(data);
        });
      })
      .catch(err => {
        dispatch(setLoading("init", "FAILED"));

        if (err.response) {
          if (err.response.status === 401 || err.response.status === 400) {
            api.manualLogout().then(() => {
              dispatch(setLogout());
              reject(err);
            });
          } else {
            reject(err);
          }
        } else {
          reject(err);
        }
      });
  });
};

const fetchCameraFeature = (houseId, featureId, featureKey, objectType) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("cameraFeature", "REQUEST"));
    api
      .getCameraFeature(houseId, featureId, featureKey)
      .then(data => {
        // const { key, name, type } = data.payload;

        dispatch(
          setCameraFeature(
            data.id,
            data.key,
            data.name,
            data,
            data.type,
            objectType,
            data.image,
            data.prefilled
          )
        );

        dispatch(setLoading("cameraFeature", "SUCCESS"));
        resolve(data);
      })
      .catch(err => {
        window.console.error(err);
        dispatch(setLoading("cameraFeature", "FAILED"));
        if (!err || !err.response) {
          reject(err);
          return;
        }

        if (err.response.status === 400 || err.response.status === 401) {
          api.manualLogout().then(() => {
            dispatch(setLogout());
            reject(err);
            return;
          });
        } else {
          reject(err);
        }
      });
  });
};

const changeCurrentCameraPage = (homeId, pageKey, prevPath) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("page", "REQUEST"));
    const { dashboardItems } = getState().camera;

    if (!dashboardItems) {
      dispatch(clearCurrentCameraPage(homeId));
      dispatch(setLoading("page", "FAILED"));
      reject("No page items available.");
    }

    const currentPageObj = generateCurrPageObj(dashboardItems, pageKey);
    if (!currentPageObj || currentPageObj.currPageItems.length <= 0) {
      dispatch(clearCurrentCameraPage(homeId));
      dispatch(setLoading("page", "FAILED"));
      reject("No page items available.");
      return;
    }
    dispatch(
      setCameraCurrentPage(
        parseInt(homeId),
        currentPageObj.pageKey,
        currentPageObj.parentKey,
        currentPageObj.currPageName,
        currentPageObj.currPageItems,
        prevPath
      )
    );
    dispatch(setLoading("page", "SUCCESS"));
    resolve();
  });
};

const updateFeatureItem = options => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateFeatureItem", "REQUEST"));
    api
      .updateFeatureItem(options)
      .then(featureItem => {
        if (options.featureItemId) {
          dispatch(setFeatureItem(featureItem));
        } else {
          dispatch(addFeatureItem(featureItem));
        }

        dispatch(setLoading("updateFeatureItem", "SUCCESS"));
        resolve(featureItem);
      })
      .catch(err => {
        dispatch(setLoading("updateFeatureItem", "FAILED"));
        if (!err.response || !err) {
          reject(err);
        } else {
          if (err.response.status === 400 || err.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(setLogout());
              reject(err);
            });
          } else {
            reject(err);
          }
        }
      });
  });
};

const deleteFeatureItem = (featureKey, featureId, featureItemId) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    const { currentHomeId } = getState().camera;

    dispatch(setLoading("deleteFeatureItem", "REQUEST"));
    api
      .deleteFeatureItem(currentHomeId, featureKey, featureId, featureItemId)
      .then(item => {
        dispatch(removeFeatureItem(featureId, featureItemId));
        dispatch(setLoading("deleteFeatureItem", "SUCCESS"));
        resolve(item);
      })
      .catch(err => {
        dispatch(setLoading("deleteFeatureItem", "FAILED"));
        if (!err || !err.response) {
          reject(err);
        } else {
          if (err.response.status === 401 || err.response.status === 400) {
            api.manualLogout().then(() => {
              dispatch(setLogout());
              reject(err);
            });
          } else {
            reject(err);
          }
        }
      });
  });
};

export default {
  setCameraHomeInfo,
  setCameraPages,
  setCameraCurrentPage,
  setCameraFeature,
  setFeatureItem,
  addFeatureItem,
  removeCurrentCameraFeature,
  removeFeatureItem,
  fetchInitCameraData,
  changeCurrentCameraPage,
  fetchCameraFeature,
  deleteFeatureItem,
  updateFeatureItem
};
