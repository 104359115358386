import React, { useState, useEffect } from "react";

import { ReactComponent as HCLogo } from "../common/svg/HC_Logo.svg";

import { connect } from "react-redux";

import { Link, Redirect } from "react-router-dom";
import { prePromptOperation } from "./duck";

import GuestCreatePageForm from "./GuestCreatePageForm";

import parseQuery from "stringquery";
import { Helmet } from "react-helmet";
import _get from "lodash.get";

import GuestHousePreview from "./GuestHousePreview";

function GuestCreatePage({
  queryParams,
  createGuest,
  getPendingGuestInfo,
  history,
  isLoggedIn,
}) {
  const [initializeLoading, setInitializeLoading] = useState(true);
  const [preHousePhoto, setPreHousePhoto] = useState(null);
  const [guestData, setGuestData] = useState(null);
  const [guestError, setGuestError] = useState({});

  const [currentStep, setCurrentStep] = useState(1);

  const handlePendingGuestInfo = ({
    firstName,
    email,
    lastName,
    housePhoto,
    houseName,
    regionName,
  }) => {
    setPreHousePhoto(housePhoto);
    setGuestData({ email, firstName, lastName, houseName, regionName });
    setInitializeLoading(false);
  };

  useEffect(() => {
    getPendingGuestInfo(queryParams.id)
      .then(handlePendingGuestInfo)
      .catch((error) => {
        if (_get(error, "response.status") === 401) {
          setGuestError({
            status: 401,
            text:
              "This account creation link is no longer available or has expired.",
          });
        } else if (_get(error, "response.status") === 418) {
		  window.location.href = '/manage/login';
		  return;
		} else {
          setGuestError({
            status: 401,
            text: "This account creation link is not valid.",
          });
        }
        setInitializeLoading(false);
      });
  }, [getPendingGuestInfo, queryParams.id]);

  const onSubmit = (values, actions) => {
    if (!queryParams.id) {
      return;
    }
    const { confirmPassword: password, firstName, lastName } = values;

    createGuest(queryParams.id, password, firstName, lastName)
      .then((mostRecentHouseId) => {
        actions.setSubmitting(false);
        history.push({
          state: { fadeTransition: false },
          pathname: `/app/${mostRecentHouseId}`,
        });
      })
      .catch((err) => {
        actions.setSubmitting(false);
      });
  };

  if (isLoggedIn) {
    return <Redirect to={`/app`} />;
  }

  if (currentStep === 1 && !guestError.status) {
    return (
      <>
        <Helmet>
          <title>Home Concierge | Create Account</title>
          <meta
            name="description"
            content="Create your Home Concierge guest account."
          />
        </Helmet>
        {initializeLoading === false && (
          <GuestHousePreview
            title={guestData.houseName}
            description={() => (
              <>
                Everything about YOUR vacation home <br></br>all in one place.
              </>
            )}
            isHawaii={guestData.regionName.toLowerCase() === "kona"}
            housePhoto={preHousePhoto}
            onSubmit={() => setCurrentStep(2)}
          />
        )}
      </>
    );
  }
  if (currentStep === 2 || guestError.status) {
    return (
      <>
        <div className="guest-create-page">
          <div className="guest-create-page__inner">
            <div className="guest-create-page__header">
              <div className="guest-create-page__brand">
                <HCLogo />
              </div>
              {guestData && (
                <>
                  <h5>Welcome to Home Concierge</h5>
                  <h4>{guestData.email}</h4>
                </>
              )}
            </div>

            {!guestError.status && guestData ? (
              <div className="guest-create-page__form">
                <GuestCreatePageForm
                  email={guestData.email}
                  firstName={guestData.firstName}
                  lastName={guestData.lastName}
                  onSubmit={onSubmit}
                />
              </div>
            ) : (
              <div className="guest-create-page__error">
                <p>{guestError.text}</p>
                <p>
                  Please contact{" "}
                  <a href="mailto:help@homeconcierge.com">
                    help@homeconcierge.com
                  </a>{" "}
                  for assistance.
                </p>
              </div>
            )}
            <div className="guest-create-page__signin">
              <p>
                Have an account? <Link to="/manage/login">Sign in here.</Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}

const mapDispatch = (dispatch) => ({
  createGuest: (guestId, password, firstName, lastName) =>
    dispatch(
      prePromptOperation.handleCreateGuest(
        guestId,
        password,
        firstName,
        lastName
      )
    ),
  getPendingGuestInfo: (uniqueId) =>
    dispatch(prePromptOperation.getPendingGuestInfo(uniqueId)),
});

const mapState = (state, { location }) => ({
  queryParams: parseQuery(location.search),
  isLoggedIn: _get(state.userReducer, "user.isLoggedIn", false),
});

export default connect(mapState, mapDispatch)(GuestCreatePage);
