const SET_CAMERA_HOME_INFO = "SET_CAMERA_HOME_INFO";
const SET_CAMERA_PAGES = "SET_CAMERA_PAGES";
const SET_CAMERA_CURRENT_PAGE = "SET_CAMERA_CURRENT_PAGE";
const SET_CAMERA_FEATURE = "SET_CAMERA_FEATURE";
const REMOVE_CURRENT_CAMERA_FEATURE = "REMOVE_CURRENT_CAMERA_FEATURE";
const SET_FEATURE_ITEM = "SET_FEATURE_ITEM";
const ADD_FEATURE_ITEM = "ADD_FEATURE_ITEM";
const REMOVE_FEATURE_ITEM = "REMOVE_FEATURE_ITEM";
const CLEAR_CURRENT_CAMERA_PAGE = "CLEAR_CURRENT_CAMERA_PAGE";
export default {
  SET_CAMERA_HOME_INFO,
  SET_CAMERA_PAGES,
  SET_CAMERA_CURRENT_PAGE,
  SET_CAMERA_FEATURE,
  REMOVE_CURRENT_CAMERA_FEATURE,
  SET_FEATURE_ITEM,
  ADD_FEATURE_ITEM,
  REMOVE_FEATURE_ITEM,
  CLEAR_CURRENT_CAMERA_PAGE
};
