import { api } from "../../../utilities/APIWrapper";
import Creators from "./actions";

const setCurrentSettingPage = Creators.setCurrentSettingPage;

const generateBrochureLink = (houseId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .generateBrochureLink(houseId)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  setCurrentSettingPage,
  generateBrochureLink,
};
