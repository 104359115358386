import { useState, useCallback } from "react";

const useGeolocation = (enabled = true) => {
  const [geo, setGeo] = useState(null);
  const [loading, setLoading] = useState(false);

  const prompt = useCallback(() => {
    if (!enabled) return;
    setLoading(true);
    const success = (position) => {
      const maps = window.google.maps;

      const geocoder = new maps.Geocoder();

      const geoCoords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      geocoder.geocode(
        {
          location: geoCoords,
        },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              setGeo({ ...results[0], name: "Your Location" });
              setLoading(false);
            }
          } else {
            setGeo(null);
            setLoading(false);
          }
        }
      );
    };

    const error = (err) => {
      console.error("Unable to retrieve location.");
      setLoading(false);
    };

    if (!window.navigator.geolocation) {
      console.error("Geolocation is not supported by your browser.");
      setLoading(false);
      return;
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }, [enabled]);

  return [prompt, loading, geo];
};

export default useGeolocation;
