import React, { Component } from "react";
import PropTypes from "prop-types";
import GeneralError from "./GeneralError";

export default class Tatl extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
      PropTypes.node
    ])
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    const { children } = this.props;

    if (!this.state.hasError) return children;

    return <GeneralError />;
  }
}
