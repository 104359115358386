import _get from "lodash.get";
import { createSelector } from "reselect";

const userOwnedHomesSelector = (state) => {
  const userManagedHomes = _get(state, "userReducer.houses", []).filter(
    ({ permission }) =>
      permission.type === "owner" || permission.type === "host"
  );

  return userManagedHomes;
};

const hasManagedHomeSelector = createSelector(
  [userOwnedHomesSelector],
  (userManagedHomes) => {
    if (userManagedHomes.length === 0) return false;
    return true;
  }
);

export default hasManagedHomeSelector;
