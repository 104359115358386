import React, { useContext } from "react";
import PropTypes from "prop-types";
import MUIModal from "@material-ui/core/Modal";
import ListItem from "./ListItem";
import { isInStandaloneMode } from "../../utilities/isMobileDevice";
import largeHCFaviconPNG from "../../assets/hc-favicon-large.png";
import { ReactComponent as LongArrowRightSVG } from "../../assets/long-arrow-right.svg";
import SafariIconPNG from "../../assets/safari-icon.png";
import PillButton from "./PillButton";
import { OutboundWarningContext } from "./OutboundWarningProvider";

function GenericWarningModal({ onClose, icon, url, dest = "Maps" }) {
  const { setWarningProvider } = useContext(OutboundWarningContext);

  const handleLinkClick = () => {
    setWarningProvider(false);
    window.open(url);
  };

  return (
    <MUIModal
      open={true}
      onClose={onClose}
      className="generic-warning-modal"
      disablePortal
      disableAutoFocus
      disableEnforceFocus
    >
      <div className="generic-warning-modal__inner">
        <div className="generic-warning-modal__top">
          <div className="outbound-image">
            <img src={largeHCFaviconPNG} alt="Home Concierge" />
          </div>
          <div className="outbound-exiting">
            <LongArrowRightSVG />
          </div>
          <div className="outbound-image">
            <img src={icon} alt="Home Concierge" />
          </div>
        </div>
        <div className="generic-warning-modal__body">
          <ul className="generic-warning-modal__bodylist">
            <ListItem index={1}>
              You are now leaving <br></br>
              <strong>Home Concierge</strong> to open the{" "}
              <strong>
                {dest}
                <img src={icon} alt={dest} />
              </strong>
              app.
            </ListItem>
            <ListItem index={2}>
              {isInStandaloneMode() ? (
                <>
                  Tap on the{" "}
                  <strong>
                    Home Concierge app
                    <img
                      src={largeHCFaviconPNG}
                      alt="Home Concierge app"
                    />{" "}
                  </strong>{" "}
                  to return to Home Concierge after you've reached your
                  destination.
                </>
              ) : (
                <>
                  Tap on{" "}
                  <strong>
                    Safari
                    <img src={SafariIconPNG} alt="Safari" />
                  </strong>
                  to return to Home Concierge after you've reached your
                  destination.
                </>
              )}
            </ListItem>
          </ul>
        </div>

        <div className="generic-warning-modal__buttons">
          <PillButton shape="rounded" color="blue" onClick={handleLinkClick}>
            Open app
          </PillButton>
        </div>
      </div>
    </MUIModal>
  );
}

GenericWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  dest: PropTypes.string,
};

export default GenericWarningModal;
