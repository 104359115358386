import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "./rootReducer";

import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { middlewareFeatureQueryChange } from "./queryParamsReducer";
import ReactGA from "react-ga4";

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    })) ||
  compose;

const history = createBrowserHistory();

history.listen(location => {
  setTimeout(() => {
	// Send pageview with a custom path
	ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: window.document.title });
  }, 500);
});

export default function configureStore(initialState = {}) {
  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        middlewareFeatureQueryChange,
        routerMiddleware(history)
      )
    )
  );
}

export { history };
