import isMobileDevice from "./isMobileDevice";
import detectOS from "./detectOS";
import analytics from "./GAHelper";

export const openDestMap = (src, dest) => {
  const daddr = dest;
  const saddr = src;

  if (!isMobileDevice()) {
    const googleUrl = new URL("https://www.google.com/maps/dir/");
    googleUrl.searchParams.append("api", 1);
    googleUrl.searchParams.append("destination", daddr);
    googleUrl.searchParams.append("origin", saddr);

    return googleUrl.href;
  }

  const appleUrl = new URL(`https://maps.apple.com/maps`);

  appleUrl.searchParams.append("saddr", saddr);
  appleUrl.searchParams.append("daddr", daddr);
  appleUrl.searchParams.append("dirflg", "d");

  return appleUrl.href;
};

const openMap = (src) => {
  // if only src was given, use single point

  // if not on mobile device, send to a google maps search.
  if (!isMobileDevice()) {
    const googleUrl = new URL(`https://google.com/maps/search/`);

    googleUrl.searchParams.append("api", 1);
    googleUrl.searchParams.append("query", src);

    return googleUrl.href;
  }

  if (detectOS() !== "ios") {
    analytics.sendFeatureAction("Outbound Directions - Android/Other", src);
  } else {
    analytics.sendFeatureAction("Outbound Directions - iOS", src);
  }

  const appleUrl = new URL(`https://maps.apple.com/maps`);

  appleUrl.searchParams.append("q", src);

  return appleUrl.href;
};

export default openMap;
