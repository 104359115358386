import React, { useEffect, useRef } from "react";

function PullToRefresh({
  children,
  onRefresh,
  resistance = 6,
  distanceToRefresh = 25
}) {
  const contentEl = useRef(document.body);
  const ptrEl = useRef();

  const startY = useRef(null);
  const panEnabled = useRef(false);
  const distance = useRef(0);
  const touchY = useRef(null);
  const loading = useRef(false);

  useEffect(() => {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;

    if (isAndroid) return;
    const doLoading = () => {
      if (loading.current === true) return;
      loading.current = true;
      document.body.classList.add("ptr-loading");

      // If no valid loading function exists, just reset elements
      if (!onRefresh) {
        return doReset();
      }

      // The loading function should return a promise
      var loadingPromise = onRefresh();

      // For UX continuity, make sure we show loading for at least one second before resetting
      setTimeout(function() {
        // Once actual loading is complete, reset pull to refresh
        loadingPromise.then(doReset);
      }, 1000);
    };

    const doReset = () => {
      document.body.classList.remove("ptr-loading");
      document.body.classList.remove("ptr-refresh");
      document.body.classList.add("ptr-reset");

      var bodyClassRemove = function() {
        document.body.classList.remove("ptr-reset");
        document.body.removeEventListener(
          "transitionend",
          bodyClassRemove,
          false
        );
        loading.current = false;
      };

      document.body.addEventListener("transitionend", bodyClassRemove, false);
    };

    const setContentPan = () => {
      if (!contentEl.current) return;

      contentEl.current.style.transform = contentEl.current.style.webkitTransform =
        "translate3d( 0, " + distance.current + "px, 0 )";

      ptrEl.current.style.transform = ptrEl.current.style.webkitTransform =
        "translate3d( 0, " +
        (distance.current - ptrEl.current.offsetHeight) +
        "px, 0 )";
    };

    const setBodyClass = () => {
      if (distance.current > distanceToRefresh) {
        document.body.classList.add("ptr-refresh");
      } else {
        document.body.classList.remove("ptr-refresh");
      }
    };

    if (!contentEl.current || !ptrEl.current) return;

    contentEl.current.addEventListener("touchstart", e => {
      startY.current = document.body.scrollTop;
      touchY.current = e.touches[0].clientY;
      if (startY.current === 0) {
        panEnabled.current = true;
      }
    });

    contentEl.current.addEventListener("touchmove", e => {
      if (!panEnabled.current) return;

      const moveDistance = e.changedTouches[0].clientY - touchY.current;
      // if the move distance is greater than 0, that means pan was up
      // set the current distance to the changed distance / resistance,
      // then set the content transform and the body classes necessary
      if (moveDistance > 0) {
        distance.current = moveDistance / resistance;

        setContentPan();
        setBodyClass();
      }
    });
    contentEl.current.addEventListener("touchend", e => {
      if (!panEnabled.current) return;

      // reset the transforms at end of touch end
      // (the ptr-refresh css class handles the rest of the transforms)
      contentEl.current.style.transform = contentEl.current.style.webkitTransform =
        "";
      ptrEl.current.style.transform = ptrEl.current.style.webkitTransform = "";

      // if the body has ptr refresh, handle loading. otherwise, reset all calsses
      if (document.body.classList.contains("ptr-refresh")) {
        doLoading();
      } else {
        doReset();
      }

      // reset all references
      touchY.current = null;
      distance.current = 0;
      panEnabled.current = false;
    });
  }, [contentEl, distanceToRefresh, onRefresh, resistance]);

  return (
    <div className="ptr">
      <div className="ptr__inner">
        <div className="ptr__loader" ref={ptrEl} style={{ color: "white" }}>
          <p style={{ marginRight: "15px" }}>Updating app</p>
          <span id="l1"></span>
          <span id="l2"></span>
          <span id="l3"></span>
        </div>
        <div className="ptr__content" ref={contentEl}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default PullToRefresh;
