import { useEffect, useCallback } from "react";

const useKeyboardNavigation = options => {
  const { currentDataKey, itemCount, onNext, onPrev } = options;

  const handleKeyBoardNavigation = useCallback(
    e => {
      const key = e.which || e.keyCode;
      switch (key) {
        case 37: {
          if (currentDataKey > 0) {
            onPrev(currentDataKey - 1);
          }
          break;
        }
        case 39: {
          if (currentDataKey + 1 < itemCount) {
            onNext(currentDataKey + 1);
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [currentDataKey, itemCount, onNext, onPrev]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyBoardNavigation);
    return () => {
      document.removeEventListener("keydown", handleKeyBoardNavigation);
    };
  }, [handleKeyBoardNavigation]);

  if (!options) return;
};

export default useKeyboardNavigation;
