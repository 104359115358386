import types from "./types";

export default function toasts(state = [], action) {
  const { payload, type } = action;
  switch (type) {
    case types.ADD_TOAST: {
      return [payload, ...state];
    }
    case types.REMOVE_TOAST: {
      return state.filter(toast => toast.id !== payload);
    }
    default: {
      return state;
    }
  }
}
