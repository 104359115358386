import React, { useState, useEffect } from "react";
import SharePage from "./SharePage";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { globalOperations } from "../../../duck";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { toastOperations } from "../../toast/duck";
import PillButton from "../../common/PillButton";
import { Modal, ModalProvider } from "../../common/Modal";
import ErrorRenderer from "../../common/ErrorRenderer";

function DeleteGuestPrompt({
  onClose,
  isLoading,
  onSubmit,
  guestName,
  currentHomeName,
}) {
  return (
    <Modal onClose={onClose}>
      <div className="delete-guest-prompt">
        <div className="delete-guest-prompt__inner">
          <div className="delete-guest-prompt__header">
            <h4>
              Are you sure you want to remove <strong>{guestName}</strong> from{" "}
              <strong>{currentHomeName}</strong>?
            </h4>
            <p>
              This user will no longer have access to the house in their app.
            </p>
          </div>
          <div className="delete-guest-prompt__cta">
            <button className="cta-button" onClick={onSubmit}>
              {isLoading ? "Removing..." : "Yes, Delete Guest"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function InviteGuestPrompt({ onSubmit, onClose, isLoading, guests }) {
  const InviteGuestPromptValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string()
      .email("Must be a valid email.")
      .test(
        "unique-email",
        "This guest is already invited!", // eslint-disable-line no-template-curly-in-string
        (value) => {
          if (!guests) return true;

          const findEmail = guests.find(
            ({ email }) =>
              email.toLowerCase() === (value ? value.toLowerCase() : null)
          );

          return !Boolean(findEmail);
        }
      )
      .required("Email is required."),
  });

  return (
    <Modal onClose={onClose}>
      <div className="invite-prompt">
        <div className="invite-prompt__inner">
          <div className="invite-prompt__header">
            <h3>Invite Friends/Family</h3>
            <p>
              Your guest will receive an email to set up their account or log in
              to their existing account.
            </p>
          </div>
          <div className="invite-prompt__form">
            <Formik
              onSubmit={onSubmit}
              validationSchema={InviteGuestPromptValidationSchema}
              initialValues={{ firstName: "", lastName: "", email: "" }}
            >
              {({
                values,
                handleBlur,
                handleChange,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit} className="invite-prompt-form">
                  <ErrorRenderer errors={errors} touched={touched} />
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name*"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name*"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="email"
                    placeholder="Email Address*"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <PillButton className="cta-button" type="submit">
                    {isLoading ? "Inviting... " : "Invite Guest"}
                  </PillButton>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}

InviteGuestPrompt.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

function SharePopup({
  invitedGuests,
  shareWithGuest,
  inviteGuestLoading,
  currentHomeId,
  deleteSecondaryGuest,
  currentGuestId,
  deleteGuestLoading,
  addToast,
  currentHomeName,
}) {
  const [showInvitePrompt, setShowInvitePrompt] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [queuedData, setQueuedData] = useState(null);

  useEffect(() => {
    const popupContentEl = document.querySelector(".popup-content");
    disableBodyScroll(popupContentEl);
    return () => {
      enableBodyScroll(popupContentEl);
    };
  }, []);

  const inviteGuest = ({ email, firstName, lastName }) => {
    shareWithGuest(currentGuestId, email, firstName, lastName)
      .then(() => {
        addToast({
          intent: "success",
          text: `Invited guest "${firstName} ${lastName}" to ${currentHomeName}`,
        });
        setShowInvitePrompt(false);
      })
      .catch((error) => {
        window.console.error(error);
        addToast({
          intent: "error",
          text: error.message
            ? error.message
            : "There was an error while inviting guest. Please try again.",
        });
      });
  };

  const handleShowDeletePrompt = (guestData) => {
    setShowDeletePrompt(true);
    setQueuedData(guestData);
  };

  const deleteGuest = () => {
    deleteSecondaryGuest(queuedData.id)
      .then(() => {
        addToast({
          intent: "success",
          text: `Removed guest "${queuedData.firstName} ${queuedData.lastName}" from ${currentHomeName}`,
        });
        setShowDeletePrompt(false);
        setQueuedData(null);
      })
      .catch((error) => {
        window.console.error(error);
        addToast({
          intent: "error",
          text: "There was an error while deleting guest. Please try again.",
        });
      });
  };

  return (
    <div className="popup-content-inner" data-testid="share-popup">
      <ModalProvider>
        <SharePage
          guests={invitedGuests}
          showInvitePrompt={() => setShowInvitePrompt(true)}
          showDeletePrompt={handleShowDeletePrompt}
          currentHomeId={currentHomeId}
        />
        {showInvitePrompt && (
          <InviteGuestPrompt
            guests={invitedGuests}
            isLoading={inviteGuestLoading}
            onSubmit={inviteGuest}
            onClose={() => setShowInvitePrompt(false)}
          />
        )}
        {showDeletePrompt && (
          <DeleteGuestPrompt
            isLoading={deleteGuestLoading}
            onSubmit={deleteGuest}
            currentHomeName={currentHomeName}
            guestName={`${queuedData.firstName} ${queuedData.lastName}`}
            onClose={() => {
              setShowDeletePrompt(false);
              setQueuedData(null);
            }}
          />
        )}
      </ModalProvider>
    </div>
  );
}

SharePopup.propTypes = {
  invitedGuests: PropTypes.array.isRequired,
  shareWithGuest: PropTypes.func.isRequired,
  inviteGuestLoading: PropTypes.bool.isRequired,
  currentHomeId: PropTypes.number.isRequired,
};

const mapState = (state) => ({
  invitedGuests: state.userReducer.invitedGuests,
  inviteGuestLoading: _get(
    state.loadingReducer,
    "inviteSecondaryGuest.loading",
    false
  ),
  deleteGuestLoading: _get(
    state.loadingReducer,
    "deleteSecondaryGuest.loading",
    false
  ),
  currentHomeId: _get(state.dashboardReducer, "currentHomeId", null),
  currentGuestId: _get(state.dashboardReducer, "propertyInfo.guestId", null),
  currentHomeName: _get(
    state.dashboardReducer,
    "propertyInfo.propertyName",
    null
  ),
  guestType: _get(state.dashboardReducer, "propertyInfo.guestType", null),
});

const mapDispatch = (dispatch) => ({
  shareWithGuest: (guestId, email, firstName, lastName) =>
    dispatch(
      globalOperations.createSecondaryGuest(guestId, email, firstName, lastName)
    ),
  deleteSecondaryGuest: (guestId) =>
    dispatch(globalOperations.deleteSecondaryGuest(guestId)),
  addToast: (options) => dispatch(toastOperations.addToast(options)),
});

export default withRouter(connect(mapState, mapDispatch)(SharePopup));
