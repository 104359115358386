import React, { useEffect } from "react";

import PropTypes from "prop-types";
import RootContent from "./settings_pages/RootContent";
import HelpContent from "./settings_pages/HelpContent";
import TermsContent from "./settings_pages/TermsContent";
import PrivacyPolicyContent from "./settings_pages/PrivacyPolicyContent";
import SwitchHouses from "../houses/SwitchHouses";
import FAQContent from "./settings_pages/FAQContent";
import ContactContent from "./settings_pages/ContactContent";
import { connect } from "react-redux";
import { settingsPageOperations } from "./duck";

import { Helmet } from "react-helmet";
import SendBrochureContent from "./settings_pages/SendBrochureContent";

function SettingsInnerPage({
  settingKey,
  settingName,
  backPath,
  setCurrentSettingPage,
  houses,
}) {
  useEffect(() => {
    setCurrentSettingPage(
      settingKey,
      settingName ? settingName : "More",
      backPath
    );
  }, [settingKey, backPath, setCurrentSettingPage, settingName]);

  switch (settingKey) {
    case "more": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings</title>
            <meta
              name="description"
              content="Manage your account and other settings."
            />
          </Helmet>
          <RootContent />
        </>
      );
    }
    case "help": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings | {settingName}</title>
            <meta
              name="description"
              content="Information for contacting Home Concierge"
            />
          </Helmet>
          <HelpContent />
        </>
      );
    }
    case "contact": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings | {settingName}</title>
            <meta
              name="description"
              content="Contact us with any questions or concerns"
            />
          </Helmet>
          <ContactContent />
        </>
      );
    }
    case "faq": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings | {settingName}</title>
            <meta name="description" content="Frequently Asked Questions" />
          </Helmet>
          <FAQContent />
        </>
      );
    }
    case "privacy-policy": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings | {settingName}</title>
            <meta name="description" content="Home Concierge Privacy Policy" />
          </Helmet>
          <PrivacyPolicyContent />
        </>
      );
    }
    case "terms-and-conditions": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings | {settingName}</title>
            <meta
              name="description"
              content="Home Concierge Terms and Conditions"
            />
          </Helmet>
          <TermsContent />
        </>
      );
    }

    case "switch-houses": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings | {settingName}</title>
            <meta name="description" content="Switch your home" />
          </Helmet>
          <SwitchHouses houses={houses} />
        </>
      );
    }

    case "send-brochure": {
      return (
        <>
          <Helmet>
            <title>Home Concierge | Settings | {settingName}</title>
            <meta name="description" content="Send a brochure invite" />
          </Helmet>
          <SendBrochureContent />
        </>
      );
    }

    default: {
      return <p>Cant find settings page info.</p>;
    }
  }
}
const mapDispatch = (dispatch) => ({
  setCurrentSettingPage: (key, name, backPath) =>
    dispatch(settingsPageOperations.setCurrentSettingPage(key, name, backPath)),
});

const mapState = (state) => ({
  houses: state.userReducer.houses,
});

SettingsInnerPage.propTypes = {
  settingKey: PropTypes.string,

  generateRoutes: PropTypes.func,
};
export default connect(mapState, mapDispatch)(SettingsInnerPage);
