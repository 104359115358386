import types from "./types";

const initialStateSettingsPage = {
  currentSettingPage: {
    name: null,
    key: null,
    backPath: null
  },
  cameraToggle: false
};

const settingsPageReducer = (state = initialStateSettingsPage, action) => {
  switch (action.type) {
    case types.SET_CURRENT_SETTING_PAGE: {
      return {
        ...state,
        currentSettingPage: {
          key: action.key,
          name: action.name,
          backPath: action.backPath
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default settingsPageReducer;
