import React from "react";
import PropTypes from "prop-types";

import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute.js";

function RouteGenerator({ routes, ...rest }) {
  return (
    <Switch {...rest}>
      {routes.map(({ key, title, path, component, auth, ...rest }) =>
        auth ? (
          <PrivateRoute key={key} path={path} component={component} {...rest} />
        ) : (
          <Route key={key} path={path} component={component} {...rest} />
        )
      )}
    </Switch>
  );
}

RouteGenerator.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
        .isRequired,
      auth: PropTypes.bool
    })
  )
};

export default RouteGenerator;
