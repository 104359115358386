import React from "react";
import { Route, Switch } from "react-router-dom";

import GuestCreatePage from "./GuestCreatePage";
import GuestLoginLink from "./GuestLoginLink";
import GuestHouseRegisterPage from "./GuestHouseRegisterPage";
import HostCreatePage from "./HostCreatePage";
import BrochureCreatePage from "./BrochureCreatePage";

function PrePromptRoot() {
  return (
    <div className="preprompt-root">
      <div className="preprompt-root__content">
        <Switch>
          <Route path="/manage/create-host" component={HostCreatePage} />
          <Route
            path="/manage/brochure-registration"
            component={BrochureCreatePage}
          />
          <Route path="/manage/create" component={GuestCreatePage} />
          <Route
            path="/manage/house-registration"
            component={GuestHouseRegisterPage}
          />
          <Route path="/manage/login" component={GuestLoginLink} />
          <Route render={() => <p>No Page Found</p>} />
        </Switch>
      </div>
    </div>
  );
}

export default PrePromptRoot;
