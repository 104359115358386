import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";
import BannerHeader from "../BannerHeader";
import { useEffect } from "react";
import { OrientationContext } from "../../common/OrientationProvider";
import FeatureVideo from "../../featurepage/FeatureVideo";

function VideoPopup({ removePopup, title = "Video", videoUrl }) {
  const { disable, enable } = useContext(OrientationContext);
  useEffect(() => {
    disable();
    return () => {
      enable();
    };
  }, [disable, enable]);
  return (
    <div className="popup-content-inner" data-testid="video-popup">
      <BannerHeader onClose={removePopup} cta="close" contentAlign={"center"}>
        {title}
      </BannerHeader>
      <FeatureVideo url={videoUrl} />
    </div>
  );
}

VideoPopup.propTypes = {
  removePopup: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string
};

const mapState = state => ({
  videoUrl: _get(
    state.dashboardReducer,
    `currentFeature.featureData.items[${_get(
      state.dashboardReducer,
      "currentFeature.featureDataKey",
      ""
    )}].video_url`,
    null
  )
});

export default connect(mapState)(VideoPopup);
