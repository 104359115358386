import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function Toast({ id, intent, text, onDismiss, removeToast }) {
  useEffect(() => {
    if (id) {
      setTimeout(() => {
        removeToast(id);
      }, 5000);
    }
  }, [removeToast, id]);

  const createRole = intent => {
    switch (intent) {
      case "error":
      case "success":
      case "warning":
        return `${intent}-toast`;
      default:
        return "generic-toast";
    }
  };
  return (
    <li
      className={clsx({
        toast: true,
        [`toast--${intent}`]: true
      })}
      role={createRole(intent)}
      data-testid="toast"
    >
      <p className="toast__content">{text}</p>
      <button className="toast__dismiss" onClick={onDismiss} />
    </li>
  );
}

Toast.propTypes = {
  intent: PropTypes.string,
  text: PropTypes.string,
  onDismiss: PropTypes.func,
  removeToast: PropTypes.func,
  id: PropTypes.number
};

export default React.memo(Toast, () => {
  return false;
});
