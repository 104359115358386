import { isIOS } from "./isMobileDevice";

let textArea;

function createTextArea(text) {
  textArea = document.createElement("textArea");
  textArea.value = text;
  document.body.appendChild(textArea);
}
function selectText() {
  let range, selection;

  if (isIOS()) {
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }
}

function copyToClipboard() {
  document.execCommand("copy");
  document.body.removeChild(textArea);
}

export default {
  copy: (text) => {
    createTextArea(text);
    selectText();
    copyToClipboard();
  },
};
