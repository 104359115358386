import { ReactComponent as websiteSVG } from "./svg/featurenav/website-feature-nav-icon.svg";
import { ReactComponent as reservationsSVG } from "./svg/featurenav/reservations-feature-nav-icon.svg";
import { ReactComponent as shareSVG } from "./svg/dashboardnav/ShareDash.svg";
import { ReactComponent as directionsSVG } from "./svg/featurenav/directions.svg";

import { ReactComponent as contactHostSVG } from "./svg/featurenav/contact-host-feature-nav-icon.svg";
import { ReactComponent as contactChefSVG } from "./svg/featurenav/ChefNav.svg";
import { ReactComponent as contactMasseuseSVG } from "./svg/featurenav/MasseuseNav.svg";
import { ReactComponent as contactTourGuideSVG } from "./svg/featurenav/TourNav.svg";
import { ReactComponent as contactPersonalShopperSVG } from "./svg/featurenav/PrivateShoppersNav.svg";
import { ReactComponent as islandGuideSVG } from "./svg/dashboardnav/GuideBook.svg";
import { ReactComponent as homeSVG } from "./svg/dashboardnav/HomeButton.svg";
import { ReactComponent as DashHamburgerNavSVG } from "./svg/dashboardnav/Hamburger.svg";
import { ReactComponent as callSVG } from "./svg/featurenav/Call.svg";
import { ReactComponent as inventorySVG } from "./svg/featurenav/repair.svg";

import { ReactComponent as MorePlaySVG } from "./svg/dashboardnav/moreicons/MorePlay.svg";
import { ReactComponent as MoreDineSVG } from "./svg/dashboardnav/moreicons/MoreDine.svg";
import { ReactComponent as MoreSpaSVG } from "./svg/dashboardnav/moreicons/MoreSpa.svg";
import { ReactComponent as MoreShopSVG } from "./svg/dashboardnav/moreicons/MoreShop.svg";

import { ReactComponent as MoreAdventuresSVG } from "./svg/dashboardnav/moreicons/MoreAdventures.svg";
import { ReactComponent as MoreBeachesSVG } from "./svg/dashboardnav/moreicons/MoreBeaches.svg";
import { ReactComponent as MoreBikingSVG } from "./svg/dashboardnav/moreicons/MoreBiking.svg";
import { ReactComponent as MoreDivingSVG } from "./svg/dashboardnav/moreicons/MoreDiving.svg";
import { ReactComponent as MoreEntertainmentSVG } from "./svg/dashboardnav/moreicons/MoreEntertainment.svg";
import { ReactComponent as MoreGolfSVG } from "./svg/dashboardnav/moreicons/MoreGolf.svg";
import { ReactComponent as MoreHikingSVG } from "./svg/dashboardnav/moreicons/MoreHiking.svg";
import { ReactComponent as MoreLuauSVG } from "./svg/dashboardnav/moreicons/MoreLuau.svg";
// import MoreRentalSVG from './svg/dashboardnav/moreicons/MoreRental.svg';
import { ReactComponent as MoreSightseeingSVG } from "./svg/dashboardnav/moreicons/MoreSightseeing.svg";
import { ReactComponent as MoreSnorkelSVG } from "./svg/dashboardnav/moreicons/MoreSnorkel.svg";
import { ReactComponent as MoreSurfSUPSVG } from "./svg/dashboardnav/moreicons/MoreSurfSUP.svg";
import { ReactComponent as MoreVolcanoLavaSVG } from "./svg/dashboardnav/moreicons/MoreVolcanoLava.svg";
import { ReactComponent as PersonalShopperSVG } from "./svg/dashboardnav/moreicons/PersonalShopperMORE.svg";
import { ReactComponent as PrivateChefSVG } from "./svg/dashboardnav/moreicons/PrivateChefMore.svg";
import { ReactComponent as PrivateToursSVG } from "./svg/dashboardnav/moreicons/PrivateToursMore.svg";
import { ReactComponent as RentalsSVG } from "./svg/dashboardnav/moreicons/RentalMORE.svg";

import { ReactComponent as backToAppSVG } from "./svg/dashboardnav/backToApp.svg";

import { ReactComponent as videoSVG } from "./svg/dashboardnav/video.svg";

import { ReactComponent as directorySVG } from "./svg/dashboardnav/directory.svg";
import { ReactComponent as menuSVG } from "./svg/dashboardnav/menu.svg";

const website = {
  key: "website",
  name: "Website",
  icon: websiteSVG,
};
const home = {
  key: "home",
  name: "Home",
  icon: homeSVG,
};
const islandGuide = {
  key: "island_guide",
  name: "Island Guide",
  icon: islandGuideSVG,
};

const reservations = {
  key: "reservations",
  name: "Reservations",
  icon: reservationsSVG,
};

const directory = {
  key: "directory",
  name: "Directory",
  icon: directorySVG,
};

const menu = {
  key: "menu",
  name: "Menu",
  icon: menuSVG,
};

const directions = {
  key: "directions",
  name: "Directions",
  icon: directionsSVG,
};

const contactHost = {
  key: "contact_host",
  name: "Contact Host",
  icon: contactHostSVG,
};

const contactPerson = {
  key: "contact_person",
  name: "Contact Person",
  icon: contactHostSVG,
};

const contactChef = {
  key: "contact_chef",
  name: "Contact Chef",
  icon: contactChefSVG,
};

const contactMasseuse = {
  key: "contact_masseuse",
  name: "Contact Therapist",
  icon: contactMasseuseSVG,
};

const contactTourGuide = {
  key: "contact_tour_guide",
  name: "Contact Tour Guide",
  icon: contactTourGuideSVG,
};

const contactPersonalShopper = {
  key: "contact_shopper",
  name: "Contact Shopper",
  icon: contactPersonalShopperSVG,
};

const backToApp = {
  key: "backToApp",
  name: "Back To App",
  icon: backToAppSVG,
};

const MORE_play = {
  key: "MORE_play",
  name: "More play",
  icon: MorePlaySVG,
};

const MORE_dine = {
  key: "MORE_dine",
  name: "More dine",
  icon: MoreDineSVG,
};
const MORE_spa = {
  key: "MORE_spa",
  name: "More spa",
  icon: MoreSpaSVG,
};

const MORE_beaches = {
  key: "MORE_beaches",
  name: "More beaches",
  icon: MoreBeachesSVG,
};

const MORE_sightseeing = {
  key: "MORE_sightseeing",
  name: "More sightseeing",
  icon: MoreSightseeingSVG,
};

const MORE_golf = {
  key: "MORE_golf",
  name: "More golf",
  icon: MoreGolfSVG,
};

const MORE_surfSUP = {
  key: "MORE_surfSUP",
  name: "More Surf/SUP",
  icon: MoreSurfSUPSVG,
};

const MORE_snorkelling = {
  key: "MORE_snorkelling",
  name: "More Snorkelling",
  icon: MoreSnorkelSVG,
};

const MORE_diving = {
  key: "MORE_diving",
  name: "More diving",
  icon: MoreDivingSVG,
};

const MORE_biking = {
  key: "MORE_biking",
  name: "More biking",
  icon: MoreBikingSVG,
};

const MORE_hiking = {
  key: "MORE_hiking",
  name: "More hiking",
  icon: MoreHikingSVG,
};

const MORE_adventures = {
  key: "MORE_adventures",
  name: "More adventures",
  icon: MoreAdventuresSVG,
};

const MORE_entertainment = {
  key: "MORE_entertainment",
  name: "More entertainment",
  icon: MoreEntertainmentSVG,
};

const MORE_classicHawaii = {
  key: "MORE_classicHawaii",
  name: "More Classic Hawaii",
  icon: MoreLuauSVG,
};

const MORE_volcanoLava = {
  key: "MORE_volcanoLava",
  name: "More Volcano",
  icon: MoreVolcanoLavaSVG,
};

const MORE_shop = {
  key: "MORE_shop",
  name: "More shop",
  icon: MoreShopSVG,
};

const MORE_privateChefs = {
  key: "MORE_privateChefs",
  name: "More Private Chefs",
  icon: PrivateChefSVG,
};

const MORE_privateTours = {
  key: "MORE_privateTours",
  name: "More Private Tours",
  icon: PrivateToursSVG,
};

const MORE_personalShopper = {
  key: "MORE_personalShopper",
  name: "More Personal Shopper",
  icon: PersonalShopperSVG,
};

const MORE_privateMasseuse = {
  key: "MORE_privateMasseuse",
  name: "More Private Masseuse",
  // replace with proper icon
  icon: MoreSpaSVG,
};

const MORE_rentals = {
  key: "MORE_rentals",
  name: "More Rentals",
  icon: RentalsSVG,
};

const more = {
  key: "more",
  name: "More",
  icon: DashHamburgerNavSVG,
};
const share = {
  key: "share",
  name: "Share",
  icon: shareSVG,
};

const book_now = {
  key: "book_now",
  name: "Book Now",
  icon: shareSVG,
};

const shareWithFamily = {
  key: "shareWithFamily",
  name: "Share",
  icon: shareSVG,
};

const call = {
  key: "call",
  name: "Call",
  icon: callSVG,
};

const video = {
  key: "video",
  name: "Video",
  icon: videoSVG,
};

const userManual = {
  key: "manual-website",
  name: "Website",
  icon: websiteSVG,
};

const inventory = {
  key: "inventory",
  name: "Repair",
  icon: inventorySVG,
};

export default {
  website,
  reservations,
  shareWithFamily,
  share,
  directions,
  contactHost,
  contactChef,
  contactMasseuse,
  contactPerson,
  contactPersonalShopper,
  islandGuide,
  home,
  more,
  call,
  MORE_dine,
  MORE_play,
  MORE_spa,
  MORE_shop,
  MORE_beaches,
  MORE_sightseeing,
  MORE_golf,
  MORE_surfSUP,
  MORE_snorkelling,
  MORE_diving,
  MORE_biking,
  MORE_hiking,
  MORE_adventures,
  MORE_entertainment,
  MORE_classicHawaii,
  MORE_volcanoLava,
  MORE_personalShopper,
  MORE_privateChefs,
  MORE_privateTours,
  MORE_privateMasseuse,
  MORE_rentals,
  backToApp,
  video,
  userManual,
  directory,
  menu,
  contactTourGuide,
  book_now,
  inventory,
};
