import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as HCLogo } from "../common/svg/HC_Logo.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../common/Input";
import { prePromptOperation } from "./duck";
import { connect } from "react-redux";
import _get from "lodash.get";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQueryParam, NumberParam } from "use-query-params";
import PillButton from "../common/PillButton";
import ErrorRenderer from "../common/ErrorRenderer";

function SuccessMessage({ email }) {
  return (
	<div className="guest-forgot-success" data-testid="forgot-password-success">
	  <h4>Thank you.</h4>
	  <p>
		An email has been sent to <strong>{email}</strong> with a link to click and login.
	  </p>
	</div>
  );
}

function GuestLoginLinkForm({ onSubmit, loading }) {
  return (
	<div className="guest-forgot-form">
	  <Formik
		onSubmit={onSubmit}
		validationSchema={Yup.object().shape({
		  email: Yup.string()
			.email("Must be a valid email address")
			.required("Email address is required.")
		})}
		initialValues={{
		  email: ""
		}}
	  >
		{({
			handleBlur,
			errors,
			handleChange,
			values,
			handleSubmit,
			touched
		  }) => (
		  <>
			<ErrorRenderer touched={touched} errors={errors} />
			<form onSubmit={handleSubmit}>
			  <div className="guest-forgot-form__fields">
				<Input
				  type="email"
				  name="email"
				  placeholder="Email Address*"
				  value={values.email}
				  handleBlur={handleBlur}
				  handleChange={handleChange}
				/>
			  </div>
			  <div className="guest-forgot-form__text">
				<p>
				  Enter and submit your email address<br />to receive an email with a login link.
				</p>
			  </div>
			  <div className="guest-forgot-form__submit">
				<PillButton type="submit">
				  {loading ? <div className="spinner-loader"/> : "Click Here For An Updated Link"}
				</PillButton>
			  </div>
			</form>
		  </>
		)}
	  </Formik>
	</div>
  );
}

function GuestLoginLink({
    user,
    houses,
    isLoggedIn,
  	sendLoginLink,
  	sendLoginLinkLoading
  }) {
  const [houseRedirectParam] = useQueryParam("houseRedirect", NumberParam);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [sentEmailAddr, setSentEmailAddr] = useState(null);
  const onSubmit = ({ email }) => {
	sendLoginLink(email)
	  .then(() => {
		setShowSuccessMessage(true);
		setSentEmailAddr(email);
	  })
	  .catch(err => {
		window.console.error(err);
	  });
  };

  if (isLoggedIn && houses) {
	if (houses.length > 0) {
	  const mostRecentHouseId = houses[0].id;
	  return (
		<Redirect
		  to={`/app/${
			houseRedirectParam ? houseRedirectParam : mostRecentHouseId
		  }`}
		/>
	  );
	}
  }

  return (
	<>
	  <Helmet>
		<title>Home Concierge | Login</title>
		<meta
		  name="description"
		  content="Enter your email to receive a login link."
		/>
	  </Helmet>
	  <div className="guest-forgot-page">
		<div className="guest-forgot-page__inner">
		  <div className="guest-forgot-page__header">
			<div className="guest-forgot-page__brand">
			  <HCLogo />
			</div>
			<h4>We have made improvements<br /> to Home Concierge</h4>
		  </div>

		  <div className="guest-forgot-page__form">
			{showSuccessMessage === false ? (
			  <GuestLoginLinkForm
				onSubmit={onSubmit}
				loading={sendLoginLinkLoading}
			  />
			) : (
			  <SuccessMessage email={sentEmailAddr} />
			)}
		  </div>
		</div>
	  </div>
	</>
  );
}

GuestLoginLink.propTypes = {
  loginUser: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.object,
  houses: PropTypes.array,
};

const mapState = state => ({
  sendLoginLinkLoading: _get(
	state.loadingReducer,
	"sendLoginLink.loading",
	false
  ),
  houses: state.userReducer.houses,
  isLoggedIn: _get(state.userReducer, "user.isLoggedIn", false),
});

const mapDispatch = dispatch => ({
  sendLoginLink: email =>
	dispatch(prePromptOperation.sendLoginLink(email))
});

export default connect(mapState, mapDispatch)(GuestLoginLink);
