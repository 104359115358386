import React, { useEffect } from "react";
import PropTypes from "prop-types";

import scriptLoader from "react-async-script-loader";
import { useHistory } from "react-router-dom";

function TidioProvider({
  children,
  isScriptLoaded,
  isScriptLoadSucceed,
  enabledPath,
}) {
  const history = useHistory();

  useEffect(() => {
    if (!isScriptLoaded) return;
    if (!isScriptLoadSucceed) return;
    if (!window.tidioChatApi) return;

    const setTidioChatClasses = (element, location, enabledPath) => {
      if (location.includes(enabledPath)) {
        element.classList.add("show");
      } else {
        element.classList.remove("show");
      }
    };

    const onTidioReady = () => {
      const tidio = document.querySelector("#tidio-chat");
      const pathname = window.location.href;
      setTidioChatClasses(tidio, pathname, enabledPath);

      history.listen((location) => {
        setTidioChatClasses(tidio, location.pathname, enabledPath);
      });
    };

    window.tidioChatApi.on("ready", onTidioReady);
  }, [enabledPath, history, isScriptLoadSucceed, isScriptLoaded]);

  return <div>{children}</div>;
}

TidioProvider.propTypes = {
  enabledPath: PropTypes.string.isRequired,
};

export default scriptLoader(
  `//code.tidio.co/g7h0voz3do3h4vf43dzdkpr7bmr0yhik.js`
)(TidioProvider);
