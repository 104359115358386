import React from "react";

import PropTypes from "prop-types";

import { withRouter, Link } from "react-router-dom";
import clsx from "clsx";

const LinkWrapper = ({ children, onClick, url, outbound, ...rest }) => {
  if (onClick)
    return (
      <button onClick={onClick} {...rest}>
        {children}
      </button>
    );

  if (url && outbound)
    return (
      <a href={url} rel="noopener noreferrer">
        {children}
      </a>
    );

  if (url && !outbound) return <Link to={url}>{children}</Link>;

  return children;
};

function SettingsButton({
  children,
  url,
  outbound,
  settingKey,
  loading,
  onClick,
  type,
  disabled,
}) {
  return (
    <div
      className={clsx({
        "settings-button route": true,
        [`page-${settingKey}`]: settingKey ? true : false,
        disabled,
      })}
      data-testid={`settings-button-${settingKey}`}
    >
      <LinkWrapper onClick={onClick} url={url} outbound={outbound}>
        {loading ? <div className="spinner-loader" /> : children}
      </LinkWrapper>

      {type === "page" && <i className="settings-button-icon next" />}
    </div>
  );
}

LinkWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  url: PropTypes.string,
  outbound: PropTypes.bool,
};

SettingsButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(["page", "action"]).isRequired,
  settingKey: PropTypes.string,
  loading: PropTypes.bool,
  url: PropTypes.string,
  outbound: PropTypes.bool,
};

export default withRouter(SettingsButton);
