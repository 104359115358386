const PAGE_STATUS_LOAD_START = "PAGE_STATUS_LOAD_START";
const PAGE_STATUS_LOAD_SUCCESS = "PAGE_STATUS_LOAD_SUCCESS";
const PAGE_STATUS_LOAD_FAILED = "PAGE_STATUS_LOAD_FAILED";
const PAGE_NOT_ALLOWED = "PAGE_NOT_ALLOWED";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_CURRENT_HOME_INFO = "SET_CURRENT_HOME_INFO";

const SET_CURRENT_FEATURE = "SET_CURRENT_FEATURE";

const CHANGE_FEATURE_DATA_KEY = "CHANGE_FEATURE_DATA_KEY";
const CLEAR_CURRENT_PAGE = "CLEAR_CURRENT_PAGE";
const SET_CURRENT_PROPERTY_PAGES = "SET_CURRENT_PROPERTY_PAGES";
const SET_CURRENT_FEATURE_DISTANCE = "SET_CURRENT_FEATURE_DISTANCE";
const SET_GAPI_LOADED = "SET_GAPI_LOADED";
const UPDATE_CURRENT_FEATURE_ADDRESS = "UPDATE_CURRENT_FEATURE_ADDRESS";
const UPDATE_CURRENT_DESTINATION_DATA = "UPDATE_CURRENT_DESTINATION_DATA";

const SET_CURRENT_MORE_LINKS = "SET_CURRENT_MORE_LINKS";
const SET_CURRENT_BOOK_NOW_LINKS = "SET_CURRENT_BOOK_NOW_LINKS";

const SET_TOS = "SET_TOS";

const TOGGLE_HIDE_BACK = "TOGGLE_HIDE_BACK";

export default {
  PAGE_STATUS_LOAD_START,
  PAGE_STATUS_LOAD_SUCCESS,
  PAGE_STATUS_LOAD_FAILED,
  PAGE_NOT_ALLOWED,
  SET_CURRENT_PAGE,
  SET_CURRENT_HOME_INFO,
  SET_CURRENT_FEATURE,
  SET_CURRENT_PROPERTY_PAGES,

  CHANGE_FEATURE_DATA_KEY,
  UPDATE_CURRENT_FEATURE_ADDRESS,
  CLEAR_CURRENT_PAGE,
  SET_CURRENT_FEATURE_DISTANCE,
  SET_GAPI_LOADED,
  UPDATE_CURRENT_DESTINATION_DATA,
  SET_TOS,
  SET_CURRENT_MORE_LINKS,
  SET_CURRENT_BOOK_NOW_LINKS,
  TOGGLE_HIDE_BACK
};
