import React from "react";
import PropTypes from "prop-types";
import BannerHeader from "../common/BannerHeader";

import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { settingsPageOperations } from "./duck";
import settingRouteConfig from "./settingsRouteConfig";
import SettingsInnerPage from "./SettingsInnerPage";
import _get from "lodash.get";
import UserHeader from "../common/UserHeader";
import generateRouteByPageKey from "./generateRouteByPageKey";

import { ModalProvider } from "../common/Modal";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.scrollableEl = React.createRef();
  }
  //if component mounts and there is no current setting page, set current setting page to root
  componentDidMount() {
    const { currentSettingPage } = this.props;
    const { key, name } = currentSettingPage;

    if (!key || !name) {
      this.props.setCurrentSettingPage("userSettings", "More", "/userSettings");
    }
  }

  componentWillUnmount() {
    this.props.setCurrentSettingPage(null, null, null);
  }

  render() {
    const {
      currentSettingPage,
      currentHomeId,
      userName,
      locationState,
      isLoggedIn,
      currentPlatform
    } = this.props;
    const { subSetting, thirdSetting } = this.props.match.params;
    const isHome = !subSetting && !thirdSetting;

    const backPath = !subSetting
      ? currentHomeId
        ? `/${currentPlatform || "app"}/${currentHomeId}`
        : `/${currentPlatform || "app"}`
      : thirdSetting
      ? `/userSettings/${subSetting}`
      : "/userSettings";

    // If the user is not logged in, redirect to manage/login
    if (isLoggedIn === false) {
      return <Redirect to={{ pathname: "/manage/login" }} />;
    }
    return (
      <div className="settings-page-wrapper">
        <ModalProvider>
          <BannerHeader
            backPath={{
              pathname: _get(locationState, "backPath", false)
                ? locationState.backPath
                : backPath
            }}
            cta={
              isHome || _get(locationState, "backPath", false)
                ? "close"
                : "back"
            }
            contentAlign={"center"}
          >
            <p>{currentSettingPage.name}</p>
          </BannerHeader>

          <div
            className="settings-page-content is-momentumScrollable"
            ref={this.scrollableEl}
          >
            <UserHeader
              name={userName}
              email={null}
              currentHomeId={currentHomeId}
            />
            <div className="settings-page-wrapper__page">
              <Switch>
                {settingRouteConfig.map(({ key, name, parent, type }) => {
                  if (type === "route") {
                    return (
                      <Route
                        key={key}
                        exact
                        path={"/userSettings/" + key}
                        render={routerProps => {
                          return (
                            <SettingsInnerPage
                              {...routerProps}
                              settingName={name}
                              settingKey={key}
                              backPath={backPath}
                              generateRoutes={settingKey =>
                                generateRouteByPageKey(settingKey)
                              }
                            />
                          );
                        }}
                      />
                    );
                  }
                  return null;
                })}
                <Route
                  path={"/userSettings"}
                  exact
                  render={routerProps => {
                    return (
                      <SettingsInnerPage {...routerProps} settingKey={"more"} />
                    );
                  }}
                />
                <Route render={() => <p>NOT FOUND</p>} />
              </Switch>
            </div>
          </div>
        </ModalProvider>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  currentSettingPage: state.settingsPageReducer.currentSettingPage,
  loadStatus: state.dashboardReducer.loadStatus,
  currentHomeId: state.dashboardReducer.currentHomeId,
  userData: state.userReducer.user,
  locationState: ownProps.location.state,
  isLoggedIn: Boolean(state.userReducer.user),
  userName: state.userReducer.user
    ? [state.userReducer.user.first_name, state.userReducer.user.last_name].join(' ').trim()
    : null,
  currentPlatform: state.userReducer.currentPlatform
});
const mapDispatchToProps = dispatch => ({
  setCurrentSettingPage: (key, name, backPath) =>
    dispatch(settingsPageOperations.setCurrentSettingPage(key, name, backPath))
});

SettingsPage.propTypes = {
  isHome: PropTypes.bool,
  propertyName: PropTypes.string,
  pageName: PropTypes.string,
  history: PropTypes.object,
  previousPath: PropTypes.string,
  currentSettingsPage: PropTypes.string,
  isLandingPage: PropTypes.bool,
  currentSettingPage: PropTypes.object,
  setCurrentSettingPage: PropTypes.func,
  currentHomeId: PropTypes.number,
  locationState: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object
  ])
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
