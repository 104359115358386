import types from "./types";
import { globalTypes } from "../../../duck";
import _get from "lodash.get";
import { parsePhoneNumber } from "react-phone-number-input";

const initialState = {
  // current 'property' name

  propertyInfo: {
    propertyName: null,
    propertyHost: {
      name: null,
      picture: null,
      description: null,
      phone: null,
      allowText: null,
    },
    propertyAddress: {
      full: null,
      state: null,
      region: null,
      city: null,
      country: null,
      lat: null,
      lng: null,
    },
  },
  //The currently loaded pages 'status'.
  loadStatus: {
    feature: {
      loading: false,
      status: "loading",
    },
    page: {
      loading: false,
      status: "loading",
    },
    featureDistance: {
      loading: false,
      status: "loading",
    },
  },
  GAPILoaded: false,
  dashboardItems: null,

  // information
  currentPage: null,
  previousPath: null,
  currentFeature: null,
  hideBack: false
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalTypes.LOGOUT_USER: {
      return {
        ...state,
        currentHomeId: null,
      };
    }
    case types.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentHomeId: parseInt(action.homeId),
        currentPage: {
          ...state.currentPage,
          pageKey: action.pageKey,
          parentKey: action.parentKey,
          pageName: action.pageName,
          pageItems: action.pageItems,
		  page: action.page
        },
        previousPath: action.previousPath,
      };
    }
	case "SET_PREVIOUS_PATH": {
	  return {
		...state,
		previousPath: action.previousPath,
	  };
	}
	case "CLEAR_CURRENT_FEATURE": {
	  return {
		...state,
		currentFeature: null
	  };
	}
    case types.SET_CURRENT_FEATURE: {
      const parsedPhone = action.featureData.phone
        ? parsePhoneNumber(action.featureData.phone, "US")
        : null;

      return {
        ...state,
        currentFeature: {
          ...state.currentFeature,
          featureId: action.featureId,
          featureName: action.featureName,
          featureKey: action.featureKey,
          featureType: action.featureType,
          featureDataKey: 0,
          featureData: {
            ...action.featureData,
            ...(action.featureData.phone && {
              phone: parsedPhone ? parsedPhone.number : null,
            }),
          },
          featureObjectType: action.featureObjectType,
          featureImage: action.featureImage,
        },
        featureCache: {
          ...state.featureCache,
          ...(!_get(
            state,
            `featureCache.
            ${action.featureObjectType}-${action.featureId}
          `
          ) && {
            [`${action.featureObjectType}-${action.featureId}`]: {
              ...state.currentFeature,
              featureId: action.featureId,
              featureName: action.featureName,
              featureKey: action.featureKey,

              featureType: action.featureType,
              featureDataKey: 0,
              featureData: action.featureData,
              featureObjectType: action.featureObjectType,
            },
          }),
        },
      };
    }
    case types.SET_CURRENT_FEATURE_DISTANCE: {
      return {
        ...state,
        currentFeature: {
          ...state.currentFeature,
          featureDistance: {
            distance: action.distance,
            duration: action.duration,
          },
        },
      };
    }
    case types.SET_CURRENT_PROPERTY_PAGES: {
      return {
        ...state,
        dashboardItems: action.pages,
      };
    }
    case types.SET_CURRENT_MORE_LINKS: {
      return {
        ...state,
        moreLinks: action.moreLinks,
      };
    }
    case types.SET_CURRENT_BOOK_NOW_LINKS: {
      return {
        ...state,
        bookNowLinks: action.bookNowLinks,
      };
    }
    case types.SET_CURRENT_HOME_INFO: {
      return {
        ...state,
        propertyInfo: {
          propertyName: action.propertyName,
          propertyHost: action.propertyHostInfo,
          propertyAddress: action.propertyAddress,
          guestId: action.guestId,
          guestType: action.guestType,
          isPotential: Boolean(action.isProspective),
          region: action.region,
        },
      };
    }

    case types.CHANGE_FEATURE_DATA_KEY: {
      return {
        ...state,
        currentFeature: {
          ...state.currentFeature,
          featureDataKey: action.featureDataKey,
        },
      };
    }
    case types.SET_GAPI_LOADED: {
      return {
        ...state,
        GAPILoaded: action.status,
      };
    }
    case types.UPDATE_CURRENT_FEATURE_ADDRESS: {
      return {
        ...state,
        currentFeature: {
          ...state.currentFeature,
          featureData: {
            ...state.currentFeature.featureData,
            address: {
              ...state.currentFeature.featureData.address,
              fullAddress: action.fullAddress,
              lat: action.lat,
              lng: action.lng,
            },
          },
        },
      };
    }
    case types.UPDATE_CURRENT_DESTINATION_DATA: {
      return {
        ...state,
        currentFeature: {
          ...state.currentFeature,
          featureData: {
            ...state.currentFeature.featureData,
            destinations: state.currentFeature.featureData.destinations.map(
              ({ placeId, ...rest }) => {
                if (placeId === action.placeId) {
                  return {
                    placeId,
                    ...rest,
                    ...action.data,
                    isCached: true,
                  };
                }
                return { placeId, ...rest };
              }
            ),
          },
        },
      };
    }
    case types.CLEAR_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: {
          pageKey: "404",
        },
        previousPath: `/app/${action.homeId}`,
        currentHomeId: parseInt(action.homeId),
      };
    }
    case types.SET_TOS: {
      return {
        ...state,
        tos: {
          privacy_policy: action.privacy_policy,
          terms_of_service: action.terms_of_service,
          contact_email: action.contact_email,
        },
      };
    }
	case types.TOGGLE_HIDE_BACK: {
	  return {
		...state,
		hideBack: action.hideBack === null ? ! state.hideBack : action.hideBack
	  };
	}
    default:
      return state;
  }
};

export default dashboardReducer;
