import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const SwitchHousesCTA = ({ homeId }) => (
  <div className="switch-houses-cta">
    <Link
      to={{
        pathname: "/userSettings/switch-houses",
        state: { backPath: homeId ? `/app/${homeId}` : "/" }
      }}
    >
      Switch House
    </Link>
  </div>
);

SwitchHousesCTA.propTypes = {
  homeId: PropTypes.number
};

export default SwitchHousesCTA;
