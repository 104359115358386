import React from "react";
import PropTypes from "prop-types";
import { toastOperations } from "./duck";
import { connect } from "react-redux";
import Toast from "./Toast";
import { TransitionGroup, CSSTransition } from "react-transition-group";
function Toasts({ removeToast, addToast, toasts }) {
  const handleDismiss = toastId => {
    return evt => {
      evt.preventDefault();
      evt.stopPropagation();
      removeToast(toastId);
    };
  };
  return (
    <ul
      className="toasts"
      style={{ pointerEvents: toasts.length === 0 ? "none" : "all" }}
    >
      <TransitionGroup>
        {toasts.map(toast => (
          <CSSTransition key={toast.id} classNames="appear" timeout={300}>
            <Toast
              {...toast}
              id={toast.id}
              removeToast={removeToast}
              onDismiss={handleDismiss(toast.id)}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </ul>
  );
}

Toasts.propTypes = {
  removeToast: PropTypes.func,
  toasts: PropTypes.array
};

const mapState = state => ({
  toasts: state.toastReducer
});

const mapDispatch = dispatch => ({
  removeToast: id => dispatch(toastOperations.removeToast(id))
});

export default connect(mapState, mapDispatch)(Toasts);
