import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { StringParam, useQueryParam } from "use-query-params";
import _get from "lodash.get";
import { Link, Redirect, useHistory } from "react-router-dom";
import GuestHousePreview from "./GuestHousePreview";
import { prePromptOperation } from "./duck";
import { connect } from "react-redux";
import GuestCreatePageForm from "./GuestCreatePageForm";
import { ReactComponent as HCLogo } from "../common/svg/HC_Logo.svg";
import { toastOperations } from "../toast/duck";
import { ReactComponent as DashHCNavSVG } from "../common/svg/dashboardnav/HC_Logo.svg";
const BrochureCreatePageError = ({ error }) => {
  return (
    <div className="guest-house-register-error">
      <div className="guest-house-register-error__inner">
        <div className="guest-house-register-error__icon">
          <DashHCNavSVG />
        </div>

        <h2>{error.text}</h2>
        <p className="gray-p">
          Please contact your host or house manager for another link to this
          home.
        </p>
        <p>
          Need assistance?{" "}
          <a href="mailto:help@homeconcierge.com">Send us a message.</a>
        </p>
        <p>
          Have an account?<Link to="/manage/login">Sign in here.</Link>
        </p>
      </div>
    </div>
  );
};

const BrochureGuestForm = ({ houseName, onSubmit }) => {
  return (
    <>
      <div className="guest-create-page">
        <div className="guest-create-page__inner">
          <div className="guest-create-page__header">
            <div className="guest-create-page__brand">
              <HCLogo />
            </div>
            <h4>
              Sign up to view <strong>{houseName}</strong>
            </h4>
            <p>
              You will be given access to the brochure version for{" "}
              <strong>{houseName}</strong>.
            </p>
          </div>
          <div className="guest-create-page__form">
            <GuestCreatePageForm onSubmit={onSubmit} withExtraFields={true} />
          </div>

          <div className="guest-create-page__signin">
            <p>
              Have an account? <Link to="/manage/login">Sign in here.</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function BrochureCreatePage({
  validateBrochureLink,
  registerBrochureGuest,
  addToast,
  isLoggedIn,
}) {
  const [initializeLoading, setInitializeLoading] = useState(true);
  const [preData, setPreData] = useState(null);
  const [initializeError, setInitializeError] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const history = useHistory();
  const [uniqueId] = useQueryParam("id", StringParam);

  const handleRegisterBrochureGuest = (values, actions) => {
    registerBrochureGuest(uniqueId, values)
      .then((user) => {
        actions.setSubmitting(false);

        history.push({
          state: { fadeTransition: false },
          pathname: `/app/${preData.id}`,
        });
      })
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
        if (error.response.status === 409) {
          addToast({
            intent: "error",
            text:
              "You are already added as a guest to this house. Please login to view the home.",
          });
        } else {
          addToast({ intent: "error", text: error.message });
        }
      });
  };

  useEffect(() => {
    validateBrochureLink(uniqueId)
      .then((response) => {
        const { id, houseName, regionName, housePhoto } = response;
        setPreData({
          id,
          houseName,
          regionName,
          housePhoto,
        });
        setInitializeLoading(false);
      })
      .catch((error) => {
        if (_get(error, "response.status") === 404) {
          setInitializeError({
            status: 404,
            text: `This "single-use" link to Home Concierge is invalid.`,
          });
        } else if (_get(error, "response.status") === 410) {
          setInitializeError({
            status: 410,
            text: `This "single-use" link to Home Concierge has expired.`,
          });
        } else {
          setInitializeError({
            status: 500,
            text:
              "There was an unknown error while validating your brochure link.",
          });
        }
        setInitializeLoading(false);
      });
  }, [uniqueId, validateBrochureLink]);

  const renderStep = (step) =>
    step === 1 ? (
      <GuestHousePreview
        title={preData.houseName}
        description={() => (
          <>
            Everything about YOUR vacation home <br></br>all in one place.
          </>
        )}
        isHawaii={preData.regionName.toLowerCase() === "kona"}
        housePhoto={preData.housePhoto}
        onSubmit={() => setCurrentStep(currentStep + 1)}
      />
    ) : step === 2 ? (
      <BrochureGuestForm
        houseName={preData.houseName}
        onSubmit={handleRegisterBrochureGuest}
      />
    ) : null;

  if (isLoggedIn) {
    return <Redirect to={`/app`} />;
  }

  if (initializeError)
    return <BrochureCreatePageError error={initializeError} />;
  if (initializeLoading) return <p>Loading...</p>;

  return (
    <div className="guest-house-register-page">
      <div className="guest-house-register-page__inner">
        {renderStep(currentStep)}
      </div>
    </div>
  );
}

BrochureCreatePage.propTypes = {
  validateBrochureLink: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  validateBrochureLink: (uniqueId) =>
    dispatch(prePromptOperation.validateBrochureLink(uniqueId)),
  registerBrochureGuest: (uniqueId, values) =>
    dispatch(prePromptOperation.registerBrochureGuest(uniqueId, values)),
  addToast: (options) => dispatch(toastOperations.addToast(options)),
});

export default connect(null, mapDispatch)(BrochureCreatePage);
