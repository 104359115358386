import ReactGA from "react-ga4";

class GAEventHelper {
  constructor() {
    this.env = process.env.NODE_ENV;
  }

  isProduction() {
    return this.env === "production";
  }

  sendGuestAction(actionRaw, labelRaw) {
	let label = labelRaw;
	let action = actionRaw;
	if (typeof actionRaw === "number") {
	  action = actionRaw.toString();
	}
	if (typeof labelRaw === "number") {
	  label = labelRaw.toString();
	}
	ReactGA.event({
	  category: "Guest Actions",
	  action,
	  label
	});
  }
  sendAccountAction(actionRaw, labelRaw) {
	let label = labelRaw;
	let action = actionRaw;

	if (typeof actionRaw === "number") {
	  action = actionRaw.toString();
	}
	if (typeof labelRaw === "number") {
	  label = labelRaw.toString();
	}
	ReactGA.event({
	  category: "Account Actions",
	  action,
	  label
	});
  }
  sendFeatureAction(actionRaw, labelRaw) {
	let label = labelRaw;
	let action = actionRaw;

	if (typeof actionRaw === "number") {
	  action = actionRaw.toString();
	}
	if (typeof labelRaw === "number") {
	  label = labelRaw.toString();
	}
	ReactGA.event({
	  category: "Feature Actions",
	  action,
	  label
	});
  }
  setUserId(userId) {
	ReactGA.set({ userId });
  }
}

export default GAEventHelper;
