import React from 'react';
// import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class FAQContent extends React.Component {
  componentDidMount() {
    this.settingsPageWrapper = document.querySelector('.settings-page-content');
    if (this.settingsPageWrapper) {
      disableBodyScroll(this.settingsPageWrapper);
    }
  }

  componentWillUnmount() {
    enableBodyScroll(this.settingsPageWrapper);
  }

  render() {
    return (
      <div className="settings-page-content-info ">
        <div className="settings-page-terms-wrapper">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed
            fringilla libero, nec dapibus justo. Morbi aliquam maximus pretium.
            Vivamus feugiat dui eget nibh porta ullamcorper et in turpis.
            Curabitur metus massa, vestibulum sed mollis et, sollicitudin sed
            eros. Mauris varius, nisi ac cursus facilisis, neque urna
            sollicitudin ex, rhoncus consequat dolor nisi quis elit. Donec
            aliquet metus at metus condimentum, pharetra finibus orci molestie.
            Suspendisse vestibulum quam mauris, nec aliquam leo imperdiet ut.
          </p>
          <p>
            Nulla et orci ornare, vehicula leo bibendum, maximus leo. Nunc
            iaculis, quam a placerat commodo, sem dolor efficitur mi, ut
            volutpat est velit nec neque. Sed iaculis, tortor vel fringilla
            pellentesque, justo elit maximus lectus, eu accumsan nulla neque
            convallis arcu. Integer viverra libero aliquet ultrices fermentum.
            Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris
            vel lectus sed purus vestibulum egestas. Fusce convallis urna cursus
            nunc laoreet, sed lacinia massa efficitur. Integer convallis turpis
            orci, vitae fermentum quam ornare eu. Nullam facilisis neque
            tincidunt sapien congue, a lobortis odio dapibus. Vivamus vestibulum
            interdum risus eget semper. Integer euismod sem vitae dapibus
            laoreet.
          </p>
          <p>
            Sed consequat elit ut varius auctor. Phasellus finibus tortor
            gravida odio gravida maximus. Aliquam vel lectus laoreet,
            pellentesque erat a, accumsan diam. Nullam tempus eros purus, ac
            cursus metus bibendum vel. Orci varius natoque penatibus et magnis
            dis parturient montes, nascetur ridiculus mus. Duis imperdiet dolor
            velit, ac lobortis augue faucibus nec. Vestibulum tristique interdum
            velit.
          </p>
          <p>
            Cras vel pharetra massa. Nulla sit amet mollis sem. Cras id tempor
            magna. Mauris tincidunt aliquam lorem. Aenean malesuada, nulla vel
            sagittis dignissim, neque leo pretium massa, eu ultrices ipsum purus
            porta ante. Sed convallis lectus tellus, eu maximus odio mollis
            eget. Sed sit amet congue velit. Pellentesque eleifend tortor eget
            diam commodo viverra. Cras augue ligula, blandit nec efficitur id,
            vehicula non justo. Sed nibh lorem, scelerisque id ipsum non, varius
            pulvinar nisi. Sed interdum ipsum a pellentesque commodo.
            Pellentesque commodo placerat turpis, vel consequat libero porttitor
            et. Aenean efficitur eget justo non tristique. Vivamus nec semper
            nisi. Morbi malesuada at sapien eu scelerisque. Aliquam erat
            volutpat. Integer nec leo eget enim scelerisque pharetra. Etiam
            ornare velit at est fringilla scelerisque. Donec vel magna ut justo
            faucibus mattis. Phasellus suscipit, sem non consectetur aliquam,
            orci justo congue purus, et dapibus quam enim ut quam. Orci varius
            natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Praesent efficitur sit amet sem vitae auctor. Cras
            eget velit enim. Ut ultricies porttitor ligula eu vehicula. Maecenas
            finibus risus lacus, eget bibendum dui elementum sed. In hac
            habitasse platea dictumst. Etiam in lacus convallis, scelerisque ex
            ut, imperdiet magna. Sed tempus magna quis libero lacinia, eget
            maximus mauris dictum.
          </p>
        </div>
      </div>
    );
  }
}

export default FAQContent;
