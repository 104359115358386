import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SwitchHousesTableItem from "../../common/SwitchHousesTableItem";

import SwitchHousesTable from "../../common/SwitchHousesTable";

import copyToClipboard from "../../../utilities/copyToClipboard";

import SendBrochureLinkPage from "../SendBrochureLinkPage";

function SendBrochureContent({ houses }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [brochureHouse, setBrochureHouse] = useState(null);
  const [copied, setCopied] = useState(false);
  const displayBrochureLink = (house) => () => {
    setBrochureHouse(house);
    setCurrentStep(2);
  };

  const onCopy = (url) => () => {
    copyToClipboard.copy(url);
    setCopied(true);
  };
  return (
    <div className="switch-houses" data-testid="switch-houses">
      <div className="switch-houses__inner">
        {currentStep === 1 && (
          <>
            <div className="switch-houses__title">
              <h3>For which home would you like to send a brochure invite?</h3>
            </div>

            <div className="switch-houses__list">
              <SwitchHousesTable>
                {houses.map((house) => (
                  <SwitchHousesTableItem
                    key={house.id}
                    id={house.id}
                    image={house.image}
                    permission={house.permission}
                    houseName={house.houseName}
                    fullAddress={house.address.fullAddress}
                    onClick={displayBrochureLink(house)}
                    ctaText="Choose"
                  />
                ))}
              </SwitchHousesTable>
            </div>
          </>
        )}
        {currentStep === 2 && (
          <SendBrochureLinkPage
            houseId={brochureHouse.id}
            houseName={brochureHouse.houseName}
            copied={copied}
            onCopy={onCopy}
          />
        )}
      </div>
    </div>
  );
}

const mapState = (state) => ({
  houses: state.userReducer.houses.filter(
    ({ permission }) =>
      permission.type === "owner" || permission.type === "host"
  ),
});

SendBrochureContent.propTypes = {
  houses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          image: PropTypes.string,
        })
      ),
      permission: PropTypes.shape({
        type: PropTypes.string,
        owner: PropTypes.string,
      }),
      houseName: PropTypes.string.isRequired,
      address: PropTypes.shape({
        fullAddress: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default connect(mapState)(SendBrochureContent);
