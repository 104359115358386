import React, { createContext, useMemo } from "react";
import { connect } from "react-redux";
import _get from "lodash.get";
import useGeocode from "../../hooks/useGeocode";

export const RouteContext = createContext({
  origin: null,
  destination: null,
  loading: null,
});

function RouteProvider({
  children,
  propertyAddress,
  featureAddress,
  propertyName,
  featureName,
}) {
  const propertyObject = useMemo(
    () =>
      propertyAddress
        ? {
            name: propertyName,
            fullAddress: propertyAddress.fullAddress,
            state: propertyAddress.state,
            city: propertyAddress.city,
            lat: propertyAddress.coordinates
              ? propertyAddress.coordinates.lat
              : null,
            lng: propertyAddress.coordinates
              ? propertyAddress.coordinates.lng
              : null,
          }
        : null,
    [propertyName] // eslint-disable-line
  );

  const featureObject = useMemo(
    () => (featureAddress ? { name: featureName, ...featureAddress } : null),
    [featureName] // eslint-disable-line
  );

  const [propGeoLoading, propertyGeocoded] = useGeocode(propertyObject);

  const [featureGeoLoading, featureGeocoded] = useGeocode(featureObject);

  return (
    <RouteContext.Provider
      value={{
        origin: propertyGeocoded,
        destination: featureGeocoded,
        loading: featureGeoLoading || propGeoLoading,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
}

RouteProvider.propTypes = {};

const mapState = (state) => ({
  propertyName: _get(state.dashboardReducer, "propertyInfo.propertyName", null),
  featureName: _get(state.dashboardReducer, "currentFeature.featureName", null),
  propertyAddress: _get(
    state.dashboardReducer,
    "propertyInfo.propertyAddress",
    null
  ),
  featureAddress: _get(
    state.dashboardReducer,
    "currentFeature.featureData.address",
    null
  ),
});

export default connect(mapState)(RouteProvider);
