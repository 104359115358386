import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { isInStandaloneMode } from "../../utilities/isMobileDevice";

const LinkWrapper = ({ path, url, onClick, children, data, className }) => {
  if (url) {
    return (
      <a
        href={url}
        rel="noopener noreferrer"
        className={className}
        data-testid="dashbox"
        target={isInStandaloneMode() ? "_blank" : "_self"}
      >
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        onClick={(evt) => {
          evt.preventDefault();
          onClick(evt);
        }}
        data-testid="dashbox"
        className={className}
      >
        {children}
      </button>
    );
  }

  if (path) {
    const pathURL =
      typeof path === "object"
        ? path
        : {
            pathname: path.split("?")[0],
            search: path.split("?")[1],
          };

    return (
      <Link
        to={{ pathname: pathURL.pathname, search: pathURL.search, state: data }}
        data-testid="dashbox"
        className={className}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      onClick={(e) => e.preventDefault()}
      href={window.location.pathname}
      data-testid="dashbox"
    >
      {children}
    </a>
  );
};

LinkWrapper.propTypes = {
  path: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function DashBox({
  isEmpty,
  icon,
  iconType,
  pageKey,
  data,
  path,
  name,
  url,
  handleTouchScale,
  onClick,
  type
}) {
  const [portalEntry, setPortalEntry] = useState(null);
  const [width, setWidth] = useState(
    window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  );

  const updateWidth = () => {
    setWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    window.addEventListener("DOMContentLoaded", updateWidth);
    if (pageKey === "arrivalDetails" || pageKey === "airportDetails") {
      const portalEntryNode = document.querySelector(
        `.portal-entry.${pageKey}`
      );
      if (portalEntryNode) {
        setPortalEntry(portalEntryNode);
      }
    }
    return () => {
      window.removeEventListener("resize", updateWidth);
      window.removeEventListener("DOMContentLoaded", updateWidth);
    };
  }, [pageKey]);

  if (type === 'share') {
	onClick = () => {
	  window.location.href = window.location.href + '?popup=shareWithFamily';
	}
  }

  const DashBoxComponent = (
    <div
      className={clsx("dashbox", {
        "dashbox--image": iconType === "image",
        [`dashbox--${pageKey}`]: Boolean(pageKey),
        "is-empty": isEmpty === true,
        "is-not-empty": isEmpty === false,
      })}
    >
      <LinkWrapper
        path={path}
        onClick={onClick}
        url={url}
        data={data}
        className="dashbox__link"
      >
        <span className="dashbox__wrapper">
          <span onClick={handleTouchScale} className="dashbox__icon">
            {icon}
          </span>
          <p className="dashbox__label" htmlFor={pageKey}>
            {name}
          </p>
        </span>
      </LinkWrapper>
    </div>
  );

  if (pageKey === "arrivalDetails" || pageKey === "airportDetails") {
    if (portalEntry) {
      if (width >= 768) {
        return createPortal(DashBoxComponent, portalEntry);
      } else {
        return DashBoxComponent;
      }
    }
  }

  return DashBoxComponent;
}

DashBox.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  icon: PropTypes.object,
  handleTouchScale: PropTypes.func,
  iconType: PropTypes.string,
  pageKey: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  pageId: PropTypes.number,
  data: PropTypes.object,
  type: PropTypes.string
};

export default DashBox;
