import React, { useMemo, useContext, useEffect } from "react";
import BannerHeader from "../../common/BannerHeader";
import PropTypes from "prop-types";

import GoogleDirectionsMap from "../../common/GoogleDirectionsMap";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import _get from "lodash.get";

import isRestrictedSelector, {
  isCheckedOutSelector,
} from "../../../selectors/isRestrictedSelector";
import { GeolocationContext } from "../GeolocationProvider";

import openMap from "../../../utilities/openMap";
import { RouteContext } from "../RouteProvider";
import GeoLocationLoader from "../GeolocationLoader";

import { OutboundWarningContext } from "../OutboundWarningProvider";
import computeAddrString from "../../../utilities/computeAddrString";

function DirectionsPopup({
  removePopup,
  GAPILoaded,
  featureDistance,
  featureName,
  isRestricted,
}) {
  const { loading: geoLoading, geo } = useContext(GeolocationContext);

  const { setWarningProvider, setAddress, canOpenPrompt } = useContext(
    OutboundWarningContext
  );

  const { origin, destination, loading } = useContext(RouteContext);

  const destinationObject = useMemo(
    () => ({ ...destination, isProtected: false }),
    [destination]
  );

  const originObject = useMemo(
    () =>
      geo
        ? { ...geo, isProtected: isRestricted }
        : { ...origin, isProtected: isRestricted },
    [geo, isRestricted, origin]
  );

  useEffect(() => {
    if (!destinationObject) return;
    setAddress(destinationObject);
  }, [destinationObject, setAddress]);

  return (
    <div className="popup-content-inner" data-testid="directions-popup">
      <BannerHeader onClose={removePopup} cta="close" contentAlign={"start"}>
        <div className="banner-header-inner">
          <div className="directions-text">
            <h3>{featureName}</h3>
            {_get(featureDistance, "duration", false) &&
            _get(featureDistance, "distance", false) ? (
              <>
                <span>{featureDistance.duration}</span>·
                <span>{featureDistance.distance}</span>
              </>
            ) : null}
          </div>

          {!loading && destination ? (
            <a
              href={openMap(
                computeAddrString(
                  { ...destination, name: featureName },
                  { withName: true }
                )
              )}
              rel="noopener noreferrer"
            >
              Open in maps
            </a>
          ) : null}
        </div>
      </BannerHeader>
      <div className="popup-map-container">
        {geoLoading || loading ? (
          <GeoLocationLoader />
        ) : (
          <GoogleDirectionsMap
            loaded={GAPILoaded}
            originObject={originObject}
            destinationObject={destinationObject}
          />
        )}
      </div>
    </div>
  );
}

DirectionsPopup.propTypes = {
  removePopup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  GAPILoaded: PropTypes.bool,
  featureDistance: PropTypes.object,
  featureName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  featureAddress: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.address
    : null,
  GAPILoaded: state.dashboardReducer.GAPILoaded,
  featureDistance: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureDistance
    : null,
  featureName: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureName
    : null,
  isRestricted: isRestrictedSelector(state),
  isCheckedOut: isCheckedOutSelector(state),
});

export default withRouter(connect(mapStateToProps)(DirectionsPopup));
