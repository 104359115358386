import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import PillButton from "../common/PillButton";
import { settingsPageOperations } from "./duck";
import MessageIcon from "@material-ui/icons/Message";
import EmailIcon from "@material-ui/icons/Email";
import FileCopyIcon from "@material-ui/icons/FileCopy";
function SendBrochureLinkPage({
  houseId,
  houseName,
  copied,
  onCopy,
  generateBrochureLink,
}) {
  const [loading, setLoading] = useState(true);
  const [uniqueId, setUniqueId] = useState();
  const [error, setError] = useState();

  let url = `https://app.homeconcierge.com/manage/brochure-registration?id=${uniqueId}`;

  useEffect(() => {
    generateBrochureLink(houseId)
      .then(({ unique_id }) => {
        setUniqueId(unique_id);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [generateBrochureLink, houseId]);

  if (error) {
    return (
      <h3>
        There was an error generating a brochure link for{" "}
        <strong>{houseName}</strong>
      </h3>
    );
  }

  return (
    <div className="switch-houses__link">
      <h3>
        Here is an invite url for <strong>{houseName}</strong>
      </h3>
      <p>
        This link can be sent to anyone, allowing them to signup to the home as
        a brochure guest.
      </p>
      <p>
        Once the link is used, it will not be able to be used again. The link
        will expire in 3 days for security purposes.
      </p>
      <div
        className={clsx("switch-houses__linkwrapper", {
          "switch-houses__linkwrapper--loading": loading,
        })}
      >
        <code>{loading ? "Loading..." : url}</code>
        <PillButton
          type="button"
          color="pastel-aqua"
          disabled={loading}
          link={`mailto:?subject=You have been invited to view your vacation home, ${houseName}&body=${url}`}
          outbound={true}
          startIcon={EmailIcon}
        >
          Email Link
        </PillButton>
        <PillButton
          type="button"
          color="pastel-blue"
          disabled={loading}
          link={`sms:?&body=${url}`}
          outbound={true}
          startIcon={MessageIcon}
        >
          Text Link
        </PillButton>
        <PillButton
          type="button"
          color="orange"
          disabled={copied || loading}
          startIcon={FileCopyIcon}
          onClick={onCopy(url)}
        >
          {copied ? "Copied!" : "Copy Link"}
        </PillButton>
      </div>
    </div>
  );
}

const mapDispatch = (dispatch) => ({
  generateBrochureLink: (houseId) =>
    dispatch(settingsPageOperations.generateBrochureLink(houseId)),
});

export default connect(null, mapDispatch)(SendBrochureLinkPage);
