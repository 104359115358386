import types from "./types";
import _get from "lodash.get";

export default (state = {}, action) => {
  switch (action.type) {
    case types.SET_CAMERA_HOME_INFO: {
      const { houseName, guestId } = action;

      return {
        ...state,
        propertyInfo: {
          ...state.propertyInfo,
          propertyName: houseName,
          guestId
        }
      };
    }
    case types.SET_CAMERA_PAGES: {
      const { items } = action;
      return {
        ...state,
        dashboardItems: items
      };
    }

    case types.SET_CAMERA_CURRENT_PAGE: {
      return {
        ...state,
        currentHomeId: parseInt(action.homeId),
        currentPage: {
          ...state.currentPage,
          pageKey: action.pageKey,
          parentKey: action.parentKey,
          pageName: action.pageName,
          pageItems: action.pageItems
        },
        previousPath: action.previousPath
      };
    }
    case types.SET_CAMERA_FEATURE: {
      return {
        ...state,
        currentFeature: {
          ...state.currentFeature,
          featureId: action.featureId,
          featureName: action.featureName,
          featureKey: action.featureKey,

          featureType: action.featureType,
          featureDataKey: 0,
          featureData: action.featureData,
          featureObjectType: action.featureObjectType,
          featureImage: action.featureImage,
          featurePrefilled: action.featurePrefilled
        },
        featureCache: {
          ...state.featureCache,
          ...(!_get(
            state,
            `featureCache.
            ${action.featureKey}-${action.featureId}
          `
          ) && {
            [`${action.featureKey}-${action.featureId}`]: {
              ...state.currentFeature,
              featureId: action.featureId,
              featureName: action.featureName,
              featureKey: action.featureKey,
              featureType: action.featureType,
              featureDataKey: 0,
              featureData: action.featureData,
              featureObjectType: action.featureObjectType
            }
          })
        }
      };
    }
    case types.ADD_FEATURE_ITEM:
    case types.SET_FEATURE_ITEM: {
      return {
        ...state,
        currentFeature: {
          featureId: action.item.id,
          featureName: action.item.name,
          featureKey: action.item.key,
          featureImage: action.item.image,
          featureType: action.item.type,
          featureData: action.item
        },
        dashboardItems: state.dashboardItems.map(({ id, key, ...rest }) => {
          if (
            Number(id) === Number(action.item.id) &&
            key === action.item.key
          ) {
            return {
              id,
              key,
              ...rest,
              isEmpty: false
            };
          }
          return {
            id,
            key,
            ...rest
          };
        })
      };
    }

    case types.REMOVE_CURRENT_CAMERA_FEATURE: {
      return {
        ...state,
        currentFeature: null
      };
    }
    case types.REMOVE_FEATURE_ITEM: {
      const removedFeatureItem = state.currentPage.pageItems.find(
        ({ id }) => Number(id) === Number(action.featureId)
      );

      const currentFeatureItemLength = state.currentFeature.featureData.items.filter(
        ({ id }) => Number(id) !== Number(action.itemId)
      ).length;
      return {
        ...state,
        currentFeature: {
          ...state.currentFeature,
          featureData: {
            ...state.currentFeature.featureData,
            items: state.currentFeature.featureData.items.filter(({ id }) => {
              if (Number(id) === Number(action.itemId)) {
                return false;
              } else {
                return true;
              }
            })
          }
        },
        dashboardItems: state.dashboardItems.map(({ id, key, ...rest }) => {
          if (
            Number(id) === Number(action.featureId) &&
            key === removedFeatureItem.key &&
            currentFeatureItemLength === 0
          ) {
            return {
              id,
              key,
              ...rest,
              isEmpty: true
            };
          }
          return {
            id,
            key,
            ...rest
          };
        })
      };
    }
    case types.CLEAR_CURRENT_CAMERA_PAGE: {
      return {
        ...state,
        currentPage: {
          pageKey: "404"
        },
        previousPath: `/camera/${action.homeId}`,
        currentHomeId: action.homeId
      };
    }
    default: {
      return state;
    }
  }
};
