import types from "./types";

// reducer to handle loading
export const loadingReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SET_LOADING: {
      const { requestName, requestStatus, context } = action;
      if (!requestName || !requestStatus) return state;
      return {
        ...state,
        [requestName]: {
          loading: requestStatus === "REQUEST",
          status: requestStatus,
		  context: context
        },
      };
    }
    default:
      return state;
  }
};

export const userReducer = (
  state = { user: null, fallbackToken: null, houses: null, invitedGuests: [] },
  action
) => {
  switch (action.type) {
    case types.SET_USER_DATA: {
      const { userData } = action;
      return {
        ...state,
        user: {
          ...userData,
          isLoggedIn: true /* userData.isActive && userData.role === 'guest' */,
        },
      };
    }
    case types.SET_FALLBACK_TOKEN: {
      return {
        ...state,
        fallbackToken: action.token,
      };
    }
    case types.SET_CURRENT_PLATFORM: {
      return {
        ...state,
        currentPlatform: action.platform,
      };
    }
    case types.LOGOUT_USER: {
      return {
        ...state,
        fallbackToken: null,
        user: null,
        houses: null,
        invitedGuests: null,
      };
    }
    case types.SET_USER_HOUSES: {
      return {
        ...state,
        houses: action.userHouses,
      };
    }
    case types.SET_USER_INVITED_GUESTS: {
      return {
        ...state,
        invitedGuests: action.guests,
      };
    }

    case types.ADD_SECONDARY_GUEST: {
      return {
        ...state,
        invitedGuests: [
          ...state.invitedGuests,
          {
            id: action.guestId,
            firstName: action.firstName,
            lastName: action.lastName,
            email: action.email,
          },
        ],
      };
    }
    case types.REMOVE_SECONDARY_GUEST: {
      return {
        ...state,
        invitedGuests: state.invitedGuests.filter(
          (guest) => action.guestId !== guest.id
        ),
      };
    }

    default:
      return state;
  }
};

export default { loadingReducer };
