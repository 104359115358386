import { createSelector } from "reselect";
import _get from "lodash.get";

// gets the guest type for the home
// either primary or secondary
const guestTypeSelector = state =>
  _get(state, "dashboardReducer.propertyInfo.guestType", null);

const guestHousePermissionSelector = state =>
  _get(
	state.userReducer.houses ? state.userReducer.houses.find(
      ({ id }) =>
        id ===
        (parseInt(_get(state.dashboardReducer, "currentHomeId", null)) ||
          parseInt(_get(state.camera, "currentHomeId", null)))
    ) : {},
    "permission",
    null
  );

const isHomeAdminSelectorDeps = [
  guestTypeSelector,
  guestHousePermissionSelector
];

const isHomeAdminSelector = createSelector(
  isHomeAdminSelectorDeps,
  (guestType, guestPermission) => {
    if (
      _get(guestPermission, "type") === "owner" ||
      _get(guestPermission, "type") === "host"
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export default isHomeAdminSelector;
