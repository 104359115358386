import React, { useRef } from "react";
import PropTypes from "prop-types";

import { useGoogleMapDirections } from "../../hooks";

function DirectionsMap({ originObject, destinationObject }) {
  const mapNodeRef = useRef(null);
  useGoogleMapDirections(mapNodeRef, originObject, destinationObject);

  return (
    <div
      className="directions-gmap"
      style={{ height: "100%" }}
      ref={mapNodeRef}
    />
  );
}

DirectionsMap.propTypes = {
  originObject: PropTypes.shape({
    address_components: PropTypes.array.isRequired,
    formatted_address: PropTypes.string,
    geometry: PropTypes.shape({
      location: PropTypes.shape({
        lat: PropTypes.func.isRequired,
        lng: PropTypes.func.isRequired,
      }),
    }),
    isProtected: PropTypes.bool.isRequired,
    name: PropTypes.string,
    place_id: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
  }),
  destinationObject: PropTypes.shape({
    address_components: PropTypes.array.isRequired,
    formatted_address: PropTypes.string,
    geometry: PropTypes.shape({
      location: PropTypes.shape({
        lat: PropTypes.func.isRequired,
        lng: PropTypes.func.isRequired,
      }),
    }),
    isProtected: PropTypes.bool.isRequired,
    name: PropTypes.string,
    place_id: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
  }),
  onError: PropTypes.func,
};

export default DirectionsMap;
