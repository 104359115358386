import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { dashboardOperations } from "../../dashboard/duck";
import SanitizedHTML from "../../common/SanitizedHTML";
import FeatureLoader from "../../featurepage/FeatureLoader";
import { CSSTransition } from "react-transition-group";

function TermsContent({ getTOS, tos }) {
  const [loading, setLoading] = useState(tos ? false : true);

  const [error, setError] = useState();

  useEffect(() => {
    if (tos) return;
    getTOS()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [getTOS, tos]);

  if (error) return <p>There was an error with displaying the data.</p>;

  return (
    <>
      <CSSTransition
        classNames="fade"
        in={loading}
        timeout={300}
        unmountOnExit={true}
      >
        <FeatureLoader loading={true} />
      </CSSTransition>
      <CSSTransition
        classNames="fade"
        in={loading === false}
        mountOnEnter={true}
      >
        <div className="settings-page-content-info">
          <div className="settings-page-terms-wrapper">
            <SanitizedHTML markup={tos ? tos.terms_of_service : ""} />
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

const mapState = (state) => ({
  tos: state.dashboardReducer.tos,
});

const mapDispatch = (dispatch) => ({
  getTOS: (tosKey) => dispatch(dashboardOperations.getTOS(tosKey)),
});

TermsContent.propTypes = {
  getTOS: PropTypes.func.isRequired,
  tos: PropTypes.shape({
    privacy_policy: PropTypes.string,
    terms_of_service: PropTypes.string,
    contact_email: PropTypes.string,
  }),
};

export default connect(mapState, mapDispatch)(TermsContent);
