import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import DirectionsMap from "./DirectionsMap";

import { toastOperations } from "../toast/duck";

function GoogleDirectionsMap({
  originObject,
  destinationObject,
  featureLoading,
  addToast,
}) {
  const handleError = useCallback(
    (err) => {
      addToast({
        intent: "error",
        text:
          "There was an issue with displaying your map markers. Please try again later or contact help@homeconcierge.com for assistance.",
      });
    },
    [addToast]
  );

  if (featureLoading === true) {
    return "loading...";
  }

  return (
    <DirectionsMap
      destinationObject={destinationObject}
      originObject={originObject}
      onError={handleError}
    />
  );
}

const mapStateToProps = (state) => ({
  featureLoading: state.loadingReducer.feature.loading,
});

const mapDispatch = (dispatch) => ({
  addToast: (options) => dispatch(toastOperations.addToast(options)),
});

GoogleDirectionsMap.propTypes = {
  featureLoading: PropTypes.bool,
};

GoogleDirectionsMap.whyDidYouRender = true;

export default connect(mapStateToProps, mapDispatch)(GoogleDirectionsMap);
