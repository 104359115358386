import ReactGA from "react-ga4";
import { useCallback } from "react";

const useReactGA = (trackingId) => {
  const initialize = useCallback(
    (options) => {
      if (!trackingId) return;

      ReactGA.initialize(trackingId, {
        gaOptions: options,
		gtagOptions: {
		  send_page_view: false
		},
      });
    },
    [trackingId]
  );

  return [initialize];
};

export default useReactGA;
