import Pusher from 'pusher-js';

class broadcastPusher {

    initPusher(token)
    {
        return token ?
            new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
                    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
                    encrypted: true,
                    authEndpoint: process.env.REACT_APP_PUSHER_BROADCAST_AUTH,
                    auth: {
                        headers: {
                            'token': token,
                        },
                    },
                }):
            new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
                encrypted: true,
            });
    }

    initPublicChannel()
    {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            encrypted: true
        });
        const channel_notify = pusher.subscribe(process.env.REACT_APP_PUSHER_PUBLIC_CHANNEL);
        channel_notify.bind(process.env.REACT_APP_PUSHER_PUBLIC_EVENT, data => {
            if(data.message === 'reload'){
                window.location.reload(true);
            }
        });
    }

    initPrivateChannel(token, uniqueId)
    {
        const pusher = this.initPusher(token);
        const channel_notify = pusher.subscribe('client-user-' + uniqueId);
        channel_notify.bind(process.env.REACT_APP_PUSHER_PRIVATE_EVENT, data => {
            if(data.message === 'reload'){
				window.location.reload(true);
            }
        });
    }
};

export const pusherApi = new broadcastPusher();
export default broadcastPusher;
