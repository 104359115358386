import React from "react";
import { connect } from "react-redux";
import InteractionPopupWrapper from "./InteractionPopupWrapper";
import PropTypes from "prop-types";
import DirectionsPopup from "./popups/DirectionsPopup";
import ReservationsPopup from "./popups/ReservationsPopup";
import SharePopup from "./popups/SharePopup";

import { withRouter, Redirect } from "react-router-dom";
import parseQuery from "stringquery";
import _get from "lodash.get";
import VideoPopup from "./popups/VideoPopup";
import HiddenPopup from "./popups/HiddenPopup";
import ContactPopup from "./popups/ContactPopup";
import InventoryPopup from "./popups/InventoryPopup";
import OutboundWarningProvider from "./OutboundWarningProvider";
import BookNowPopup from "./popups/BookNowPopup";

export class InteractionPopup extends React.Component {
  renderPopup(popupName, removePopupHandler) {
    const {
      currentPageOrFeature,
      currentPropertyName,
      location,
      propertyHostData,
      currentFeatureData,
      bookNowLinks,
    } = this.props;

    switch (popupName) {
      case "book_now":
        return (
          <BookNowPopup
            airBnb={bookNowLinks.book_airbnb}
            vrbo={bookNowLinks.book_vrbo}
            luxe={bookNowLinks.book_luxe}
            otherName={bookNowLinks.book_other_name}
            otherUrl={bookNowLinks.book_other_url}
          />
        );
      case "contact_host":
        return (
          <ContactPopup
            featureName={currentPageOrFeature}
            propertyName={currentPropertyName}
            removePopup={removePopupHandler}
            email={propertyHostData.email}
            phone={propertyHostData.phone}
			phoneText={propertyHostData.phoneText}
          />
        );

	  case "inventory":
		return (
		  <InventoryPopup
			featureName={currentPageOrFeature}
			currentFeatureData={currentFeatureData}
			propertyName={currentPropertyName}
			removePopup={removePopupHandler}
			email={propertyHostData.email}
			phone={propertyHostData.phone}
			phoneText={propertyHostData.phoneText}
		  />
		);

      case "contact_tour_guide":
      case "contact_masseuse":
      case "contact_chef":
      case "contact_shopper":
        return (
          <ContactPopup
            featureName={currentPageOrFeature}
            propertyName={currentPropertyName}
            removePopup={removePopupHandler}
            email={currentFeatureData.email}
            phone={currentFeatureData.phone}
          />
        );
      case "video":
        return (
          <VideoPopup
            title={
              currentPageOrFeature ? `${currentPageOrFeature} Video` : undefined
            }
            removePopup={removePopupHandler}
          />
        );
      case "directions":
        return <DirectionsPopup removePopup={removePopupHandler} />;
      case "reservations":
        return <ReservationsPopup removePopup={removePopupHandler} />;
      case "shareWithFamily":
        return <SharePopup removePopup={removePopupHandler} />;
      case "hidden":
        return <HiddenPopup removePopup={removePopupHandler} />;
      default:
        return <Redirect to={location.pathname} />;
    }
  }

  render() {
    const { currentPopupName } = this.props;

    if (!currentPopupName) return null;
    return (
      <InteractionPopupWrapper popupName={currentPopupName}>
        {({ setClickableRef, removePopup }) => (
          <div
            className="popup-content"
            style={{ zIndex: 2, position: "relative" }}
            ref={setClickableRef}
          >
            <OutboundWarningProvider>
              {this.renderPopup(currentPopupName, removePopup)}
            </OutboundWarningProvider>
          </div>
        )}
      </InteractionPopupWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentFeatureName: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureName
    : null,
  currentPropertyName: state.dashboardReducer.propertyInfo.propertyName,

  currentPageOrFeature: _get(
    parseQuery(ownProps.location.search),
    "feature",
    false
  )
    ? _get(state.dashboardReducer, "currentFeature.featureName", null)
    : _get(state.dashboardReducer, "currentPage.pageName", null),
  propertyHostData: state.dashboardReducer.propertyInfo.propertyHost,
  currentFeatureData: _get(
    state.dashboardReducer.currentFeature,
    "featureData",
    {}
  ),
  bookNowLinks: _get(state.dashboardReducer, "bookNowLinks", {}),
});

InteractionPopup.propTypes = {
  toggle: PropTypes.func,
  currentPopupName: PropTypes.string,
  currentPageOrFeature: PropTypes.string,
  currentPropertyName: PropTypes.string,
};
export default withRouter(connect(mapStateToProps)(InteractionPopup));
