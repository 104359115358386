import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

function OrientationWarning() {
  const orientationWarningRef = useRef(null);

  // otherwise, return the warning
  useEffect(() => {
    if (!orientationWarningRef.current) return;
    const node = orientationWarningRef.current;
    disableBodyScroll(node);
    return () => {
      enableBodyScroll(node);
    };
  }, []);

  return (
    <div id="orientation-warning" ref={orientationWarningRef}>
      <h2>Please rotate screen!</h2>
    </div>
  );
}

OrientationWarning.propTypes = {
  orientation: PropTypes.oneOf([180, 90, 0, -90, -180]),
  mobileOnly: PropTypes.bool
};

export default OrientationWarning;
