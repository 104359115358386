import React from "react";

function UserHeader({ role, name, email, currentHomeId }) {
  return (
    <div className="settings-page-header">
      <div className="settings-page-header__inner">
        <div className="settings-page-header__header">
          {name && <h3>{name}</h3>}
          {email && <p>{email}</p>}
        </div>
      </div>
    </div>
  );
}

export default UserHeader;
