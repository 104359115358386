import types from "./types";

const setCurrentPage = (
  homeId,
  pageKey,
  parentKey,
  pageName,
  pageItems,
  previousPath,
  page
) => ({
  type: types.SET_CURRENT_PAGE,
  homeId,
  pageKey,
  parentKey,
  pageName,
  pageItems,
  previousPath,
  page
});

const updateCurrentDestinationData = (placeId, data) => ({
  type: types.UPDATE_CURRENT_DESTINATION_DATA,
  placeId,
  data,
});
const updateCurrentFeatureAddress = (fullAddress, lat, lng) => ({
  type: types.UPDATE_CURRENT_FEATURE_ADDRESS,
  fullAddress,
  lat,
  lng,
});

const setCurrentFeature = (
  featureId,
  featureKey,
  featureName,
  featureData,

  featureType,
  featureObjectType,
  featureImage
) => ({
  type: types.SET_CURRENT_FEATURE,
  featureId,
  featureKey,
  featureName,
  featureData,
  featureImage,
  featureType,
  featureObjectType,
});

const setCurrentPropertyPages = (pages) => ({
  type: types.SET_CURRENT_PROPERTY_PAGES,
  pages,
});

const setCurrentHomeInfo = (
  propertyName,
  propertyHostInfo,
  propertyAddress,
  guestId,
  guestType,
  isProspective,
  region
) => ({
  type: types.SET_CURRENT_HOME_INFO,
  propertyName,
  propertyHostInfo,
  propertyAddress,
  guestId,
  guestType,
  isProspective,
  region,
});

const clearCurrentPage = (homeId) => ({
  type: types.CLEAR_CURRENT_PAGE,
  homeId,
});

const changeFeatureDataKey = (featureDataKey) => ({
  type: types.CHANGE_FEATURE_DATA_KEY,
  featureDataKey,
});

const setCurrentFeatureDistance = (duration, distance) => ({
  type: types.SET_CURRENT_FEATURE_DISTANCE,
  duration,
  distance,
});

const setGAPILoaded = (status) => ({
  type: types.SET_GAPI_LOADED,
  status,
});

const setTOS = ({ privacy_policy, terms_of_service, contact_email }) => ({
  type: types.SET_TOS,
  privacy_policy,
  terms_of_service,
  contact_email,
});

const setCurrentMoreLinks = (links) => ({
  type: types.SET_CURRENT_MORE_LINKS,
  moreLinks: links,
});

const setCurrentBookNowLinks = (links) => ({
  type: types.SET_CURRENT_BOOK_NOW_LINKS,
  bookNowLinks: links,
});

const toggleHideBack = (hideBack = null) => ({
  type: types.TOGGLE_HIDE_BACK,
  hideBack
});

export default {
  setCurrentPage,
  setCurrentHomeInfo,
  setCurrentFeature,
  changeFeatureDataKey,
  setCurrentPropertyPages,
  clearCurrentPage,
  setCurrentFeatureDistance,
  setGAPILoaded,
  updateCurrentFeatureAddress,
  updateCurrentDestinationData,
  setTOS,
  setCurrentMoreLinks,
  setCurrentBookNowLinks,
  toggleHideBack
};
