import { useState, useEffect } from "react";

const useOrientation = () => {
  // function returning current orientation
  const getOrientation = () => {
    switch (window.orientation) {
      case -90:
      case 90:
        return "landscape";
      default:
        return "portrait";
    }
  };

  // store orientation
  const [orientation, setOrientation] = useState(getOrientation());

  // on mount, add event listener to resize to properly set orientation on resize
  useEffect(() => {
    const handleResize = () => {
      const newOrientation = getOrientation();

      setOrientation(newOrientation);
    };

    // window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return orientation;
};

export default useOrientation;
