import React from "react";
import { CSSTransition } from "react-transition-group";
import useInstallPrompt from "../../hooks/useInstallPrompt";
import isMobileDevice, {
  isAndroid,
  isIOS,
} from "../../utilities/isMobileDevice";
import InstallPrompt from "./InstallPrompt";

export const InstallPromptContext = React.createContext({
  showPrompt: false,
  handleClose: null,
});

function InstallPromptProvider({ children }) {
  const [showPrompt, handleClose] = useInstallPrompt(10);

  return (
    <InstallPromptContext.Provider value={{ showPrompt, handleClose }}>
      <div>
        <CSSTransition
          classNames="fade"
          in={showPrompt && isMobileDevice()}
          out={!showPrompt}
          timeout={500}
          mountOnEnter
          unmountOnExit
          appear
          data-testid="css-transition"
        >
          <div
            className="install-prompt-provider"
            data-testid="install-prompt-provider"
          >
            <InstallPrompt
              device={isAndroid() ? "android" : isIOS() ? "ios" : null}
            />
          </div>
        </CSSTransition>
      </div>
      {children}
    </InstallPromptContext.Provider>
  );
}

export default InstallPromptProvider;
