import React from "react";

import PrePromptRoot from "./app/login/PrePromptRoot";
import NoHomes from "./app/login/NoHomes";
import SettingsPage from "./app/settings/SettingsPage";
import {Redirect} from "react-router-dom";
import DashboardRoot from "./app/dashboard/DashboardRoot";
import GuestViewPage from "./app/login/GuestViewPage";

export default [
  {
    key: "manage",
    title: "Manage",
    path: "/manage",
    component: PrePromptRoot,
    auth: false
  },
  {
	key: "guest",
	title: "Guest",
	path: "/guest/view/:uniqueId",
	component: GuestViewPage,
	auth: false
  },
  {
    key: "settings",
    title: "Settings",
    path: "/userSettings/:subSetting?/:thirdSetting?",
    component: SettingsPage,
    auth: true
  },
  {
    key: "no-homes",
    title: "No Homes",
    path: "/no-homes",
    component: NoHomes,
    auth: false
  },
  {
    key: "dashboard",
    title: "Dashboard",
    path: "/app/:homeId/:primaryPage?/:secondaryPage?/:tertiaryPage?",
    component: DashboardRoot,
    auth: false
  },
  {
    key: "not-found",
    title: "Not Found",
    component: () => <Redirect to="/manage/login" />,
    auth: false
  }
];
