export default function generatePath(
  pageId,
  pageKey,
  pageObjectType,
  dashboardItems,
  path = ""
) {
  // get the current page object from redux state dashboardItems that matches param pageKey
  const currPageObject = dashboardItems.filter(({ key, id, objectType }) => {
    return key === pageKey && id === pageId && objectType === pageObjectType;
  })[0];

  //if the object doesnt exist, the page specified by pageKey was not given in initial endpoint
  // if so, we return home path

  if (!currPageObject) return "/";

  const parentPageObject = dashboardItems.find(({ key }) => {
    return key === currPageObject.parent;
  });

  //this needs to be rewritten**

  if (currPageObject.parent === "home") {
    if (path === "") {
      if (currPageObject.type !== "page") {
        path = `?feature=${encodeURIComponent(
          currPageObject.key
        )}&objectType=${encodeURIComponent(
          currPageObject.objectType
        )}&type=${encodeURIComponent(
          currPageObject.type
        )}&id=${encodeURIComponent(currPageObject.id)}`;
      } else {
        path = pageKey;
      }
    } else {
      if (currPageObject.type !== "page") {
        path = `?feature=${encodeURIComponent(
          currPageObject.key
        )}&objectType=${encodeURIComponent(
          currPageObject.objectType
        )}&type=${encodeURIComponent(
          currPageObject.type
        )}&id=${encodeURIComponent(currPageObject.id)}`;
      } else {
        if (path.substr(0, 1) === "?") {
          path = currPageObject.key + path;
        } else {
          path = currPageObject.key + "/" + path;
        }
      }
    }
    return path;
  } else {
    if (path === "") {
      if (currPageObject.type !== "page") {
        path = `?feature=${encodeURIComponent(
          currPageObject.key
        )}&objectType=${encodeURIComponent(
          currPageObject.objectType
        )}&type=${encodeURIComponent(
          currPageObject.type
        )}&id=${encodeURIComponent(currPageObject.id)}`;
      } else {
        path = currPageObject.key;
      }
    } else {
      if (path.substr(0, 1) === "?") {
        path = currPageObject.key + path;
      } else {
        path = currPageObject.key + "/" + path;
      }
    }

    return generatePath(
      parentPageObject.id,
      currPageObject.parent,
      parentPageObject.objectType,
      dashboardItems,
      path
    );
  }
}
