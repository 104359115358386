import React from "react";
import PropTypes from "prop-types";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";

const InputPass = props => {
  return (
    <input
      type={"password"}
      name={props.name}
      placeholder={props.placeholder}
      id={props.id ? props.id : props.name}
      value={props.value}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      disabled={props.disabled}
    />
  );
};

InputPass.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  placeholder: PropTypes.string
};

const InputText = props => {
  return (
    <input
      type={"text"}
      name={props.name}
      placeholder={props.placeholder}
      id={props.id ? props.id : props.name}
      value={props.value}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      disabled={props.disabled}
    />
  );
};

InputText.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  placeholder: PropTypes.string
};

function InputTel(props) {
  return (
    <PhoneInput
      type={"text"}
      country="US"
      name={props.name}
      placeholder={props.placeholder}
      id={props.id ? props.id : props.name}
      value={props.value}
      onChange={value => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={props.handleBlur}
      disabled={props.disabled}
    />
  );
}

function Input({
  type = "text",
  name,
  id,
  value,
  handleBlur,
  handleChange,
  placeholder,
  disabled,
  setFieldValue
}) {
  switch (type) {
    case "password": {
      return (
        <div className="input-wrapper">
          <InputPass
            name={name}
            placeholder={placeholder}
            id={id}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabled}
          />
        </div>
      );
    }

    case "tel": {
      return (
        <div className="input-wrapper">
          <InputTel
            name={name}
            placeholder={placeholder}
            id={id}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabled}
            setFieldValue={setFieldValue}
          />
        </div>
      );
    }
    default: {
      return (
        <div className="input-wrapper">
          <InputText
            name={name}
            placeholder={placeholder}
            type={type}
            id={id}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabled}
          />
        </div>
      );
    }
  }
}

export default Input;
