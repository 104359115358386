import types from './types';
const setCurrentSettingPage = (key, name, backPath) => ({
  type: types.SET_CURRENT_SETTING_PAGE,
  key,
  name,
  backPath
});



export default { setCurrentSettingPage };
