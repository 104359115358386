import { api } from "../../../utilities/APIWrapper";

import Creators from "./actions";
import { globalOperations } from "../../../duck";

import _get from "lodash.get";
import moment from "moment";

import analytics from "../../../utilities/GAHelper";

const fetchUserHouses = globalOperations.fetchUserHouses;
const setUserData = globalOperations.setUserData;
const setFallbackToken = globalOperations.setFallbackToken;
const loginUser = Creators.loginUser;
const setLoading = globalOperations.setLoading;

const loginWithCode = (code) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("loginCode", "REQUEST"));
    const now = moment();
    api
      .loginWithCode(code)
      .then((userData) => {
        analytics.sendAccountAction("User Login", `User ID: ${userData.id}`);
        analytics.setUserId(userData.id);
        dispatch(setUserData(userData));
        dispatch(setFallbackToken(userData.token));

        dispatch(fetchUserHouses())
          .then((houses) => {
            // sort houses and filter all previous dates
            const housesSorted = houses
              .sort((a, b) => {
                return (
                  new Date(a.check_in_date_time) -
                  new Date(b.check_in_date_time)
                );

                // if (!a.check_in_date_time || b.check_in_date_time) return 0;
                // const aDate = Math.abs(moment(a.check_in_date_time) - now);
                // const bDate = Math.abs(moment(b.check_in_date_time) - now);
                // return aDate - bDate;
              })
              .filter((d) => {
                if (!d.check_out_date_time) return true;
                return moment(d.check_out_date_time) - now > 0;
              });

            dispatch(setLoading("loginCode", "SUCCESS"));
            resolve(
              housesSorted.length > 0 ? housesSorted[0].id : houses[0].id
            );
          })
          .catch((err) => {
            dispatch(setLoading("loginCode", "FAILED"));
            window.console.error(err);
            if (_get(err, "response.status", null) === 401) {
              reject({
                status: err.response.status,
                message: "You are not authorized to view this content.",
              });
            }

            reject({
              status: _get(err, "response.status", null),
              message:
                "Unable to get user houses at this time. Please try again later.",
            });
          });
      })
      .catch((err) => {
        dispatch(setLoading("loginCode", "FAILED"));
        if (_get(err, "response.status", null) === 401) {
          reject({
            status: err.response.status,
            message: "Invalid username/password. Please try again.",
          });
        }

        reject({
          status: _get(err, "response.status", "500"),
          message: "Unable to login user at this time. Please try again later.",
        });
      });
  });
};

const handleLoginUser = (email, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("handleLogin", "REQUEST"));
    const now = moment();
    api
      .login(email, password)
      .then((userData) => {
        analytics.sendAccountAction("User Login", `User ID: ${userData.id}`);
        analytics.setUserId(userData.id);

        dispatch(setFallbackToken(userData.token));
        dispatch(setUserData(userData));

        dispatch(fetchUserHouses())
          .then((houses) => {
            // sort houses and filter all previous dates
            const housesSorted = houses
              .sort((a, b) => {
                return (
                  new Date(a.check_in_date_time) -
                  new Date(b.check_in_date_time)
                );

                // if (!a.check_in_date_time || b.check_in_date_time) return 0;
                // const aDate = Math.abs(moment(a.check_in_date_time) - now);
                // const bDate = Math.abs(moment(b.check_in_date_time) - now);
                // return aDate - bDate;
              })
              .filter((d) => {
                if (!d.check_out_date_time) return true;
                return moment(d.check_out_date_time) - now > 0;
              });
            dispatch(setLoading("handleLogin", "SUCCESS"));
            resolve(
              housesSorted.length > 0 ? housesSorted[0].id : houses[0].id
            );
          })
          .catch((err) => {
            window.console.error(err);
            if (_get(err, "response.status", null) === 401) {
              dispatch(setLoading("handleLogin", "FAILED"));
              reject({
                status: err.response.status,
                message: "You are not authorized to view this content.",
              });
            } else {
              setLoading("handleLogin", "FAILED");
              reject({
                status: _get(err, "response.status", null),
                message:
                  "Unable to get user houses at this time. Please try again later.",
              });
            }
          });
      })
      .catch((err) => {
        if (_get(err, "response.status", null) === 401) {
          dispatch(setLoading("handleLogin", "FAILED"));
          reject({
            status: err.response.status,
            message: "Invalid username/password. Please try again.",
          });
        } else {
          dispatch(setLoading("handleLogin", "FAILED"));
          reject({
            status: _get(err, "response.status", "500"),
            message:
              "Unable to login user at this time. Please try again later.",
          });
        }
      });
  });
};

const handleCreateGuest = (uniqueId, password, firstName, lastName) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    api
      .createGuest(uniqueId, password, firstName, lastName)
      .then((userData) => {
        analytics.sendAccountAction(
          "User Registered",
          `User ID: ${userData.id}`
        );
        dispatch(handleLoginUser(userData.email, password)).then(
          (mostRecentHouseId) => {
            resolve(mostRecentHouseId);
          }
        );
      })
      .catch((err) => {
        window.console.error(err);
        reject(err);
      });
  });
};

const handleCreateHost = (uniqueId, firstName, lastName, password) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    api
      .createHost(uniqueId, firstName, lastName, password)
      .then((userData) => {
        dispatch(handleLoginUser(userData.email, password)).then(
          (mostRecentHouseId) => {
            resolve(mostRecentHouseId);
          }
        );
      })
      .catch((err) => {
        window.console.error(err);
        reject(err);
      });
  });
};

const getPendingGuestInfo = (uniqueId, autoLogin) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .getPendingGuestInfo(uniqueId, autoLogin)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getPendingHostInfo = (uniqueId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api.getPendingHostInfo(uniqueId).then(resolve).catch(reject);
  });
};

const sendLoginLink = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("sendLoginLink", "REQUEST"));
    api
      .sendLoginLink(email)
      .then(() => {
        analytics.sendAccountAction("Get Login Link", email);
        dispatch(setLoading("sendLoginLink", "SUCCESS"));
        resolve();
      })
      .catch((err) => {
        window.console.error(err);
        dispatch(setLoading("sendLoginLink", "FAILED"));
        reject(err);
      });
  });
};

const validateResetId = (uniqueId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("validateResetId", "REQUEST"));
    api
      .validateResetId(uniqueId)
      .then((data) => {
        dispatch(setLoading("validateResetId", "SUCCESS"));
        resolve(data);
      })
      .catch((err) => {
        window.console.error(err);
        dispatch(setLoading("validateResetId", "FAILED"));
        reject(err);
      });
  });
};

const resetPassword = (email, password, confirmPassword, uniqueId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("resetPassword", "REQUEST"));
    api
      .resetPassword(email, password, confirmPassword, uniqueId)
      .then(() => {
        analytics.sendAccountAction("Reset Password", email);
        dispatch(setLoading("resetPassword", "SUCCESS"));
        resolve();
      })
      .catch((err) => {
        window.console.error(err);
        dispatch(setLoading("resetPassword", "FAILED"));
        reject(err);
      });
  });
};

const getHouseRegisterInfo = (uniqId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("getHouseRegisterInfo", "REQUEST"));
    api
      .getHouseRegisterInfo(uniqId)
      .then((houseData) => {
        dispatch(setLoading("getHouseRegisterInfo", "SUCCESS"));
        resolve(houseData);
      })
      .catch((err) => {
        console.error(err);
        dispatch(setLoading("getHouseRegisterInfo", "FAILED"));
        reject(err);
      });
  });
};

const registerHouseGuest = (
  houseId,
  { createPassword: password, email, firstName, lastName }
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .registerHouseGuest({
        houseId,
        password,
        email,
        firstName,
        lastName,
      })
      .then((userData) => {
        analytics.sendAccountAction(
          "User Registered",
          `User ID: ${userData.id}`
        );
        dispatch(handleLoginUser(userData.contact.email, password)).then(
          (mostRecentHouseId) => {
            resolve(userData);
          }
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const validateBrochureLink = (uniqueId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("getHouseRegisterInfo", "REQUEST"));
    api
      .validateBrochureLink(uniqueId)
      .then((data) => {
        dispatch(setLoading("getHouseRegisterInfo", "SUCCESS"));
        resolve(data);
      })
      .catch((err) => {
        dispatch(setLoading("getHouseRegisterInfo", "FAILED"));
        reject(err);
      });
  });
};

const registerBrochureGuest = (
  uniqueId,
  { createPassword: password, email, firstName, lastName }
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .registerBrochureGuest({
        uniqueId,
        password,
        email,
        firstName,
        lastName,
      })
      .then((userData) => {
        analytics.sendAccountAction(
          "User Registered",
          `User ID: ${userData.id}`
        );
        dispatch(handleLoginUser(userData.contact.email, password)).then(
          (mostRecentHouseId) => {
            resolve(userData);
          }
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  handleLoginUser,
  handleCreateGuest,
  loginUser,
  getPendingGuestInfo,
  getPendingHostInfo,
  sendLoginLink,
  validateResetId,
  resetPassword,
  loginWithCode,
  getHouseRegisterInfo,
  registerHouseGuest,
  handleCreateHost,
  validateBrochureLink,
  registerBrochureGuest,
};
