import React from "react";
import useGeolocation from "../../hooks/useGeolocation";

export const GeolocationContext = React.createContext();

function GeolocationProvider({ children, disabled }) {
  const [prompt, loading, geo] = useGeolocation(!disabled);

  return (
    <GeolocationContext.Provider
      value={{
        loading,
        geo,
        prompt,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
}

GeolocationProvider.propTypes = {};

export default GeolocationProvider;
