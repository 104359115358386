import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as HCLogo } from "../common/svg/HC_Logo.svg";
import { Link, useHistory } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import _get from "lodash.get";
import { prePromptOperation } from "./duck";
import GuestCreatePageForm from "./GuestCreatePageForm";
import { connect } from "react-redux";

function HostCreatePage({ getPendingHostInfo, createHost }) {
  const [preData, setPreData] = useState(null);
  const [preLoading, setPreLoading] = useState(true);
  const [preError, setPreError] = useState({});
  const [createId] = useQueryParam("id", StringParam);
  const history = useHistory();

  const onSubmit = (values, actions) => {
    const { confirmPassword: password, firstName, lastName } = values;
    createHost(createId, firstName, lastName, password)
      .then((mostRecentHouseId) => {
        actions.setSubmitting(false);
        history.push({
          state: { fadeTransition: false },
          pathname: `/app/${mostRecentHouseId}`,
        });
      })
      .catch((err) => {
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    if (!createId) return;
    getPendingHostInfo(createId)
      .then((response) => {
        const { firstName, email, lastName } = response;
        setPreData({
          firstName,
          email,
          lastName,
        });
        setPreLoading(false);
      })
      .catch((error) => {
        if (_get(error, "response.status") === 401) {
          setPreError({
            status: 401,
            text:
              "This account creation link is no longer available or has expired.",
          });
        } else {
          setPreError({
            status: 401,
            text: "This account creation link is no longer valid.",
          });
        }
        setPreLoading(false);
      });
  }, [createId, getPendingHostInfo]);

  if (preLoading === false) {
    return (
      <>
        <div className="guest-create-page">
          <div className="guest-create-page__inner">
            <div className="guest-create-page__header">
              <div className="guest-create-page__brand">
                <HCLogo />
              </div>
              <h4>Create your account</h4>
            </div>

            {!preError.status && preData ? (
              <div className="guest-create-page__form">
                <GuestCreatePageForm
                  email={preData.email}
                  firstName={preData.firstName}
                  lastName={preData.lastName}
                  onSubmit={onSubmit}
                  loading={false}
                />
              </div>
            ) : (
              <div className="guest-create-page__error">
                <p>{preError.text}</p>
                <p>
                  Please contact{" "}
                  <a href="mailto:help@homeconcierge.com">
                    help@homeconcierge.com
                  </a>{" "}
                  for assistance.
                </p>
              </div>
            )}
            <div className="guest-create-page__signin">
              <p>
                Have an account? <Link to="/manage/login">Sign in here.</Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}

HostCreatePage.propTypes = {
  getPendingHostInfo: PropTypes.func,
  createHost: PropTypes.func,
};

const mapDispatch = (dispatch) => ({
  getPendingHostInfo: (uniqueId) =>
    dispatch(prePromptOperation.getPendingHostInfo(uniqueId)),
  createHost: (uniqueId, firstName, lastName, password) =>
    dispatch(
      prePromptOperation.handleCreateHost(
        uniqueId,
        firstName,
        lastName,
        password
      )
    ),
});

export default connect(null, mapDispatch)(HostCreatePage);
