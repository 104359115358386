import React from "react";
import PropTypes from "prop-types";
import ResponsiveImage from "../common/ResponsiveImage";
import PillButton from "../common/PillButton";
import useOrientation from "../../utilities/useOrientation";

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

function GuestHousePreview({
  housePhoto,
  onSubmit,
  isHawaii,
  title = "Everything About YOUR Vacation Home in One Place.",
  description = "Your Host has customized the house information for your upcoming stay"
}) {
  const orientation = useOrientation();

  return (
    <div className="guest-create-pre">
      <div className="guest-create-pre__inner">
        <div className="guest-create-pre__image">
          <ResponsiveImage
            imageArray={[
              {
                size: "thumb",
                url: housePhoto
              },
              {
                size: "tablet",
                url: housePhoto
              },
              {
                size: "mobile",
                url: housePhoto
              }
            ]}
          />
        </div>
        <div className="guest-create-pre__text">
          <h2
            className={isHawaii ? "hawaii" : ""}
            data-testid="guest-create-pre-title"
          >
            {title}
          </h2>
          <p data-testid="guest-create-pre-description">
            {isFunction(description) ? description() : description}
          </p>
        </div>
        <div className="guest-create-pre__cta">
          <PillButton onClick={onSubmit} color="orange">
            Continue
          </PillButton>
        </div>
        <div className="guest-create-pre__bg">
          {orientation === "portrait" ? (
            <div className="guest-create-pre__bgitem guest-create-pre__bgitem--portrait"></div>
          ) : (
            <div className="guest-create-pre__bgitem guest-create-pre__bgitem--landscape"></div>
          )}
        </div>
      </div>
    </div>
  );
}

GuestHousePreview.propTypes = {
  housePhoto: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
};

export default GuestHousePreview;
