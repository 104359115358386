export default [
  { name: "Logout", key: "logout", parent: "userSettings", type: "route" },
  {
    name: "Contact",
    key: "contact",
    parent: "userSettings",
    type: "route",
  },
  {
    name: "Switch Houses",
    key: "switch-houses",
    parent: "userSettings",
    type: "route",
  },
  {
    name: "Send Brochure Invite",
    key: "send-brochure",
    parent: "userSettings",
    type: "route",
  },
  {
    name: "Privacy Policy",
    key: "privacy-policy",
    parent: "userSettings",
    type: "route",
  },
  {
    name: "Terms & Conditions",
    key: "terms-and-conditions",
    parent: "userSettings",
    type: "route",
  },
];
