import { ReactComponent as TerminalMapSVG } from './TerminalMap.svg';
import { ReactComponent as RentalMapSVG } from './RentalMap.svg';
import { ReactComponent as taxiServicesSVG } from './Taxi.svg';
import { ReactComponent as carRentalsSVG } from './CarRentals.svg';
import { ReactComponent as uberSVG } from './Uber.svg';
import { ReactComponent as lyftSVG } from './Lyft.svg';
import { ReactComponent as airportDirectionsSVG } from './AirportDirections.svg';
import { ReactComponent as airportWebsiteSVG } from './AirportWebsite.svg';
import TerminalMapSVGLink from './TerminalMap.svg';
import RentalMapSVGLink from './RentalMap.svg';
import taxiServicesSVGLink from './Taxi.svg';
import carRentalsSVGLink from './CarRentals.svg';
import uberSVGLink from './Uber.svg';
import lyftSVGLink from './Lyft.svg';
import airportDirectionsSVGLink from './AirportDirections.svg';
import airportWebsiteSVGLink from './AirportWebsite.svg';
export default {
  rentalMap: { inline: RentalMapSVG, link: RentalMapSVGLink },
  taxiMap: { inline: TerminalMapSVG, link: TerminalMapSVGLink },
  terminalMap: { inline: TerminalMapSVG, link: TerminalMapSVGLink },
  taxis: { inline: taxiServicesSVG, link: taxiServicesSVGLink },
  carRentals: { inline: carRentalsSVG, link: carRentalsSVGLink },
  airportWebsite: { inline: airportWebsiteSVG, link: airportWebsiteSVGLink },
  airportDirections: {
    inline: airportDirectionsSVG,
    link: airportDirectionsSVGLink
  },
  uber: { inline: uberSVG, link: uberSVGLink },
  lyft: { inline: lyftSVG, link: lyftSVGLink }
};
