import { createSelector } from "reselect";
import _get from "lodash.get";

// boolean, if the guest is a potential guest
const isPotentialSelector = (state) =>
  _get(state, "dashboardReducer.propertyInfo.isPotential", null);

// Checks the isCheckedOut value for the current home
export const isCheckedOutSelector = (state) => {
  return state.userReducer.houses ? _get(
    state.userReducer.houses.find(
      ({ id }) =>
        id === parseInt(_get(state.dashboardReducer, "currentHomeId", null))
    ),
    "isCheckedOut",
    null
  ) : false;
};

const guestHousePermissionSelector = (state) =>
  state.userReducer.houses ? _get(
    state.userReducer.houses.find(
      ({ id }) =>
        id === parseInt(_get(state.dashboardReducer, "currentHomeId", null))
    ),
    "permission",
    null
  ) : false;

const isRestrictedSelectorDeps = [
  isPotentialSelector,
  isCheckedOutSelector,
  guestHousePermissionSelector,
];

const isRestrictedSelector = createSelector(
  isRestrictedSelectorDeps,
  (isPotential, isCheckedOut, guestPermission) => {
    if (
      isPotential && _get(guestPermission, "type")
        ? !guestPermission.type.match(/^(owner|host)$/)
        : false
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export default isRestrictedSelector;
