import React from "react";
import PropTypes from "prop-types";
import navBarObjects from "./navBarObjects";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _get from "lodash.get";

import parseQuery from "stringquery";
import { isValidEmbedUrl } from "../../utilities/isValidEmbedUrl";

class NavBarItemWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: null,
      name: null,
      action: null,
    };
  }

  generateItemObject(itemKey) {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => (res = obj[key]), {});

    const currentObject = Object.filter(
      navBarObjects,
      (obj) => obj.key === itemKey
    );
    return currentObject;
  }

  componentDidMount() {
    const { itemKey } = this.props;
    const currentItemObject = this.generateItemObject(itemKey);

    this.setState({
      icon: currentItemObject.icon,
      name: currentItemObject.name,
    });
  }

  generateURL(url) {
    if (!/^https?:\/\//i.test(url)) {
      return "https://" + url;
    } else {
      return url;
    }
  }

  render() {
    const {
      children,
      itemKey,
      queryParams,
      phone,
      website,
      address,
      reservations,
      directory,
      menu,
      host,
      videoUrl,
      userManualUrl,
      moreLinks,
      pageKey,
      email,
	  inventory,
	  currentUserRole
    } = this.props;
    const { icon, name } = this.state;
    const pathByKey = (key) => {
      if (!itemKey) return null;
      if (itemKey.match("^MORE_")) {
        return moreLinks[pageKey];
      }

      switch (key) {
        case "home":
          return { pathname: `/app/${this.props.match.params.homeId}` };
        case "website":
          //replace with current feature site from redux store
          return this.generateURL(website);
        case "more":
          return {
            pathname: "/userSettings",
          };

        case "manual-website":
          return this.generateURL(userManualUrl);
        case "call":
          return `tel:${phone}`;

        case "backToApp":
          return { pathname: `/app/${this.props.match.params.homeId}` };

        case "video":
          const [isValidEmbed] = isValidEmbedUrl(videoUrl);

          if (isValidEmbed) {
            return {
              search: queryParams.feature
                ? `?feature=${queryParams.feature}&objectType=${queryParams.objectType}&type=${queryParams.type}&id=${queryParams.id}&popup=${key}`
                : `?popup=${key}`,
            };
          }
          return this.generateURL(videoUrl);

        case "directory":
          return this.generateURL(directory);
        case "menu":
          return this.generateURL(menu);
        default:
          return {
            search: queryParams.feature
              ? `?feature=${queryParams.feature}&objectType=${queryParams.objectType}&type=${queryParams.type}&id=${queryParams.id}&popup=${key}`
              : `?popup=${key}`,
          };
      }
    };

    const path = pathByKey(itemKey);

    // if the current item is a MORE item but their is no morelink set in redux state
    // return null
    if (itemKey && itemKey.match("^MORE_") && !moreLinks[pageKey]) {
      return null;
    }

    if (itemKey === "call" && phone && reservations) {
      return null;
    }

    if (itemKey === "call" && !phone) {
      return null;
    }
    if (itemKey === "website" && !website) {
      return null;
    }
    if (itemKey === "reservations" && !reservations) {
      return null;
    }

    if (
      [
        "contact_chef",
        "contact_masseuse",
        "contact_tour_guide",
        "contact_shopper",
      ].includes(itemKey) &&
      !phone &&
      !email
    ) {
      return null;
    }

    if (itemKey === "contact_host" && !host) {
      return null;
    }

    if (itemKey === "video" && !videoUrl) {
      return null;
    }

    if (itemKey === "manual-website" && !userManualUrl) {
      return null;
    }

    if (itemKey === "directory" && !directory) {
      return null;
    }

    if (itemKey === "menu" && !menu) {
      return null;
    }

	if (itemKey === "inventory" && !inventory) {
	  return null;
	}

    if (
      itemKey === "directions" &&
      (!address ||
        _get(address, "fullAddress", null) === null ||
        (_get(address, "fullAddress", null) === "" &&
          (_get(address, "lat", null) === null ||
            _get(address, "lng", null) === null)))
    ) {
      return null;
    }

    if (!icon) return null;

    return (
      <div
        className={`navbar-item ${itemKey}`}
        data-testid={`navbar-item`}
        role={itemKey}
      >
        {children({
          name,
          path,
          icon,
        })}
      </div>
    );
  }
}
const mapStateToProps = (state, { location }) => ({
  queryParams: parseQuery(location.search),

  inventory: state.dashboardReducer.currentFeature
	? state.dashboardReducer.currentFeature.featureData.inventory
	: null,

  phone: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.phone
    : null,
  email: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.email
    : null,
  website: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.website
    : null,
  address: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.address
    : null,
  reservations: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.reservation
    : null,
  directory: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.directory
    : null,
  menu: state.dashboardReducer.currentFeature
    ? state.dashboardReducer.currentFeature.featureData.menu
    : null,
  host: _get(state.dashboardReducer, "propertyInfo.propertyHost"),
  videoUrl: _get(
    state.dashboardReducer,
    `currentFeature.featureData.items[${_get(
      state.dashboardReducer,
      "currentFeature.featureDataKey",
      ""
    )}].video_url`,
    null
  ),
  userManualUrl: _get(
    state.dashboardReducer,
    `currentFeature.featureData.items[${_get(
      state.dashboardReducer,
      "currentFeature.featureDataKey",
      ""
    )}].user_manual_url`,
    null
  ),
  moreLinks: _get(state.dashboardReducer, "moreLinks", []),
  pageKey: _get(state.dashboardReducer, "currentPage.pageKey", null),
  currentUserRole: _get(state.userReducer, "user.role", null)
});
NavBarItemWrapper.propTypes = {
  itemKey: PropTypes.string,
  children: PropTypes.any,
  location: PropTypes.object,
  queryParams: PropTypes.object,
  phone: PropTypes.string,
  website: PropTypes.string,
  address: PropTypes.object,
  reservations: PropTypes.string,
  directory: PropTypes.string,
  menu: PropTypes.string,
  match: PropTypes.object,
  inventory: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(NavBarItemWrapper));
