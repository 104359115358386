import React from "react";
import PropTypes from "prop-types";
import MUIModal from "@material-ui/core/Modal";
import ListItem from "./ListItem";
import { isInStandaloneMode } from "../../utilities/isMobileDevice";
import largeHCFaviconPNG from "../../assets/hc-favicon-large.png";
import SafariIconPNG from "../../assets/safari-icon.png";
import PillButton from "./PillButton";
import AppleMapsPNG from "../../assets/apple-maps-icon.png";
import hcRedPinPNG from "../../assets/hc-red-pin.png";
import getDirectionsBtnPng from "../../assets/get-directions-btn.png";
import localforage from "localforage";

function MultiWarningModal({ onClose }) {
  const handleLinkClick = (evt) => {
    localforage.setItem("multi_prompted", "true");
    onClose(evt);
  };

  return (
    <MUIModal
      open={true}
      onClose={onClose}
      className="multi-warning-modal"
      disablePortal
      disableAutoFocus
      disableEnforceFocus
    >
      <div className="multi-warning-modal__inner">
        <div className="multi-warning-modal__top">
          <p>
            Note: When you select a location
            <img src={hcRedPinPNG} alt="Destination" width="30" />
          </p>
          <p>
            and tap on<br></br> "Get Directions"{" "}
            <img src={getDirectionsBtnPng} alt="Get directions" width="125" />
          </p>
        </div>
        <div className="multi-warning-modal__body">
          <ul className="multi-warning-modal__bodylist">
            <ListItem index={1}>
              You will be leaving <br></br>
              <strong>Home Concierge</strong> to open the{" "}
              <strong>
                Apple Maps
                <img src={AppleMapsPNG} alt={"apple maps"} />
              </strong>
              app.
            </ListItem>
            <ListItem index={2}>
              {isInStandaloneMode() ? (
                <>
                  Tap on the{" "}
                  <strong>
                    Home Concierge app
                    <img
                      src={largeHCFaviconPNG}
                      alt="Home Concierge app"
                    />{" "}
                  </strong>{" "}
                  to return to Home Concierge after you've reached your
                  destination.
                </>
              ) : (
                <>
                  Tap on{" "}
                  <strong>
                    Safari
                    <img src={SafariIconPNG} alt="Safari" />
                  </strong>
                  to return to Home Concierge after you've reached your
                  destination.
                </>
              )}
            </ListItem>
          </ul>
        </div>

        <div className="multi-warning-modal__buttons">
          <PillButton shape="rounded" color="blue" onClick={handleLinkClick}>
            Close This Window
          </PillButton>
        </div>
      </div>
    </MUIModal>
  );
}

MultiWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MultiWarningModal;
