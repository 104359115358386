import { ReactComponent as housePhotosSVG } from './HousePhotos.svg';
import { ReactComponent as weatherSVG } from './Weather.svg';
import { ReactComponent as localNewsSVG } from './LocalNews.svg';
import { ReactComponent as shopSVG } from './Shop.svg';
import { ReactComponent as dineSVG } from './Dine.svg';
import { ReactComponent as playSVG } from './Play.svg';
import { ReactComponent as spaSVG } from './SPA.svg';
import { ReactComponent as musicSVG } from './Music.svg';
import { ReactComponent as emergencySVG } from './Emergency.svg';
import { ReactComponent as houseFeaturesSVG } from './HouseFeatures.svg';
import { ReactComponent as tvOfficeSVG } from './TvOffice.svg';
import { ReactComponent as guestServicesSVG } from './GuestServices.svg';
import { ReactComponent as airportDetailsSVG } from './AirportDetail.svg';
import { ReactComponent as arrivalDetailsSVG } from './ArrivalDetails.svg';
import housePhotosSVGLink from './HousePhotos.svg';
import weatherSVGLink from './Weather.svg';
import localNewsSVGLink from './LocalNews.svg';
import shopSVGLink from './Shop.svg';
import dineSVGLink from './Dine.svg';
import playSVGLink from './Play.svg';
import spaSVGLink from './SPA.svg';
import musicSVGLink from './Music.svg';
import emergencySVGLink from './Emergency.svg';
import houseFeaturesSVGLink from './HouseFeatures.svg';
import tvOfficeSVGLink from './TvOffice.svg';
import guestServicesSVGLink from './GuestServices.svg';
import airportDetailsSVGLink from './AirportDetail.svg';
import arrivalDetailsSVGLink from './ArrivalDetails.svg';
const homeIcons = {
  airportDetails: { inline: airportDetailsSVG, link: airportDetailsSVGLink },
  arrivalDetails: { inline: arrivalDetailsSVG, link: arrivalDetailsSVGLink },
  housePhotos: { inline: housePhotosSVG, link: housePhotosSVGLink },
  weather: { inline: weatherSVG, link: weatherSVGLink },
  localNews: { inline: localNewsSVG, link: localNewsSVGLink },
  shop: { inline: shopSVG, link: shopSVGLink },
  dine: { inline: dineSVG, link: dineSVGLink },
  play: { inline: playSVG, link: playSVGLink },
  spa: { inline: spaSVG, link: spaSVGLink },
  music: { inline: musicSVG, link: musicSVGLink },
  emergency: { inline: emergencySVG, link: emergencySVGLink },
  houseFeatures: { inline: houseFeaturesSVG, link: houseFeaturesSVGLink },
  tvOffice: { inline: tvOfficeSVG, link: tvOfficeSVGLink },
  guestServices: { inline: guestServicesSVG, link: guestServicesSVGLink }
};

export default homeIcons;
