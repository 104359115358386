import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import FeatureLoader from "./app/featurepage/FeatureLoader";
import Toasts from "./app/toast/Toasts";
import { dashboardOperations } from "./app/dashboard/duck";
import RouteGenerator from "./app/common/RouteGenerator";
import useQueryParams from "./hooks/useQueryParams";

import { globalOperations } from "./duck";

import initialLoadingSelector from "./selectors/initialLoadingSelector";

import routes from "./routes.js";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";
import _get from "lodash.get";
import { connect } from "react-redux";

import scriptLoader from "react-async-script-loader";
import useReactGA from "./hooks/useReactGA";
import TidioProvider from "./app/common/TidioProvider";

import { pusherApi } from "./utilities/broadcastPusher";

import generateManifest from "./utilities/generateManifest";

// App: root of application
// handles fetching cache for user token/data, fetches house list, initializes google analyrics and generates application routes
function App({
  checkIfLoggedIn,
  initialLoading,
  initLoading,
  isScriptLoaded,
  isScriptLoadSucceed,
  fallbackToken,
  userUid
}) {
  const [initialize] = useReactGA(
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  );

  const [GAPILoaded, setGAPILoaded] = useState(false);
  const queryParams = useQueryParams();

  // on mount, check if user is currently logged in
  // if resolved, initialize GA with user id. Otherwise, initialize without userID
  useEffect(() => {
    const fallbackToken = queryParams.get("token");

	pusherApi.initPublicChannel();

    checkIfLoggedIn(fallbackToken)
      .then((userData) => {
        initialize({ userId: userData.id });

        // connect to private channel with pusher
        const user_token = userData.token.substr(7);
        pusherApi.initPrivateChannel(user_token, userData.unique_id);
      })
      .catch((err) => {
        initialize(null);
      });
  }, [checkIfLoggedIn, initialize, queryParams]);

  useEffect(() => {
    if (isScriptLoaded) {
      if (isScriptLoadSucceed) {
        setGAPILoaded(true);
      } else {
        setGAPILoaded(false);
      }
    }
  }, [isScriptLoadSucceed, isScriptLoaded, setGAPILoaded]);

  useEffect(() => {
	generateManifest(userUid);
  }, [userUid]);

  // get current location
  const location = useLocation();

  if (!GAPILoaded) return null;

  return (
    <TidioProvider enabledPath={"/userSettings"}>
      <div id="app">
        <CSSTransition
          classNames="fade"
          in={initialLoading || initLoading || !GAPILoaded}
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <FeatureLoader loading={true} />
        </CSSTransition>

        {!initialLoading && (
          <TransitionGroup appear>
            <CSSTransition
              classNames="fade"
              appear
              mountOnEnter
              unmountOnExit
              key={
                location.pathname.substring(0, 13) === "/userSettings"
                  ? "settingsFade"
                  : "dashboardFade"
              }
              timeout={300}
            >
              <RouteGenerator routes={routes} location={location} />
            </CSSTransition>
          </TransitionGroup>
        )}
        <Toasts />
      </div>
    </TidioProvider>
  );
}

const mapState = (state) => ({
  initialLoading: initialLoadingSelector(state),
  initLoading: _get(state.loadingReducer, "init.loading", false),
  fallbackToken: _get(state.userReducer, "fallbackToken", false),
  userUid: _get(state.userReducer, "user.unique_id", null),
});

const mapDispatch = (dispatch) => ({
  setGAPILoaded: (status) =>
    dispatch(dashboardOperations.setGAPILoaded(status)),
  checkIfLoggedIn: (fallbackToken) =>
    dispatch(globalOperations.checkIfLoggedIn(fallbackToken)),
});

App.propTypes = {
  initialLoading: PropTypes.bool.isRequired,
  initLoading: PropTypes.bool.isRequired,
  setGAPILoaded: PropTypes.func.isRequired,
  checkIfLoggedIn: PropTypes.func.isRequired,
};

const ConnectedApp = connect(mapState, mapDispatch)(App);

export default scriptLoader(
  `https://maps.google.com/maps/api/js?units=imperial&key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
)(ConnectedApp);
