import queryParse from "stringquery";

const initialState = {
  feature: null,
  type: null,
  popup: null
};

const middlewareFeatureQueryChange = store => next => action => {
  if (action.type === "@@router/LOCATION_CHANGE") {
    if (action.payload.location.search !== "") {
      const parsedQueryParams = queryParse(action.payload.location.search);

      store.dispatch({
        type: "@@middleware/FEATURE_QUERY_CHANGE",
        payload: parsedQueryParams
      });
    } else {
      store.dispatch({
        type: "@@middleware/FEATURE_QUERY_CHANGE",
        payload: initialState
      });
    }
  }
  next(action);
};

const queryParamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@middleware/FEATURE_QUERY_CHANGE":
      return {
        feature: action.payload.feature,
        popup: action.payload.popup,
        objectType: action.payload.objectType,
        type: action.payload.type,
        id: action.payload.id,
        email: action.payload.email,
        fname: action.payload.fname,
        lname: action.payload.lname,
        guestId: action.payload.guestId
      };
    default:
      return state;
  }
};

export { queryParamsReducer, middlewareFeatureQueryChange };
