import types from "./types";

const setLoading = (requestName, requestStatus, context) => ({
  type: types.SET_LOADING,
  requestName,
  requestStatus,
  context
});

const setUserData = (userData) => ({
  type: types.SET_USER_DATA,
  userData,
});

const setFallbackToken = (token) => ({
  type: types.SET_FALLBACK_TOKEN,
  token
});

const setUserHouses = (userHouses) => ({
  type: types.SET_USER_HOUSES,
  userHouses,
});

const setLogout = () => ({
  type: types.LOGOUT_USER,
});

const addSecondaryGuest = (guestId, email, firstName, lastName) => ({
  type: types.ADD_SECONDARY_GUEST,
  guestId,
  email,
  firstName,
  lastName,
});

const setUserInvitedGuests = (guests) => ({
  type: types.SET_USER_INVITED_GUESTS,
  guests,
});

const removeSecondaryGuest = (guestId) => ({
  type: types.REMOVE_SECONDARY_GUEST,
  guestId,
});

const setCurrentPlatform = (platform) => ({
  type: types.SET_CURRENT_PLATFORM,
  platform,
});

export default {
  setLoading,
  setUserData,
  setFallbackToken,
  setUserHouses,
  setLogout,
  addSecondaryGuest,
  setUserInvitedGuests,
  removeSecondaryGuest,
  setCurrentPlatform,
};
