const computeAddrString = (
  { types, formatted_address, geometry, name },
  options = { withName: false }
) => {
  const { withName } = options;

  if (types.includes("plus_code")) {
    const lat = geometry.location.lat();
    const lng = geometry.location.lng();

    return `${lat},${lng}`;
  } else {
    if (withName) return `${name}, ${formatted_address}`;
    return formatted_address;
  }
};

export default computeAddrString;
