import React from "react";
import PropTypes from "prop-types";
import sanitize from "sanitize-html";

function SanitizedHTML({ markup, className }) {
  if (!markup) return null;
  return (
    <div
      data-testid="sanitized-html"
      className={"sanitized-html__root" + (className ? ` ${className}` : ``)}
      dangerouslySetInnerHTML={{
        __html: sanitize(markup, { allowedTags: ["p", "span", "br", "div"] })
      }}
    />
  );
}

SanitizedHTML.propTypes = {
  markup: PropTypes.string.isRequired
};

export default SanitizedHTML;
