import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { ReactComponent as HCStandaloneIconSVG } from "./svg/dashboard/hc_standalone_icon.svg";
import clsx from "clsx";
import { useSelector } from "react-redux";

import _get from "lodash.get";

function DashboardHeaderContainer({
  propertyName,
  pageName,
  platform = "dashboard",
  previousPath,
  isHome,
  hideBack
}) {
  const region = useSelector(state =>
    _get(state.dashboardReducer, "propertyInfo.region", null)
  );

  //generate header title markup based on curr page
  const headerMarkup = isHome =>
    !isHome ? (
      <>
		{!hideBack && <Link
		  className="page-back"
		  to={{pathname: previousPath}}
		  data-testid="dashboard-header-back"
		/>
		}
        <div
          className="page-title-container"
          data-testid="dashboard-header-title-inside"
        >
          <span className="page-title-icon">
            <HCStandaloneIconSVG />
          </span>
          <h2 className="page-title subpage">{pageName}</h2>
        </div>
      </>
    ) : (
      <h2
        className={clsx({
          "page-title": true,
          home: true,
          hawaii: region ? region.name.toLowerCase() === "kona" : true
        })}
        data-testid="dashboard-header-title-home"
      >
        {platform === "dashboard" ? "Welcome to" : "Camera"}{" "}
        <span
          className="intro-residence"
          data-testid="dashboard-header-property-name"
          style={{ fontSize: propertyName.length > 16 ? "1.5em" : "1.7em" }}
        >
          {propertyName}
        </span>
      </h2>
    );

  return (
    <header id="db-header" data-testid="dashboard-header">
      <div className="db-header-content">
        <div className="db-header-intro">{headerMarkup(isHome)}</div>
      </div>
    </header>
  );
}

DashboardHeaderContainer.propTypes = {
  isHome: PropTypes.bool,
  propertyName: PropTypes.string,
  pageName: PropTypes.string,
  history: PropTypes.object,
  previousPath: PropTypes.string,
  hideBack: PropTypes.bool
};

export default withRouter(DashboardHeaderContainer);
