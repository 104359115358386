import React, {useEffect, useState} from "react";
import { ReactComponent as HCStandaloneIconSVG } from "../../common/svg/dashboard/hc_standalone_icon.svg";
import { ReactComponent as IconEmail } from "./svg/icon-email.svg";
import { ReactComponent as IconText } from "./svg/icon-text.svg";
import analytics from "../../../utilities/GAHelper";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import trimEnd from 'lodash/trimEnd';
import { connect } from "react-redux";

import _get from "lodash.get";

function SharePage({
  currentHomeId,
  propertyName,
  houses
}) {
  const [shareEmailLink, setShareEmailLink] = useState('');
  const [shareTextLink, setShareTextLink] = useState('');
  const [shouldShowSharing, setShouldShowSharing] = useState(false);

  const subject = 'You have been invited to view your vacation home' + (propertyName ? ', ' + propertyName : '');
  const propertyBody = propertyName ? propertyName : ' your vacation home';

  useEffect(() => {
	  if (houses) {
		const guestHouse = houses.find(({ id }) => id === parseInt(currentHomeId));
		const guestPermissions = _get(guestHouse, 'permission');
		if (guestPermissions) {

		  const baseUrl = trimEnd(process.env.REACT_APP_SHARE_URL, '/');
		  const shareUrl = `${baseUrl}/${guestPermissions.shareUuid}`;

		  const emailBody = encodeURIComponent('HOME CONCIERGE is your complimentary digital concierge service to ' + propertyBody + '.\r\n\r\n' +
			'It is an extensive "tour" of the home and resource for where to shop, dine, play, etc. in the area\r\n\r\n' +
			'If you have any other questions don\'t hesitate to reach out.\r\n\r\n' + shareUrl);

		  const emailLink = `mailto:?subject=${subject}&body=${emailBody}`;

		  setShareEmailLink(emailLink);

		  const textLink = `sms:?&body=${subject}: ${shareUrl}`;

		  setShareTextLink(textLink);

		  setShouldShowSharing(true);
		}
	  }
  }, [houses, currentHomeId]);

  return (
    <div className="share-page-wrapper">
      <div className="share-page-header">
        <Link
          className="page-back"
          to={{ pathname: `/app/${currentHomeId}` }}
        />
        <div className="share-page-header-inner">
          <span className="share-page-title-icon">
            <HCStandaloneIconSVG />
          </span>

          <h2 className="share-page-title">Share with Family & Friends</h2>
        </div>
      </div>
	  {shouldShowSharing && (
		<>
		  <div className="share-page-list">
			<a className="btn-share btn-share__email" href={shareEmailLink}
			   title="Share your login via email" target="_blank" onClick={() => analytics.sendGuestAction("Share", 'Email')}><IconEmail/> <span>Email A Link</span>
			</a>
			<br/>
			<a className="btn-share btn-share__text" href={shareTextLink}
			   title="Share your login via text message" target="_blank" onClick={() => analytics.sendGuestAction("Share", 'Text Message')}><IconText/> <span>Text A Link</span>
			</a>
		  </div>
		</>
	  )}
	  {! shouldShowSharing && (
		<>
		  <div className="share-page-text">
			<p>
			  You cannot share this account because it is not a guest account to this house.
			</p>
		  </div>
		</>
	  )}
	</div>
  );
}

const mapState = (state) => ({
  propertyName: _get(state.dashboardReducer, 'propertyInfo.propertyName', null),
  houses: _get(state.userReducer, 'houses', null)
});

SharePage.propTypes = {
  currentHomeId: PropTypes.number.isRequired,
  guests: PropTypes.array,
  showInvitePrompt: PropTypes.func.isRequired,
};

export default connect(mapState)(SharePage);
