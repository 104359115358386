import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
		<Component {...props} />
      }
    />
  );
};

const mapState = state => ({
  isLoggedIn: Boolean(state.userReducer.user)
});

export default connect(mapState)(PrivateRoute);
