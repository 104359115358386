import axios from "axios";
import localforage from "localforage";

import * as Cookies from "js-cookie";

import _get from "lodash.get";

import removeSubDomain from "./removeSubDomain";

class APIWrapper {
  constructor() {
    this.apiURL = process.env.REACT_APP_API_BASE_URL;

    axios.defaults.baseURL = this.apiURL;
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  setAuthHeader(token) {
    axios.defaults.headers.common = {
      Authorization: token,
    };
  }

  clearAuthHeader() {
    axios.defaults.headers.common = {};
  }

  async setOpenApp(houseId, guestId) {
    try {
      const { data } = await axios.post("/user/setOpenApp", {
        houseId,
        guestId,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async inviteSecondaryGuest(guestId, email, firstName, lastName) {
    try {
      const { data } = await axios.post(
        "/user/guest/inviteSecondaryGuest",
        { guestId, email, firstName, lastName },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data.invitedGuest;
    } catch (error) {
      throw error;
    }
  }

  async deleteSecondaryGuest(houseId, guestId) {
    try {
      const { data } = await axios.delete("/user/guest/deleteSecondaryGuest", {
        data: {
          houseId,
          guestId,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async checkUserCredentials(fallbackToken = false) {
    let userData = await localforage.getItem("userData");

    const sharedAuthCookie = Cookies.get("SharedAuthorization");

    if (sharedAuthCookie) {
      this.setAuthHeader(sharedAuthCookie);
      const { data } = await axios.get("/user/getUserData");

      const updatedUserData = {
        ...data.userData,
        token: sharedAuthCookie,
      };

      await this.storeUserCredentials(updatedUserData);

      return updatedUserData;
    }

    if (!_get(userData, "token", false)) {
      if (fallbackToken) {
        this.setAuthHeader(fallbackToken);

        const { data } = await axios.get("/user/getUserData");

        const updatedUserData = {
          ...data.userData,
          token: fallbackToken,
        };
        await this.storeUserCredentials(updatedUserData);

        return updatedUserData;
      }

      throw new Error("No stored token");
    } else {
      this.setAuthHeader(userData.token);
      const { data } = await axios.get("/user/getUserData");

      const updatedUserData = {
        ...data.userData,
        token: userData.token,
      };
      await this.storeUserCredentials(updatedUserData);

      return updatedUserData;
    }
  }

  async storeUserCredentials({ email, role, first_name, last_name, token }) {
    await localforage.removeItem("userData");

    await localforage.setItem(
      "userData",
      { email, role, first_name, last_name, token },
      (err) => {
        if (err) throw err;
        return {
          email,
          role,
          first_name,
          last_name,
          token,
        };
      }
    );
  }

  async getPendingGuestInfo(uniqueId, autoLogin) {
    try {
      const { data } = await axios.get("/initializeGuest", {
        params: {
          uniqueId,
		  autoLogin
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPendingHostInfo(uniqueId) {
    try {
      const { data } = await axios.get("/initializeHost", {
        params: { uniqueId },
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createHost(uniqueId, firstName, lastName, password) {
    const { data } = await axios.post("/finalizePortalUser", {
      accountType: "individual",
      uniqueId,
      firstName,
      lastName,
      password,
    });
    return data;
  }

  async getUserHouses() {
    try {
      const { data } = await axios.get("/getGuestHomes");

      return data.houses;
    } catch (error) {
      throw error;
    }
  }

  async login(email, password) {
    try {
      const { data } = await axios.post(
        "/user/login",
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await this.storeUserCredentials(data);
      this.setAuthHeader(data.token);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async loginWithCode(code) {
    try {
      const { data } = await axios.post("/user/code/login", { code });
      await this.storeUserCredentials(data);
      this.setAuthHeader(data.token);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    let response, error;
    const rootDomain = removeSubDomain(window.location.hostname);

    // try to make request to /user/logout
    try {
      response = await axios.get("/user/logout");
    } catch (err) {
      error = err;
    }

    // if fails or succeeds, remove local storage user data,
    // cached user data, sharedAuth cookie, and axios auth header

    await localforage.removeItem("userData");

    Cookies.remove("SharedAuthorization", {
      domain: rootDomain,
    });
    this.clearAuthHeader();

    // if error is present, throw it
    if (error) throw error;

    // otherwise, return response of /user/logout
    return response.data;
  }

  async createGuest(uniqueId, password, firstName, lastName, phoneNumber) {
    try {
      const { data } = await axios.post("/finalizeGuest", {
        uniqueId,
        accountType: "individual",
        companyName: null,
        password,
        firstName,
        lastName,
        phoneNumber,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async registerHouseGuest({ houseId, email, password, firstName, lastName }) {
    try {
      const { data } = await axios.post("/registerHouseGuest", {
        houseId,
        accountType: "individual",
        companyName: null,
        password,
        firstName,
        lastName,
        email,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendLoginLink(email) {
    try {
      const { data } = await axios.post("/user/loginLink", {
        origin: "app",
        email,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validateResetId(uniqueId) {
    try {
      const { data } = await axios.get(`/user/getEmailFromToken/${uniqueId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(email, password, confirmPassword, uniqueId) {
    try {
      const { data } = await axios.post("/user/resetPassword", {
        email,
        password,
        password_confirmation: confirmPassword,
        token: uniqueId,
      });
      // this.setAuthHeader(data.token);
      // await this.storeUserCredentials(data);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async manualLogout() {
    const rootDomain = removeSubDomain(window.location.hostname);

    await localforage.removeItem("userData");
    this.clearAuthHeader();

    Cookies.remove("SharedAuthorization", {
      domain: rootDomain,
    });
    return true;
  }

  async getPropertyInfo(houseId) {
    try {
      const { data } = await axios.get(
        `/app/getPropertyInfo?houseId=${houseId}`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPropertyFeature(homeId, featureId, featureObjectType) {
    try {
      const { data } = await axios.get(
        `/app/getPropertyFeature?houseId=${homeId}&featureId=${featureId}&objectType=${featureObjectType}`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCameraInfo(houseId) {
    try {
      const { data } = await axios.get(
        `/user/house/getCameraInfo?houseId=${houseId}`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCameraFeature(houseId, featureId, featureKey) {
    try {
      const { data } = await axios.get(
        `/user/house/getCameraFeature?houseId=${houseId}&featureKey=${featureKey}&featureId=${featureId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFeatureItem({
    houseId,
    featureKey,
    featureId,
    featureItemId,
    pageSequence,
    pageText,
    formData,
    pagePhotoX,
    pagePhotoY,
    pagePhotoHeight,
    pagePhotoWidth,
    pageCircleX,
    pageCircleY,
    pageCircleHeight,
    pageCircleWidth,
  }) {
    try {
      const { data } = await axios.post(
        "/user/house/updateFeatureItem",
        formData,
        {
          params: {
            houseId,
            featureKey,
            featureId,
            featureItemId,
            pageSequence,
            pageText,
            pagePhotoX,
            pagePhotoY,
            pagePhotoWidth,
            pagePhotoHeight,
            pageCircleX,
            pageCircleY,
            pageCircleHeight,
            pageCircleWidth,
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFeatureItem(houseId, featureKey, featureId, featureItemId) {
    try {
      const { data } = await axios.delete("/user/house/deleteFeatureItem", {
        params: {
          houseId,
          featureKey,
          featureId,
          featureItemId,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTOS(regionId) {
    try {
      const { data } = await axios.get(`/app/tos?regionId=${regionId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHouseRegisterInfo(id) {
    try {
      const { data } = await axios.get(`/getHouseRegisterInfo?id=${id}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validateBrochureLink(uniqueId) {
    try {
      const { data } = await axios.get("/app/brochure_link/validate", {
        params: {
          unique_id: uniqueId,
        },
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  async registerBrochureGuest({
    uniqueId,
    password,
    email,
    firstName,
    lastName,
  }) {
    try {
      const { data } = await axios.post("/app/brochure_link/register", {
        unique_id: uniqueId,
        firstName,
        lastName,
        password,
        email,
        accountType: "individual",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async generateBrochureLink(houseId) {
    try {
      const { data } = await axios.post(
        `/app/brochure_link/generate/${houseId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export const api = new APIWrapper();

export default APIWrapper;
