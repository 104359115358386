import React, {useContext, useEffect, useState} from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import DashboardEmpty from "./DashboardEmpty";
import DashboardNotFound from "./DashboardNotFound";
import DashBox from "./DashBox";
import { useLocation, useParams } from "react-router-dom";

import _sortBy from "lodash.sortby";

import { connect } from "react-redux";
import _get from "lodash.get";
import { dashboardOperations } from "./duck";
import generatePath from "../../utilities/generatePath";
import SwitchHousesCTA from "../common/SwitchHousesCTA";
import iconMarkup from "../../utilities/iconMarkup";
import { isAndroid } from "../../utilities/isMobileDevice";
import { StringParam, useQueryParam } from "use-query-params";
import { OutboundWarningContext } from "../common/OutboundWarningProvider";
import FeatureEmbed from "../featurepage/FeatureEmbed";

function DashboardSpecialItems({ items, homeId, houseCount, dashboardItems }) {
  const sortedCurrPageItems = _sortBy(items, (o) => o.sequence);

  const specialItems = sortedCurrPageItems.filter(
    (item) => item.key === "arrivalDetails" || item.key === "airportDetails"
  );

  const airportDetailsItem = specialItems.find(
    ({ key }) => key === "airportDetails"
  );
  const arrivalDetailsItem = specialItems.find(
    ({ key }) => key === "arrivalDetails"
  );

  return (
    <>
      {airportDetailsItem && (
        <DashBox
          key={airportDetailsItem.key}
          pageKey={airportDetailsItem.key}
          name={airportDetailsItem.name}
          icon={iconMarkup(
            airportDetailsItem.key,
            'svg',
			airportDetailsItem.image,
            null,
            null
          )}
          iconType={'svg'}
          data={airportDetailsItem}
          path={
            `/app/${homeId}/` +
            generatePath(
              airportDetailsItem.id,
              airportDetailsItem.key,
              airportDetailsItem.objectType,
              dashboardItems
            )
          }
          type={airportDetailsItem.type}
          //   handleTouchScale={this.handleTouchScale}
          role="main"
        />
      )}
      {houseCount > 1 && <SwitchHousesCTA homeId={homeId} />}
      {arrivalDetailsItem && (
        <DashBox
          key={arrivalDetailsItem.key}
          pageKey={arrivalDetailsItem.key}
          name={arrivalDetailsItem.name}
		  icon={iconMarkup(
			arrivalDetailsItem.key,
			'svg',
			arrivalDetailsItem.image,
			null,
			null
		  )}
          iconType={arrivalDetailsItem.icon}
          data={arrivalDetailsItem}
          path={
            `/app/${homeId}/` +
            generatePath(
              arrivalDetailsItem.id,
              arrivalDetailsItem.key,
              arrivalDetailsItem.objectType,
              dashboardItems
            )
          }
          type={arrivalDetailsItem.type}
          // handleTouchScale={handleTouchScale}
          role="main"
        />
      )}
    </>
  );
}

const MemoizedFeatureEmbed = React.memo(FeatureEmbed);

function DashboardComponent({
  dashboardItems,
  changeCurrentPage,
  initLoading,
  is404,
  currentPageItems,
  currentPageKey,
  currentPageName,
  currentHomeId,
  houses,
  currentPage
}) {
  const { setWarningProvider } = useContext(OutboundWarningContext);
  const location = useLocation();
  const [embedUrl, setEmbedUrl] = useState(null);

  const { primaryPage, secondaryPage, tertiaryPage, homeId } = useParams();

  const [, setPopupQuery] = useQueryParam("popup", StringParam);

  const [objectType] = useQueryParam("objectType", StringParam);

  useEffect(() => {
    if (dashboardItems) {
      const pathArray = location.pathname.split("/");
      const upperLevelPage = tertiaryPage || secondaryPage || primaryPage;
      const prevPath = pathArray.slice(0, -1).join("/");
      changeCurrentPage(
        homeId,
        upperLevelPage ? upperLevelPage : "",
        prevPath === "" ? "/" : prevPath
      ).catch((err) => {});
    }
  }, [
    changeCurrentPage,
    dashboardItems,
    homeId,
    location.pathname,
    primaryPage,
    secondaryPage,
    tertiaryPage,
  ]);

  useEffect(() => {
	// this is when we have an embedded item served on a Dashbox itemk like a pano2vr on Kitchen
	// ensure we don't have an objectType in the url which would be a feature page we link to in the pano2vr with a popup
	if (currentPageItems.length === 0 && currentPage && currentPage.embedUrl && ! objectType) {
	  setEmbedUrl(currentPage.embedUrl);
	}
  }, [currentPageItems]);

  if (initLoading) {
    return null;
  }

  if (is404) {
    return <DashboardNotFound />;
  }

  if (currentPageItems.length === 0) {
	if (embedUrl) {
		return <MemoizedFeatureEmbed url={embedUrl} />;
	}

    return <DashboardEmpty pageName={currentPageName} homeId={homeId} />;
  }

  const computeManualUrl = (itemKey) => {
    switch (itemKey) {
      case "sonos": {
        // if is android,
        // return link to google play store
        if (isAndroid()) {
          return "https://play.google.com/store/apps/details?id=com.sonos.acr2&hl=en_US";
        }

        // otherwise, redirect to ios app store
        // will open up in native app store on ios devices and web in others
        return "https://apps.apple.com/us/app/sonos/id1488977981";
      }
      default: {
        console.error("Manual dashbox link itemKey not recognized.");
        // if itemKey is not recognized, fallback to homeconcierge home page
        return "https://homeconcierge.com";
      }
    }
  };

  const handleTouchScale = () => {};

  const handleHiddenClick = () => {
    setPopupQuery("hidden");
  };

  const sortedCurrPageItems = _sortBy(currentPageItems, (o) => o.sequence);
  return (
    <div id="db-items" data-testid="dashboard-component">
      <TransitionGroup className="db-items-transgroup">
        <CSSTransition
          key={currentPageKey}
          timeout={{ enter: 350, exit: 350 }}
          classNames="fade"
          mountOnEnter
          unmountOnExit
          appear
        >
          <div className="db-items-wrapper">
            <div className="db-items-main" data-testid="db-items-main">
              {sortedCurrPageItems
                .filter(
                  (item) =>
                    item.key !== "arrivalDetails" &&
                    item.key !== "airportDetails"
                )
                .map(({ name, key, type, icon, image, url, id, objectType }) =>
                  key === "uber" || key === "lyft" ? (
                    <DashBox
                      key={`${key}-${id}-${objectType}`}
                      pageKey={key}
                      name={name}
                      type={type}
                      icon={iconMarkup(key, icon, image, name, objectType)}
                      iconType={icon}
                      handleTouchScale={handleTouchScale}
                      role="main"
                      onClick={() => {
                        setWarningProvider(true, key);
                      }}
                    />
                  ) : type === "link" ? (
                    <DashBox
                      key={`${key}-${id}-${objectType}`}
                      pageKey={key}
                      name={name}
                      type={type}
                      icon={iconMarkup(key, icon, image, name, objectType)}
                      iconType={icon}
                      handleTouchScale={handleTouchScale}
                      role="main"
                      url={url}
                    />
                  ) : type === "manual" ? (
                    <DashBox
                      key={`${key}-${id}-${objectType}`}
                      pageKey={key}
                      name={name}
                      type={type}
                      icon={iconMarkup(key, icon, image, name, objectType)}
                      iconType={icon}
                      handleTouchScale={handleTouchScale}
                      role="main"
                      url={computeManualUrl(key)}
                    />
                  ) : type === "hidden" ? (
                    <DashBox
                      key={`${key}-${id}-${objectType}`}
                      pageKey={key}
                      name={name}
                      type={type}
                      icon={iconMarkup(key, icon, image, name, objectType)}
                      iconType={icon}
                      handleTouchScale={handleTouchScale}
                      role="main"
                      onClick={handleHiddenClick}
                    />
                  ) : (
                    <React.Fragment key={`${key}-${id}-${objectType}`}>
                      <DashBox
                        key={`${key}-${id}-${objectType}`}
                        pageKey={key}
                        name={name}
                        icon={iconMarkup(key, icon, image, name, objectType)}
                        iconType={icon}
                        path={
                          `/app/${homeId}/` +
                          generatePath(id, key, objectType, dashboardItems)
                        }
                        type={type}
                        handleTouchScale={handleTouchScale}
                        role="main"
                      />
                    </React.Fragment>
                  )
                )}
            </div>
            <div className="db-items-special" data-testid="special-items">
              {currentPageName === "home" && (
                <DashboardSpecialItems
                  items={currentPageItems}
                  homeId={currentHomeId}
                  houseCount={houses ? houses.length : 0}
                  dashboardItems={dashboardItems}
                />
              )}
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

const mapState = (state) => ({
  currentPageItems: _get(state.dashboardReducer, "currentPage.pageItems", []),
  currentPageName: _get(state.dashboardReducer, "currentPage.pageName"),
  currentPageKey: _get(state.dashboardReducer, "currentPage.pageKey"),
  currentHomeId: _get(state.dashboardReducer, "currentHomeId"),
  currentPage: _get(state.dashboardReducer, "currentPage.page"),
  dashboardItems: state.dashboardReducer.dashboardItems,
  is404: _get(state.loadingReducer, "page.status", false) === "FAILED" && _get(state.loadingReducer, "feature.loading", false) !== true && _get(state.loadingReducer, "page.context", '') !== 'clearCurrentPage',
  initLoading: _get(state.loadingReducer, "init.loading", false),
  houses: _get(state.userReducer, "houses", []),
});

const mapDispatch = (dispatch) => ({
  changeCurrentPage: (homeId, pageKey, prevPath) =>
    dispatch(dashboardOperations.changeCurrentPage(homeId, pageKey, prevPath)),
});

export default connect(mapState, mapDispatch)(DashboardComponent);
