import React from "react";
import PropTypes from "prop-types";
import ResponsiveImage from "./ResponsiveImage";
import { Link } from "react-router-dom";
import clsx from "clsx";

const getUserRoleName = (permissionType) => {
  switch (permissionType) {
    case "owner": {
      return "Your Home";
    }
    case "host": {
      return "Added as Host";
    }
    case "guest": {
      return "Guest";
    }
    default: {
      return null;
    }
  }
};

function SwitchHousesTableItem({
  id,
  image,
  permission,
  houseName,
  fullAddress,
  link,
  onClick,
  disabled = false,
  ctaText = "Open",
}) {
  return (
    <div className="switch-houses-table__item" key={id}>
      <div className="switch-houses-table__photo">
        <ResponsiveImage imageArray={image} />
      </div>
      <div className="switch-houses-table__address">
        <small>{getUserRoleName(permission.type)}</small>
        <p>
          <strong>{houseName}</strong>
        </p>
        <p>{fullAddress}</p>
      </div>
      <div className="switch-houses-table__manage">
        <Link
          className={clsx("switch-houses-table__open", {
            disabled,
          })}
          to={link || "#"}
          onClick={onClick}
        >
          {ctaText}
        </Link>
      </div>
    </div>
  );
}

SwitchHousesTableItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  permission: PropTypes.shape({
    type: PropTypes.string,
    owner: PropTypes.string,
  }),
  houseName: PropTypes.string.isRequired,
  fullAddress: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SwitchHousesTableItem;
