import React from "react";
import PropTypes from "prop-types";

function BookNowBtn({text = 'Book Now'}) {

  return (
    <div className="book-now-btn">
      <span>{text}</span>
    </div>
  );
}

BookNowBtn.propTypes = {
  text: PropTypes.string
};

export default BookNowBtn;
