import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import PillButton from "./PillButton";
import MUIModal from "@material-ui/core/Modal";
import openMap from "../../utilities/openMap";
import mapsPNG from "../../assets/maps.png";
import swipeRightPNG from "../../assets/swipe_right.png";
import computeAddrString from "../../utilities/computeAddrString";
import ListItem from "./ListItem";
import UberIconPNG from "../../assets/uber-icon.png";
import LyftIconPNG from "../../assets/lyft-icon.png";
import { ReactComponent as AppleMapsSVG } from "../../assets/map_logo.svg";
import { ReactComponent as HCLogoSVG } from "../../assets/hc_logo.svg";
import { ReactComponent as LongArrowRightSVG } from "../../assets/long-arrow-right.svg";
import isMobileDevice, {
  isIOSIpad,
} from "../../utilities/isMobileDevice";
import GenericWarningModal from "./GenericWarningModal";
import MultiWarningModal from "./MultiWarningModal";

export const OutboundWarningContext = createContext();

const OutboundWarningModal = ({ onClose, address }) => {
  const { setWarningProvider } = useContext(OutboundWarningContext);

  // close outbound provider when map is opened
  const handleLinkClick = () => {
    setWarningProvider(false);
    return true;
  };

  return (
    <MUIModal
      open={true}
      onClose={onClose}
      className="outbound-warning-modal"
      disablePortal
      disableAutoFocus
      disableEnforceFocus
    >
      <div className="outbound-warning-modal__inner">
        <div className="outbound-warning-modal__top">
          <div className="outbound-image">
            <HCLogoSVG />
          </div>
          <div className="outbound-exiting">
            <LongArrowRightSVG />
          </div>
          <div className="outbound-image">
            <AppleMapsSVG />
          </div>
        </div>
        <div className="outbound-warning-modal__body">
          <ul className="outbound-warning-modal__bodylist">
            <ListItem index={1}>
              You are now leaving&nbsp;<strong>Home Concierge</strong>
            </ListItem>
            <li className="list-item list-item--map">
              <div className="list-item__map">
                <img className="list-item__map-image" src={mapsPNG} alt="Home Concierge app" />
              </div>
              <div className="list-item__swipe">
                <img className="list-item__swipe-image" src={swipeRightPNG} alt="Home Concierge app" />
              </div>
            </li>
            <ListItem index={2}>
              To return to<br/>
              Home Concierge<br/>
              <strong>SWIPE Right</strong> on the<br/>
              bart at the bottom of the screen.
            </ListItem>
          </ul>
        </div>

        <div className="outbound-warning-modal__buttons">
          <PillButton
            shape="rounded"
            color="blue"
            link={openMap(
              computeAddrString(address, {
                withName: address.name ? true : false,
              })
            )}
            outbound={true}
            onClick={handleLinkClick}
          >
            Open map app
          </PillButton>
        </div>
      </div>
    </MUIModal>
  );
};

OutboundWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  address: PropTypes.shape({
    name: PropTypes.string,
    fullAddress: PropTypes.string,
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

function OutboundWarningProvider({ children, once = false }) {
  const [showWarningProvider, setShowWarningProvider] = useState(false);
  const [warningModalType, setWarningModalType] = useState("address");
  const [address, setAddress] = useState(null);
  const [ready, setReady] = useState(
    once
      ? localStorage.getItem("outboundWarningPrompted")
        ? false
        : true
      : true
  );

  const setWarningProvider = (state, type) => {
    setWarningModalType(type || null);
    setShowWarningProvider(Boolean(state));
  };

  const clearReady = () => {
    localStorage.setItem("outboundWarningPrompted", "true");
    setReady(false);
  };

  const handleModalClose = () => {
    if (once && ready) {
      clearReady();
    }
    setWarningProvider(false);
  };

  const isMobile = isMobileDevice() || isIOSIpad();

  return (
    <OutboundWarningContext.Provider
      value={{
        showWarningProvider,
        setWarningProvider,
        setAddress,
        address,
        canOpenPrompt: ready,
      }}
    >
      {children}
      {showWarningProvider && (
        <>
          {warningModalType === "uber" && (
            <GenericWarningModal
              onClose={handleModalClose}
              icon={UberIconPNG}
              url={"uber://"}
              dest="Uber"
            />
          )}
          {warningModalType === "lyft" && (
            <GenericWarningModal
              onClose={handleModalClose}
              icon={LyftIconPNG}
              url="lyft://"
              dest="Lyft"
            />
          )}
        </>
      )}
    </OutboundWarningContext.Provider>
  );
}

OutboundWarningProvider.propTypes = {};

export default OutboundWarningProvider;
