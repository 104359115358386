import React from 'react';
import PropTypes from 'prop-types';
import SettingsButton from '../SettingsButton';
import generateRoutesByPageKey from '../generateRouteByPageKey';
import SettingsSection from '../SettingsSection';
function HelpContent({ generateRoutes }) {
  return (
    <SettingsSection>
      <SettingsButton page={generateRoutesByPageKey('faq')} settingKey="faq">
        FAQ
      </SettingsButton>
      <SettingsButton
        page={generateRoutesByPageKey('privacy-policy')}
        settingKey="privacy-policy"
      >
        Privacy Policy
      </SettingsButton>
      <SettingsButton
        page={generateRoutesByPageKey('terms-and-conditions')}
        settingKey="terms-and-conditions"
      >
        Terms & Conditions
      </SettingsButton>
    </SettingsSection>
  );
}
HelpContent.propTypes = {
  generateRoutes: PropTypes.func.isRequired
};

export default HelpContent;
