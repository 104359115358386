import settingRouteConfig from "./settingsRouteConfig";

export default function generateRouteByPageKey(settingKey, routeUrl = "") {
  const currSettingObject = settingRouteConfig.filter(({ key }) => {
    return key === settingKey ? true : false;
  })[0];

  //if the requested page is setting key, return an empty string to
  //default to /userSetting
  if (settingKey === "userSettings") return "";

  //if is direct child or root page,
  // append current routeUrl to settingKey

  if (currSettingObject.parent === "userSettings") {
    if (routeUrl === "") {
      routeUrl = settingKey;
    } else {
      routeUrl = settingKey + "/" + routeUrl;
    }

    return routeUrl;
  } else {
    //otherwise, run through the function again with <settingKey>/<routeUrl> as the
    // routeUrl and the parent as the setting key
    routeUrl = settingKey + "/" + routeUrl;

    return generateRouteByPageKey(currSettingObject.parent, routeUrl);
  }
}
