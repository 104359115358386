import React from "react";
import PropTypes from "prop-types";
import PopupPill from "./PopupPill";

import analytics from "../../../utilities/GAHelper";

function ContactPopup({
  phone,
  phoneText,
  email,
  featureName,
  propertyName,
  removePopup,
}) {
  const callOnClick = () => {
    analytics.sendGuestAction(
      "Call Host - Success",
      `Active Page: "${featureName}"`
    );
    window.location.href = `tel:${phone.replace("+", "")}`;
  };

  const textOnClick = () => {
	const phoneNumber = phoneText && phoneText.length > 0 ? phoneText : phone;
    analytics.sendGuestAction(
      "Text Host - Success",
      `Active Page: "${featureName}"`
    );
    window.location.href = `sms:${phoneNumber.replace("+", "")} `;
  };

  const emailOnClick = () => {
    analytics.sendGuestAction(
      "Email Host - Success",
      `Active Page: "${featureName}"`
    );
    window.location.href = `mailto:${email}?subject=Guest Message from: ${propertyName}&body=Page: ${featureName}%0D%0A`;
  };

  return (
    <div className="popup-content-inner" data-testid="contact-popup">
      <div className="popup-actions">
        {phone && (
          <>
            <PopupPill
              type="action"
              onClick={callOnClick}
              data-testid="contact-popup-call"
            >
              Call
            </PopupPill>
            <PopupPill
              type="action"
              onClick={textOnClick}
              data-testid="contact-popup-text"
            >
              Text
            </PopupPill>
          </>
        )}
        {email && (
          <PopupPill
            type="action"
            onClick={emailOnClick}
            data-testid="contact-popup-email"
          >
            Email
          </PopupPill>
        )}
      </div>
      <div className="popup-cancel">
        <PopupPill type="cancel" onClick={removePopup}>
          Cancel
        </PopupPill>
      </div>
    </div>
  );
}

ContactPopup.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  featureName: PropTypes.string,
  propertyName: PropTypes.string,
  removePopup: PropTypes.func.isRequired,
};

export default ContactPopup;
