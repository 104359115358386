import homeIcons from "./Home";
import AirportDetailsIcons from "./AirportDetails";
import TravelDetailsIcons from "./TravelDetails";

const iconObj = {
  ...homeIcons,
  ...AirportDetailsIcons,
  ...TravelDetailsIcons,
};

export default iconObj;
