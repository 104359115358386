import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isInStandaloneMode } from "../../utilities/isMobileDevice";
const ButtonWrapper = ({
  link,
  onClick,
  type,
  outbound,
  children,
  color,
  shape = "rounded",
  ...rest
}) => {
  if (link) {
    if (outbound) {
      return (
        <a
          {...rest}
          href={link}
          onClick={onClick}
          target={outbound || isInStandaloneMode() ? "_blank" : null}
          rel="noreferrer"
          data-testid="pill-button"
          className={clsx("pill-button__wrapper", {
            [`pill-button__wrapper--${color}`]: Boolean(color),
            [`pill-button__wrapper--${shape}`]: Boolean(shape),
          })}
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link
          to={link}
          data-testid="pill-button"
          className={clsx("pill-button__wrapper", {
            [`pill-button__wrapper--${color}`]: Boolean(color),
            [`pill-button__wrapper--${shape}`]: Boolean(shape),
          })}
        >
          {children}
        </Link>
      );
    }
  } else {
    return (
      <button
        {...rest}
        onClick={onClick}
        type={type}
        data-testid="pill-button"
        className={clsx("pill-button__wrapper", {
          [`pill-button__wrapper--${color}`]: Boolean(color),
          [`pill-button__wrapper--${shape}`]: Boolean(shape),
        })}
      >
        {children}
      </button>
    );
  }
};

const PillButton = ({
  type = "button",
  link,
  children,
  outbound,
  color = "orange",
  endIcon: EndIcon,
  startIcon: StartIcon,
  ...rest
}) => {
  return (
    <div className="pill-button">
      <ButtonWrapper
        {...rest}
        link={link}
        type={type}
        outbound={outbound}
        color={color}
      >
        {StartIcon && (
          <span className="pill-button__icon pill-button__icon--start">
            <StartIcon />
          </span>
        )}
        <span className="pill-button__text">{children}</span>
        {EndIcon && (
          <span className="pill-button__icon pill-button__icon--end">
            <EndIcon />
          </span>
        )}
      </ButtonWrapper>
    </div>
  );
};

PillButton.propTypes = {
  type: PropTypes.oneOf(["button", "submit"]),
  link: PropTypes.string,
  outbound: PropTypes.bool,
  color: PropTypes.oneOf([
    "orange",
    "red",
    "blue",
    "gray",
    "pastel-aqua",
    "pastel-blue",
  ]),
  endIcon: PropTypes.object,
  startIcon: PropTypes.object,
};

export default PillButton;
