import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { withRouter } from "react-router";
import parseQuery from "stringquery";
import _get from "lodash.get";

export class InteractionPopupWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.setClickableRef = this.setClickableRef.bind(this);
    this.removePopupParam = this.removePopupParam.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.removePopupParam = debounce(this.removePopupParam.bind(this), 500, {
      leading: true,
      trailing: false
    });
  }

  componentDidMount() {
    document.addEventListener("touchend", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("touchend", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  removePopupParam() {
    const { history, location } = this.props;
    const queryParams = parseQuery(location.search);
    const removePopupQuery = params => {
      if (params.feature) {
        history.push({
          pathname: location.pathname,
          search: `?feature=${params.feature}&objectType=${params.objectType}&type=${params.type}&id=${params.id}`
        });
      } else {
        history.push({
          pathname: location.pathname
        });
      }
    };

    removePopupQuery(queryParams);
  }

  handleClickOutside(event) {
    if (this.clickableRef && !this.clickableRef.contains(event.target)) {
      event.preventDefault();
      this.removePopupParam();
    }
  }

  setClickableRef(node) {
    this.clickableRef = node;
  }

  render() {
    const { children, popupName } = this.props;

    return (
      <div
        id="interaction-popup"
        className={`${popupName}`}
        data-testid="interaction-popup-wrapper"
      >
        {children({
          setClickableRef: this.setClickableRef,
          removePopup: this.removePopupParam
        })}
      </div>
    );
  }
}

InteractionPopupWrapper.propTypes = {
  children: PropTypes.any,

  pathname: PropTypes.string,
  popupName: PropTypes.string,
  hasPopup: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
  hasPopup: Boolean(_get(parseQuery(ownProps.location.search), "popup", false)),

  pathname: window.location.pathname
});
export default withRouter(connect(mapStateToProps)(InteractionPopupWrapper));
