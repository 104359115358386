import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function BannerHeader({ onClose, backPath, contentAlign, cta, children }) {
  return (
    <header
      className={
        "banner-header" + (contentAlign ? ` align-${contentAlign}` : "")
      }
    >
      <div
        className={`close-feature close-feature--${cta}`}
        data-testid="banner-header-close"
        onClick={onClose}
      >
        {backPath ? (
          <Link
            to={backPath}
            className={"close-feature-btn" + (cta ? ` icon-${cta}` : "")}
            data-testid="banner-header-link"
          />
        ) : (
          <button
            className={"close-feature-btn" + (cta ? ` icon-${cta}` : "")}
            data-testid="banner-header-btn"
          />
        )}
      </div>
      <div className="banner-header-content">{children}</div>
    </header>
  );
}

BannerHeader.propTypes = {
  onClose: PropTypes.func,
  backPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  contentAlign: PropTypes.string,
  cta: PropTypes.string,
};

export default BannerHeader;
