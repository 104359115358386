import React from "react";
import PropTypes from "prop-types";

function HiddenPopup({ removePopup }) {
  return (
    <div className="popup-content-inner" data-testid="contact-host-popup">
      <button
        className="close-feature-btn icon-close"
        data-testid="modal-close-btn"
        onClick={removePopup}
      ></button>
      <h3>
        <strong>This feature is hidden for privacy reasons.</strong>
      </h3>
      <p>
        You will be able to view this feature once you are checked in to the
        home.
      </p>
    </div>
  );
}

HiddenPopup.propTypes = {
  removePopup: PropTypes.func.isRequired
};

export default HiddenPopup;
