import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import analytics from "../../utilities/GAHelper";

import { connect } from "react-redux";
import _get from "lodash.get";
import { isInStandaloneMode } from "../../utilities/isMobileDevice";

class NavBarItem extends React.Component {
  handleOutboundClick(itemKey) {
    return (evt) => {
      const { featureName, pageName } = this.props;
      if (itemKey === "website") {
        analytics.sendFeatureAction("Outbound Website", featureName);
      } else if (itemKey.match("^MORE_")) {
        analytics.sendGuestAction("Outbound MORE", pageName);
      }
    };
  }

  handleCallClick = (evt) => {
    const { featureName } = this.props;
    analytics.sendFeatureAction("Call Feature", featureName);
  };
  render() {
    const { children, path, itemKey } = this.props;

    if (itemKey.match(/^(call)$/)) {
      return (
        <a
          data-testid={`navbar-item-link`}
          className="navbar-item-link tel"
          href={path}
          onClick={this.handleCallClick}
          target={isInStandaloneMode() ? "_blank" : "_self"}
          rel={"noreferrer"}
        >
          {children}
        </a>
      );
    }

    if (
      itemKey.match(/^(website|manual-website)$/) ||
      itemKey.match("^MORE_") ||
      /^https?:\/\//i.test(path)
    ) {
      return (
        <a
          data-testid={`navbar-item-link`}
          className="navbar-item-link outbound"
          rel="noopener noreferrer"
          href={path}
          target={isInStandaloneMode() ? "_blank" : "_self"}
          onClick={this.handleOutboundClick(itemKey)}
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        className="navbar-item-link"
        data-testid={`navbar-item-link`}
        to={path}
      >
        {children}
      </Link>
    );
  }
}

NavBarItem.propTypes = {
  children: PropTypes.any,
  itemKey: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const mapState = (state) => ({
  featureName: _get(state.dashboardReducer, "currentFeature.featureName", null),
  pageName: _get(state.dashboardReducer, "currentPage.pageName", null),
});

export default connect(mapState)(NavBarItem);
