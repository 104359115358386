import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

function LeftArrow() {
  return (
    <Link to="/manage/login">
      <span className="page-back"></span>
    </Link>
  );
}

function NoHomesCard() {
  return (
    <div className="no-homes-card">
      <div className="no-homes-card__inner">
        <div className="no-homes-card__back">
          <LeftArrow /> <small>Back to Login</small>
        </div>
        <h2>Looks like you aren't added to any homes!</h2>
        <p>Your host must invite you to your home before you can proceed.</p>
        <p>
          Please contact your host or point of contact to invite you to your
          home.
        </p>
        <small>
          Please contact{" "}
          <a href="mailto:support@homeconcierge.com">
            support@homeconcierge.com
          </a>{" "}
          for further assistance
        </small>
      </div>
    </div>
  );
}

function NoHomes({ hasHomes }) {
  if (hasHomes) {
    return <Redirect to="/app" />;
  }
  return (
    <>
      <Helmet>
        <title>Home Concierge | No Homes Found</title>
        <meta
          name="description"
          content="We were unable to find any homes for your account."
        />
      </Helmet>
      <div className="no-homes">
        <div className="no-homes__inner">
          <NoHomesCard />
        </div>
      </div>
    </>
  );
}

const mapState = state => ({
  hasHomes: state.userReducer.houses
    ? state.userReducer.houses.length > 0
    : false
});

export default connect(mapState)(NoHomes);
