import React from 'react';
import PropTypes from 'prop-types';

export default function SettingsSection({ children }) {
  return <div className="settings-section">{children}</div>;
}

SettingsSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
