import { createSelector } from "reselect";
import _get from "lodash.get";

const cacheLoadingSelector = state =>
  _get(state.loadingReducer, "cache.loading", true);
const loginLoadingSelector = state =>
  _get(state.loadingReducer, "login.loading", true);

const initialLoadingSelector = createSelector(
  [cacheLoadingSelector, loginLoadingSelector],
  (cacheLoading, loginLoading) => {
    return cacheLoading || loginLoading;
  }
);

export default initialLoadingSelector;
