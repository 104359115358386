import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import configureStore, { history } from "./store";
import { Router, Route } from "react-router";

import App from "./App";
import "./scss/main.scss";

import * as serviceWorker from "./serviceWorker";
import Tatl from "./app/common/Tatl";
import { QueryParamProvider } from "use-query-params";

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js");
  whyDidYouRender(React);
}

// Provice redux store
ReactDOM.render(
  <Provider store={configureStore()}>
    <Router history={history}>
      <Tatl>
        <QueryParamProvider ReactRouterRoute={Route}>
          <App />
        </QueryParamProvider>
      </Tatl>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register({
  onUpdate: async registration => {
	if (registration && registration.waiting) {
	  await registration.unregister();
	  registration.waiting.postMessage({ type: 'SKIP_WAITING' });
	  window.location.reload();
	}
  },
});
