import React, { useEffect, useState } from "react";
import PillButton from "../../common/PillButton";
import { connect } from "react-redux";
import { dashboardOperations } from "../../dashboard/duck";

import _get from "lodash.get";

function ContactContent({ getTOS, tos }) {
  const [loading, setLoading] = useState(tos ? false : true);

  useEffect(() => {
    if (tos) return;
    getTOS()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [getTOS, tos]);

  const contactEmail = loading
    ? "..."
    : _get(tos, "contact_email")
    ? tos.contact_email
    : "help@homeconcierge.com";

  return (
    <div
      className="settings-page-content settings-page-content--contact"
      data-testid="settings-page-content-contact"
    >
      <div className="settings-page-content__inner">
        <h3>Have anys questions or concerns?</h3>
        <p>
          Please contact{" "}
          <a href={`mailto:${contactEmail}`} data-testid="contact-email">
            {contactEmail}
          </a>
          .
        </p>
        <PillButton link={"mailto:help@homeconcierge.com"} outbound={true}>
          Email Us
        </PillButton>
      </div>
    </div>
  );
}

const mapState = (state) => ({
  tos: state.dashboardReducer.tos,
});

const mapDispatch = (dispatch) => ({
  getTOS: (tosKey) => dispatch(dashboardOperations.getTOS(tosKey)),
});

export default connect(mapState, mapDispatch)(ContactContent);
