export const getYoutubeId = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

export const getVimeoId = url => {
  const regExp = /^.*(vimeo.com\/)(\d+)/;
  const match = url.match(regExp);
  return match ? match[2] : null;
};

export const isValidEmbedUrl = url => {
  if (!url) return [false, null];
  const vimeo = getVimeoId(url);
  const youtube = getYoutubeId(url);

  const isValid = Boolean(vimeo || youtube);
  const platform = vimeo ? "vimeo" : youtube ? "youtube" : null;

  return [isValid, platform];
};
