import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import useOrientation from "../../utilities/useOrientation";
import OrientationWarning from "./OrientationWarning";
import isMobileDevice, { isIOSIpad } from "../../utilities/isMobileDevice";

export const OrientationContext = React.createContext();

function OrientationProvider({ children, portrait = true, landscape = true }) {
  const [showWarning, setShowWarning] = useState();
  const [disabled, setDisabled] = useState();
  const orientation = useOrientation();

  const disable = useCallback(() => {
    setDisabled(true);
  }, []);

  const enable = useCallback(() => {
    setDisabled(false);
  }, []);

  useEffect(() => {
    if (!isMobileDevice() || isIOSIpad()) {
      return;
    }

    // if disabled state is set, make sure showWarning is false
    // and return early
    if (!!disabled) {
      if (showWarning) {
        setShowWarning(false);
      }
      return;
    }

    // if portrait lock is set and orientation is portrait,
    // show warning
    if (!portrait && orientation === "portrait") {
      setShowWarning(true);
      return;
    }

    // if landscape lock is set and orientation is landscape,
    // show warning
    if (!landscape && orientation === "landscape") {
      setShowWarning(true);
      return;
    }

    // otherwise, remove warning
    setShowWarning(false);
  }, [disabled, landscape, orientation, portrait, showWarning]);

  return (
    <OrientationContext.Provider
      value={{
        orientation,
        disabled,
        disable,
        enable
      }}
    >
      <div className="orientation-provider">
        <div className="orientation-provider__content">{children}</div>
        {showWarning && (
          <div className="orientation-provider__warning">
            <OrientationWarning />
          </div>
        )}
      </div>
    </OrientationContext.Provider>
  );
}

OrientationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  portrait: PropTypes.bool,
  landscape: PropTypes.bool
};

export default OrientationProvider;
