import React from "react";

import PropTypes from "prop-types";
import detectOS from "../../../utilities/detectOS";
import PopupPill from "./PopupPill";
import PopupRow from "./PopupRow";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import analytics from "../../../utilities/GAHelper";
import _get from "lodash.get";
function InventoryPopup({
	featureName,
	propertyName,
	removePopup,
	propertyHostData,
	currentFeatureData,
	featureImage
  }) {
  const callOnClick = () => {
	const servicePhone = currentFeatureData?.inventory?.phone;
	analytics.sendGuestAction(
	  "Call Host Service",
	  `Active Page: "${featureName}"`
	);
	window.location.href = `tel:${servicePhone.replace("+", "")}`;
  };

  const textOnClick = () => {
	const servicePhone = currentFeatureData?.inventory?.sms;
	analytics.sendGuestAction(
	  "Text Host Service",
	  `Active Page: "${featureName}"`
	);
	window.location.href =`sms:${servicePhone.replace("+", "")} `;
  };

  const emailOnClick = () => {
	const serviceEmail = currentFeatureData?.inventory?.email;
	analytics.sendGuestAction(
	  "Email Host Service",
	  `Active Page: "${featureName}"`
	);
	window.location.href = `mailto:${serviceEmail}?subject=Guest Message from: ${propertyName}&body=Page: ${featureName}%0D%0A`;
  };

  return (
	<div className="popup-content-inner" data-testid="contact-host-popup">
	  <div className="popup-data">
		<div className="popup-data-inner">
		  <div className="feature-header-info">
			<div className="feature-header-info-icon">
			  <img src={featureImage} alt={currentFeatureData?.name} />
			</div>
			<div className="feature-header-info-name">
			  <h2>{currentFeatureData?.name}</h2>
			</div>
		  </div>
		<PopupRow label={'House'} value={propertyName} />
		{currentFeatureData?.inventory?.make && (
		  <PopupRow label={'Maker'} value={currentFeatureData.inventory.make} />
		)}
		{currentFeatureData?.inventory?.title && (
		  <PopupRow label={'Series'} value={currentFeatureData.inventory.title} />
		)}
		{currentFeatureData?.inventory?.model && (
		  <PopupRow label={'Model #'} value={currentFeatureData.inventory.model} />
		)}
		{currentFeatureData?.inventory?.serial && (
		  <PopupRow label={'Serial #'} value={currentFeatureData.inventory.serial} />
		)}
		{currentFeatureData?.inventory?.service && (
		  <PopupRow label={'Service'} value={currentFeatureData.inventory.service} />
		)}
		</div>
	  </div>
	  <div className="popup-actions">
		{currentFeatureData?.inventory?.phone && (
		  <PopupPill type="action" onClick={callOnClick}>
			Call
		  </PopupPill>
		)}
		{currentFeatureData?.inventory?.sms && (
		  <PopupPill type="action" onClick={textOnClick}>
			Text
		  </PopupPill>
		)}
		{currentFeatureData?.inventory?.email && (
		  <PopupPill type="action" onClick={emailOnClick}>
			Email
		  </PopupPill>
		)}
	  </div>
	  <div className="popup-cancel">
		<PopupPill type="cancel" onClick={removePopup}>
		  Cancel
		</PopupPill>
	  </div>
	</div>
  );
}

InventoryPopup.propTypes = {
  featureName: PropTypes.string,
  propertyName: PropTypes.string,
  removePopup: PropTypes.func,
  propertyHostData: PropTypes.object,
  currentFeatureData: PropTypes.object
};

const mapState = state => ({
  propertyHostData: state.dashboardReducer.propertyInfo.propertyHost,
  featureImage: _get(
	state.dashboardReducer,
	"currentFeature.featureImage",
	null
  ),
});
export default withRouter(connect(mapState)(InventoryPopup));
