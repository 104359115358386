import React from "react";

function ErrorRenderer({ errors, touched }) {
  if (!errors || !touched) return null;

  return (
    <div className="error-renderer">
      <div className="error-renderer_inner">
        <div className="error-renderer__errors">
          <ul>
            {Object.entries(errors).length > 0 &&
              errors.constructor === Object &&
              Object.entries(touched).length > 0 &&
              Object.keys(errors).map(
                errorKey =>
                  touched[errorKey] && (
                    <li data-testid="error-renderer-item" key={errorKey}>
                      {errors[errorKey]}
                    </li>
                  )
              )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ErrorRenderer;
