import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as DashHCNavSVG } from "../common/svg/dashboardnav/HC_Logo.svg";

import NavBarItemWrapper from "../common/NavBarItemWrapper";
import NavBarItem from "../common/NavBarItem";

import _get from "lodash.get";
import isRestrictedSelector from "../../selectors/isRestrictedSelector";
import isHomeAdminSelector from "../../selectors/isHomeAdmin";
import clsx from "clsx";
import BookNowBtn from "./BookNowBtn";

class DashboardFooterContainer extends React.Component {
  render() {
    const {
      isHome,
      loading,
      currentPageKey,
      currentParentKey,
      isRestricted,
      isHomeAdmin,
      moreLinks,
      bookNowLinks,
	  currentPage,
	  isBrochure
    } = this.props;

	const hideFooter = _get(currentPage, "page.guestApp.hideFooter", false);

    const homePageContent = () => (
      <>
        <span className="db-footer-content-item">
          <DashHCNavSVG />
        </span>

        <span className="db-footer-content-item"></span>

        <span className="db-footer-content-item">
          {Object.keys(bookNowLinks).length > 0 && (
            <NavBarItemWrapper itemKey={"book_now"}>
              {({ name, path, icon: Icon }) => (
                <NavBarItem path={path} itemKey="book_now">
                  <BookNowBtn text={isBrochure ? 'Book Now' : 'Book Again'} />
                </NavBarItem>
              )}
            </NavBarItemWrapper>
          )}

          <NavBarItemWrapper itemKey={"more"}>
            {({ name, path, icon: Icon }) => (
              <NavBarItem path={path} itemKey={"more"}>
                <span
                  className={
                    "svg-navitem-wrapper " + (loading ? "loading" : "loaded")
                  }
                >
                  {Icon && <Icon />}
                </span>

                <p className="svg-navitem-text">
                  <span>{name}</span>
                </p>
              </NavBarItem>
            )}
          </NavBarItemWrapper>
        </span>

        <div className="db-footer-navitems"></div>
      </>
    );
    const renderInsidePageItems = (pageKey, parentKey) => {
      if (Object.keys(moreLinks).includes(pageKey)) {
        return ["home", `MORE_${pageKey}`, "contact_host"];
      }

      return ["home", "contact_host"];
    };

    const insidePageItemsArray = renderInsidePageItems(
      currentPageKey,
      currentParentKey
    );
    const insidePageContent = () => (
      <>
        {insidePageItemsArray.map((itemKey) => (
          <span
            className={clsx(
              "db-footer-content-item",
              itemKey && `db-footer-content-item--${itemKey}`
            )}
            key={itemKey}
          >
            <NavBarItemWrapper key={itemKey} itemKey={itemKey}>
              {({ name, path, icon: Icon }) => (
                <NavBarItem path={path} itemKey={itemKey}>
                  <span
                    className={
                      "svg-navitem-wrapper " + (loading ? "loading" : "loaded")
                    }
                  >
                    {Icon && <Icon />}
                  </span>

                  <p className="svg-navitem-text">
                    <span>{name}</span>
                  </p>
                </NavBarItem>
              )}
            </NavBarItemWrapper>
          </span>
        ))}
      </>
    );

	if (hideFooter) {
	  return null;
	}

    return (
      <footer id="db-footer" data-testid="dashboard-footer">
        <div
          className={
            "db-footer-content " +
            (isHome && this.props.status !== "FAILED" ? "" : "inside-page")
          }
          data-testid={`db-footer-content-${isHome ? "home" : "inside"}`}
        >
          {isHome && this.props.status !== "FAILED"
            ? homePageContent()
            : insidePageContent()}
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  loading:
    _get(state.loadingReducer, "page.loading", false) ||
    _get(state.loadingReducer, "feature.loading", false),

  isRestricted: isRestrictedSelector(state),
  isHomeAdmin: isHomeAdminSelector(state),
  guestType: _get(state, "dashboardReducer.propertyInfo.guestType", null),
  isBrochure: _get(state, "dashboardReducer.propertyInfo.isPotential", false),
  moreLinks: _get(state, "dashboardReducer.moreLinks", {}),
  bookNowLinks: _get(state, "dashboardReducer.bookNowLinks", {}),
  currentPage: _get(state, "dashboardReducer.currentPage", {}),
});
DashboardFooterContainer.propTypes = {
  isHome: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  loading: PropTypes.bool,

  currentPageKey: PropTypes.string,
  currentParentKey: PropTypes.string,
  match: PropTypes.object,
  currentPage: PropTypes.object
};
export default withRouter(connect(mapStateToProps)(DashboardFooterContainer));
