import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import HostPlaceholder from '../common/svg/HostPlaceholder.png';
import PrivateChefPlaceholder from '../common/svg/PrivateChefPlaceholder.png';

function DashboxImageLoading() {
  return (
    <div className="dashbox-image-loading">
      <div className="spinner-loader" />
    </div>
  );
}

function DashboxImage({ image, name, objectType }) {
  const [loaded, setLoaded] = useState(false);
  const setImageLoaded = () => {
    setLoaded(true);
  };
  return (
    <div className="dashbox-image">
      <DashboxImageLoading />

      <CSSTransition
        key={'image'}
        in={loaded}
        timeout={{ enter: 150, exit: 150 }}
        classNames="fade"
      >
        <img
          src={
            image
              ? encodeURI(image)
              : objectType === 'privateChef'
              ? PrivateChefPlaceholder
              : objectType && objectType.match(/^(mainHost|backupHost|managementCo)$/)
              ? HostPlaceholder
              : HostPlaceholder
          }
          alt={name}
          onLoad={setImageLoaded}
        />
      </CSSTransition>
    </div>
  );
}

DashboxImage.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  objectType: PropTypes.string
};

export default DashboxImage;
