import React from "react";

import PillButton from "../common/PillButton";

function DashboardEmpty({ pageName, homeId }) {
  return (
    <div className="dashboard-empty" data-testid="dashboard-empty">
      <h2>No Page Items</h2>
      <p>
        This home does not yet have items added for <strong>{pageName}</strong>.
        Please check back later.
      </p>
      <PillButton link={homeId ? `/app/${homeId}` : `/`} color="blue">
        Back To Home
      </PillButton>
    </div>
  );
}

export default DashboardEmpty;
