import React from "react";
import PropTypes from "prop-types";
import PopupPill from "./PopupPill";

function BookNowPopup({ airBnb, luxe, vrbo, otherName, otherUrl }) {
  return (
    <div className="popup-content-inner" data-testid="contact-popup">
      <div className="popup-actions">
        {airBnb && (
          <PopupPill type="action" url={airBnb} data-testid="airbnb-pill">
            Airbnb
          </PopupPill>
        )}
        {luxe && (
          <PopupPill type="action" url={luxe} data-testid="luxe-pill">
            Luxe
          </PopupPill>
        )}
        {vrbo && (
          <PopupPill type="action" url={vrbo} data-testid="vrbo-pill">
            Vrbo
          </PopupPill>
        )}

        {otherName && otherUrl && (
          <PopupPill type="action" url={otherUrl} data-testid="other-pill">
            {otherName}
          </PopupPill>
        )}
      </div>
    </div>
  );
}

BookNowPopup.propTypes = {
  airBnb: PropTypes.string,
  luxe: PropTypes.string,
  vrbo: PropTypes.string,
  otherName: PropTypes.string,
  otherUrl: PropTypes.string,
};

export default BookNowPopup;
