import { useState, useEffect } from "react";
import useIsComponentMounted from "./useIsComponentMounted";


const useGeocode = (address, type = "address") => {
  const isComponentMounted = useIsComponentMounted();

  const [wasFetched, setWasFetched] = useState(null);
  const [geocoded, setGeocoded] = useState(null);
  const [geoLoading, setGeoLoading] = useState(true);

  const createQueryObject = (addrComponents, type) => {
	if (type === "address" && addrComponents) {
	  if (
		addrComponents.fullAddress ||
		addrComponents.city ||
		addrComponents.state
	  ) {
		return {
		  address: (`${addrComponents.fullAddress} ${
			addrComponents.city || ""
		  } ${addrComponents.state || ""}`).trim(),
		};
	  }

	  if (addrComponents.lat && addrComponents.lng) {
		return {
		  location: {
			lat: Number(addrComponents.lat),
			lng: Number(addrComponents.lng),
		  },
		};
	  }
	}

	if (type === "placeId" && address) {
	  return {
		placeId: address,
	  };
	}

	return null;
  };

  useEffect(() => {
	if (! address || wasFetched) {
	  return;
	}

	if (! isComponentMounted.current) {
	  return;
	}

	try {
	  const maps = window.google.maps;
	  const geocoder = new maps.Geocoder();
	  const geoQuery= createQueryObject(address, type);

	  if (! geoQuery) {
		return;
	  }

	  geocoder.geocode(geoQuery, (result, status) => {
		if (status === "OK") {
		  setGeocoded({...result[0], name: address.name});
		  setGeoLoading(false);
		} else {
		  setGeocoded(null);
		  setGeoLoading(false);
		}
		setWasFetched(true);
	  });
	} catch (e) {
	  setGeocoded(null);
	  setGeoLoading(false);
	  setWasFetched(true);
	}
  }, [address, type]);

  return [geoLoading, geocoded];
};

export default useGeocode;
