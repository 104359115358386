import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { isInStandaloneMode, isIOSSafari } from "../utilities/isMobileDevice";

import localforage from "localforage";
import moment from "moment";
import _get from "lodash.get";

const useInstallPrompt = clicksToTrigger => {
  const [clickCount, setClickCount] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const history = useHistory();
  const isValidPlatform = isIOSSafari() && !isInStandaloneMode();
  useEffect(
    () =>
      history.listen(() => {
        setClickCount(clickCount + 1);
      }),
    [clickCount, history]
  );

  useEffect(() => {
    if (clickCount >= clicksToTrigger && isValidPlatform) {
      localforage
        .getItem("installPrompted")
        .then(installPrompted => {
          if (_get(installPrompted, "expiry")) {
            // get expiry, convert from unix to moment object
            const expiry = moment.unix(installPrompted.expiry);

            // compare the current date and check if today is after the expiry
            const isExpired = moment().isAfter(expiry);

            // if expiry is past today, prompt to install
            if (isExpired) {
              setIsActive(true);
            }
          } else {
            // if there is no installPrompted set, prompt install
            setIsActive(true);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [clickCount, clicksToTrigger, isValidPlatform]);

  const handleClose = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const now = new Date();

    // day length of expiry
    const diff = 3;

    // unix date of expiration
    const expiration = moment(now).add(diff, "days").unix();

    // set unix date and a true value for install prompted
    localforage.setItem("installPrompted", {
      expiry: expiration,
      value: true
    });

    // hide prompt
    setIsActive(false);
  };
  if (isActive) {
  }
  return [isActive, handleClose];
};

export default useInstallPrompt;
