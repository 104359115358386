import React from "react";
import PropTypes from "prop-types";
import useVideoEmbed from "../../hooks/useVideoEmbed";

function FeatureVideo({ url }) {
  const EmbedCode = useVideoEmbed(url);

  return (
    <div className="feature-video-container">
      <div className="feature-video-container__video">
        {EmbedCode ? (
          EmbedCode
        ) : (
          <h3 className="feature-video-container__error">
            There was an error with displaying the homes video
          </h3>
        )}
      </div>
    </div>
  );
}

FeatureVideo.propTypes = {
  url: PropTypes.string.isRequired
};

export default FeatureVideo;
