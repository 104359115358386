import React from "react";
import PropTypes from "prop-types";

class FeatureLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: props.loading
    };
  }

  componentDidUpdate(prevProps) {
    // if loading changes, delay it by 300 ms to allow for unmounting of loader
    if (prevProps.loading !== this.props.loading) {
      if (this.props.loading === true) {
        this.setState({ showLoader: true });
      } else {
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 300);
      }
    }
  }

  render() {
    // the passed-in loading prop
    const { loading } = this.props;
    // the delayed loading prop
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && (
          <div id="feature-loader" className={!loading ? "hide" : "show"}>
            <div className="circle-spinner" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

FeatureLoader.propTypes = {
  loading: PropTypes.bool
};

export default FeatureLoader;
