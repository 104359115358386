import trimEnd from 'lodash/trimEnd';

export default (userUid) => {
  	if (! userUid) {
		return null;
	}

  	const baseUrl = process.env.REACT_APP_APP_URL ? trimEnd(process.env.REACT_APP_APP_URL, '/') : window.location.origin;
	const startPath = userUid ? `/guest/view/${userUid}` : '/';
  	const startUrl = `${baseUrl}${startPath}`;

  	window.history.pushState({}, "", startPath);

    const manifestData = {
      short_name: "Home Concierge",
      name: "Home Concierge",
	  lang: "en-US",
	  orientation: "portrait",
      icons: [
        {
          src: baseUrl + "/assets/HC_PWA_ICON_512x512_v2.png",
          type: "image/png",
          sizes: "512x512"
        }
      ],
	  start_url: startUrl,
      background_color: "#000000",
      display: "standalone",
      scope: baseUrl,
      theme_color: "#000000"
    };

	for (const el of document.querySelectorAll('head link[rel="manifest"]')) {
	  el.remove();
	}

  	const link = document.createElement("link");
	link.setAttribute('rel', 'manifest');
  	link.setAttribute('href', `data:application/manifest+json;base64,${btoa(JSON.stringify(manifestData))}`);
  	document.head.appendChild(link);

	return true;
}
