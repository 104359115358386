import React from "react";
import { getVimeoId, getYoutubeId } from "../utilities/isValidEmbedUrl";

const useVideoEmbed = url => {
  if (!url) return;
  const vimeoId = getVimeoId(url);
  const ytId = getYoutubeId(url);

  if (vimeoId) {
    return (
      <iframe
        src={`https://player.vimeo.com/video/${vimeoId}?playsinline=0`}
        width="640"
        height="360"
        data-testid="use-video-embed-vim"
        frameBorder="0"
        title="Vimeo Video"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen={true}
      ></iframe>
    );
  } else if (ytId) {
    return (
      <iframe
        width="560"
        height="315"
        data-testid="use-video-embed-yt"
        src={`https://www.youtube.com/embed/${ytId}`}
        frameBorder="0"
        title="Youtube Video"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      ></iframe>
    );
  } else {
    return null;
  }
};

export default useVideoEmbed;
