import { combineReducers } from "redux";
import dashboardReducer from "../app/dashboard/duck";
import { queryParamsReducer } from "../store/queryParamsReducer";
import settingsPageReducer from "../app/settings/duck";
import prePromptReducer from "../app/login/duck";
import { loadingReducer, userReducer } from "../duck";
import toastReducer from "../app/toast/duck";
import cameraReducer from "../app/camera/duck";

export default history =>
  combineReducers({
    camera: cameraReducer,
    queryParams: queryParamsReducer,
    dashboardReducer,

    loadingReducer,
    prePromptReducer,
    settingsPageReducer,
    userReducer,
    toastReducer
  });
