import Creators from "./actions";
import { api } from "../utilities/APIWrapper";
import _get from "lodash.get";

import analytics from "../utilities/GAHelper";

import moment from "moment";

const setLoading = Creators.setLoading;
const setUserData = Creators.setUserData;
const setFallbackToken = Creators.setFallbackToken;
const setUserHouses = Creators.setUserHouses;
const setLogout = Creators.setLogout;
const addSecondaryGuest = Creators.addSecondaryGuest;
const setUserInvitedGuests = Creators.setUserInvitedGuests;
const removeSecondaryGuest = Creators.removeSecondaryGuest;
const setCurrentPlatform = Creators.setCurrentPlatform;
const createSecondaryGuest = (guestId, email, firstName, lastName) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("inviteSecondaryGuest", "REQUEST"));
    api
      .inviteSecondaryGuest(guestId, email, firstName, lastName)
      .then((invitedGuest) => {
        dispatch(
          addSecondaryGuest(invitedGuest.id, email, firstName, lastName)
        );
        dispatch(setLoading("inviteSecondaryGuest", "SUCCESS"));
        resolve();
      })
      .catch((err) => {
        dispatch(setLoading("inviteSecondaryGuest", "FAILED"));
        if (_get(err, "response.status", null) !== null) {
          if (
            err.response.status === 401 &&
            _get(err, "response.data.error", null) === "token_invalid"
          ) {
            api.manualLogout().then(() => {
              dispatch(setLogout());

              reject(err);
              return;
            });
          } else if (err.response.status === 405) {
            reject({
              message: "You must be a primary guest to invite other guests.",
            });
          }
        } else {
          reject({
            message:
              "There was an unknown error while inviting a guest. Please try again",
          });
        }
      });
  });
};

const deleteSecondaryGuest = (guestId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const houseId = getState().dashboardReducer.currentHomeId;
    dispatch(setLoading("deleteSecondaryGuest", "REQUEST"));
    api
      .deleteSecondaryGuest(houseId, guestId)
      .then(() => {
        dispatch(removeSecondaryGuest(guestId));
        dispatch(setLoading("deleteSecondaryGuest", "SUCCESS"));
        resolve();
      })
      .catch((err) => {
        dispatch(setLoading("deleteSecondaryGuest", "FAILED"));
        if (
          _get(err, "response.status") === 401 &&
          _get(err, "response.data.error", null) === "token_invalid"
        ) {
          api.manualLogout().then(() => {
            dispatch(setLogout());
            reject({
              message: "You are not authorized to perform this action.",
            });
          });
        } else {
          window.console.error(err);
          reject();
        }
      });
  });
};

const fetchUserHouses = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .getUserHouses()
      .then((userHouses) => {
        const userHousesWithStatus = userHouses.map((house) => {
          const checkedOutDate = house.check_out_date_time
            ? moment(house.check_out_date_time)
            : null;

          const checkedInDate = house.check_in_date_time
            ? moment(house.check_in_date_time)
            : null;

          const now = moment();

          const checkedIn =
            checkedOutDate && checkedInDate
              ? moment(now).isBetween(checkedInDate, checkedOutDate)
              : false;

          return {
            ...house,
            isCheckedOut: !checkedIn,
          };
        });

        dispatch(setUserHouses(userHousesWithStatus));
        resolve(userHousesWithStatus);
      })
      .catch((err) => {
        window.console.error(err);

        if (
          _get(err, "response.status", null) === 401 &&
          _get(err, "response.data.error", null) === "token_invalid"
        ) {
          api.manualLogout().then(() => {
            dispatch(setLogout());
            reject(err);
          });
        } else {
          reject(err);
        }
      });
  });
};

const checkIfLoggedIn = (fallbackToken = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("cache", "REQUEST"));
    dispatch(setLoading("login", "REQUEST"));
    api
      .checkUserCredentials(fallbackToken)
      .then((userData) => {
        dispatch(fetchUserHouses())
          .then(() => {
            dispatch(setUserData(userData));
            dispatch(setFallbackToken(userData.token));
            dispatch(setLoading("cache", "SUCCESS"));
            dispatch(setLoading("login", "SUCCESS"));
            resolve(userData);
          })
          .catch((err) => {
            api.manualLogout().then(() => {
              dispatch(setLoading("cache", "FAILED"));
              dispatch(setLoading("login", "FAILED"));
              reject(err);
            });
          });
      })
      .catch((err) => {
        api.manualLogout().then(() => {
          dispatch(setLoading("cache", "FAILED"));
          dispatch(setLoading("login", "FAILED"));
          reject(err);
        });
      });
  });
};

const logoutUser = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("logout", "REQUEST"));
    api
      .logout()
      .then(({ userData }) => {
        analytics.sendAccountAction("User Logout", _get(userData, "id", null));

        dispatch(setLogout());

        dispatch(setLoading("logout", "SUCCESS"));
        resolve();
      })
      .catch((err) => {
        dispatch(setLogout());
        dispatch(setLoading("logout", "FAILED"));
        reject();
      });
  });
};

export default {
  setLoading,
  setUserHouses,
  addSecondaryGuest,
  setUserData,
  setFallbackToken,
  checkIfLoggedIn,
  fetchUserHouses,
  logoutUser,
  setLogout,
  createSecondaryGuest,
  setUserInvitedGuests,
  deleteSecondaryGuest,
  removeSecondaryGuest,
  setCurrentPlatform,
};
