import React from "react";
import PropTypes from "prop-types";

function PopupRow({ label, value, ...rest }) {
  return (
	<div className={"pill-item pill-row"} {...rest}>
	  <label>{label}</label>
	  <span>{value}</span>
	</div>
  );
}

PopupRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default PopupRow;
