import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ReactComponent as AndroidSettingsSVG } from "../../assets/AndroidSettings.svg";
import { ReactComponent as ShareSVG } from "../../assets/ShareSVG.svg";
import { ReactComponent as AndroidA2HSArrowSVG } from "../../assets/AndroidA2HSArrow.svg";
import { InstallPromptContext } from "./InstallPromptProvider";
import OutsideClickHandler from "react-outside-click-handler";

import HCHandImage from "../../assets/HC_hand.png";
import clsx from "clsx";
import ListItem from "./ListItem";

function InstallPrompt({ device = null }) {
  const { handleClose } = useContext(InstallPromptContext);

  if (!device) return null;

  return (
    <div
      className={clsx("install-prompt", {
        [`install-prompt--${device}`]: Boolean(device),
      })}
      data-testid="install-prompt-wrapper"
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          handleClose();
        }}
        className="install-prompt__inner"
      >
        {device === "android" && (
          <>
            <div className="install-prompt-modal">
              <div className="install-prompt-modal__inner">
                <div
                  className="install-prompt-modal__header"
                  style={{ backgroundImage: `url(${HCHandImage})` }}
                >
                  <h2>
                    Enjoying<br></br> Home Concierge?
                  </h2>
                </div>

                <div className="install-prompt-modal__steps">
                  <h3>
                    For the best performance, please add to your homescreen.
                  </h3>
                  <ul className="install-prompt-modal__stepslist">
                    <ListItem index={1}>
                      <p>
                        Tap on the{" "}
                        <span className="svg-wrapper">
                          <AndroidSettingsSVG />{" "}
                        </span>
                        button
                      </p>
                      <p>(at the top of this screen)</p>
                    </ListItem>
                    <ListItem index={2}>
                      <p>
                        Scroll to <strong>Add to Home Screen</strong> then tap
                      </p>
                    </ListItem>
                  </ul>
                </div>
              </div>
              <span className="install-prompt-modal__arrow">
                <AndroidA2HSArrowSVG />
              </span>
            </div>
          </>
        )}
        {device === "ios" && (
          <div className="install-prompt-modal">
            <div className="install-prompt-modal__inner">
              <div
                className="install-prompt-modal__header"
                style={{ backgroundImage: `url(${HCHandImage})` }}
              >
                <h2>
                  Enjoying<br></br> Home Concierge?
                </h2>
              </div>

              <div className="install-prompt-modal__steps">
                <h3>
                  For the best performance, please add to your homescreen.
                </h3>
                <ul className="install-prompt-modal__stepslist">
                  <ListItem index={1}>
                    <p>
                      Tap on the{" "}
                      <span className="svg-wrapper">
                        <ShareSVG />
                      </span>
                      button
                    </p>
                    <p>(at the bottom of this screen)</p>
                  </ListItem>
                  <ListItem index={2}>
                    <p>
                      Scroll to <strong>Add to Home Screen</strong> then tap
                    </p>
                  </ListItem>
                  <ListItem index={3}>
                    <p>Lastly, in the upper right corner</p>
                    <p>
                      tap on{" "}
                      <span className="text-blue">
                        <strong>Add</strong>
                      </span>
                    </p>
                  </ListItem>
                </ul>
              </div>
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
}

InstallPrompt.propTypes = {
  device: PropTypes.string,
  clearPrompt: PropTypes.func,
  transState: PropTypes.string,
};

export default InstallPrompt;
