import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import PopupPill from "./PopupPill";

import _get from "lodash.get";
import analytics from "../../../utilities/GAHelper";

function ReservationsPopup({
  removePopup,
  featureReservation,
  featurePhone,
  featureName
}) {
  const callOnClick = () => {
    analytics.sendFeatureAction("Reservation - Call", featureName);

    window.location.href = `tel:${featurePhone}`;
  };
  const handleReservationClick = () => {
    analytics.sendFeatureAction("Reservation - Book Online", featureName);
  };
  return (
    <div className="popup-content-inner" data-testid="reservations-popup">
      <div className="popup-actions">
        {featureReservation && (
          <PopupPill
            type="action"
            url={featureReservation}
            onClick={handleReservationClick}
          >
            Book Online
          </PopupPill>
        )}
        {featurePhone && (
          <PopupPill type="action" onClick={callOnClick}>
            Call
          </PopupPill>
        )}
      </div>
      <div className="popup-cancel">
        <PopupPill type="cancel" onClick={removePopup}>
          Cancel
        </PopupPill>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  featureReservation: _get(
    state.dashboardReducer,
    "currentFeature.featureData.reservation",
    null
  ),
  featurePhone: _get(
    state.dashboardReducer,
    "currentFeature.featureData.phone",
    null
  ),
  featureName: _get(state.dashboardReducer, "currentFeature.featureName", null)
});

ReservationsPopup.propTypes = {
  featureReservation: PropTypes.string.isRequired,
  featurePhone: PropTypes.string,
  removePopup: PropTypes.func
};

export default connect(mapStateToProps)(ReservationsPopup);
