const SET_PROMPT_STATE = "SET_PROMPT_STATE";
const SET_LOADING = "SET_LOADING";
const SET_USER_DATA = "SET_USER_DATA";
const SET_FALLBACK_TOKEN = "SET_FALLBACK_TOKEN";
const SET_USER_HOUSES = "SET_USER_HOUSES";
const LOGOUT_USER = "LOGOUT_USER";
const ADD_SECONDARY_GUEST = "ADD_SECONDARY_GUEST";
const SET_USER_INVITED_GUESTS = "SET_USER_INVITED_GUESTS";
const REMOVE_SECONDARY_GUEST = "REMOVE_SECONDARY_GUEST";
const SET_CURRENT_PLATFORM = "SET_CURRENT_PLATFORM";

export default {
  SET_PROMPT_STATE,
  SET_LOADING,
  SET_USER_DATA,
  SET_FALLBACK_TOKEN,
  SET_USER_HOUSES,
  LOGOUT_USER,
  ADD_SECONDARY_GUEST,
  SET_USER_INVITED_GUESTS,
  REMOVE_SECONDARY_GUEST,
  SET_CURRENT_PLATFORM,
};
