import React from "react";
import _get from "lodash.get";
import DashboxImage from "../app/dashboard/DashboxImage";
import dashboardIcons from "../app/common/svg/dashboard";

export const iconMarkup = (key, icon, image, name, objectType) => {
  if (icon === "svg") {
    const Icon = _get(dashboardIcons[key], "inline", null);
    if (!Icon) {
      return null;
    }
    return (
      <span>
        <Icon />
      </span>
    );
  }
  if (icon === "image") {
    return <DashboxImage image={image} name={name} objectType={objectType} />;
  }
};

export default iconMarkup;
