import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {Redirect, useParams} from "react-router-dom";
import { prePromptOperation } from "./duck";
import parseQuery from "stringquery";
import _get from "lodash.get";
import FeatureLoader from "../featurepage/FeatureLoader";

function GuestViewPage({
	getPendingGuestInfo,
	loginUser
}) {

  let routerParams = useParams();
  const [initializeLoading, setInitializeLoading] = useState(true);
  const [guestError, setGuestError] = useState({});
  const [houseId, setHouseId] = useState(null);

  const userUid = routerParams.uniqueId;

  const handlePendingGuestInfo = ({
	email,
	houseId
  }) => {
	setHouseId(houseId);
	return loginUser(email, userUid).then(() => {
	  setInitializeLoading(false);
	});
  };

  useEffect(() => {
	getPendingGuestInfo(userUid)
	  .then(handlePendingGuestInfo)
	  .catch((error) => {
		setGuestError({
		  status: 401,
		  text: "This guest link is not valid.",
		});
		setInitializeLoading(false);
	  });
  }, [getPendingGuestInfo, userUid]);

  	if (initializeLoading) {
	  return <FeatureLoader loading={true} />;
	}

  return <Redirect to={`/app/${houseId}`} />;
}

const mapDispatch = (dispatch) => ({
  getPendingGuestInfo: (uniqueId) =>
	dispatch(prePromptOperation.getPendingGuestInfo(uniqueId, true)),
  loginUser: (email, password) => dispatch(prePromptOperation.handleLoginUser(email, password)),
});

const mapState = (state, { location }) => ({
  queryParams: parseQuery(location.search),
  isLoggedIn: _get(state.userReducer, "user.isLoggedIn", false),
});

export default connect(mapState, mapDispatch)(GuestViewPage);
