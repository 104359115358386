import { useEffect, useState } from "react";

const useDelayedDataKey = (dataKey, delay) => {
  const [hideText, setHideText] = useState(false);
  const [delayedDataKey, setDelayedDataKey] = useState(dataKey);

  useEffect(() => {
    if (dataKey === delayedDataKey) return;

    setHideText(true);
    const dataKeyTimeout = setTimeout(() => {
      setHideText(false);
      setDelayedDataKey(dataKey);
    }, delay);

    return () => {
      clearInterval(dataKeyTimeout);
    };
  }, [delay, dataKey, delayedDataKey]);

  return [hideText, delayedDataKey];
};

export default useDelayedDataKey;
