/* global google */

import Creators from "./actions";

import { globalOperations } from "../../../duck";
import { api } from "../../../utilities/APIWrapper";
import _get from "lodash.get";

const setLogout = globalOperations.setLogout;
const setLoading = globalOperations.setLoading;
const setUserInvitedGuests = globalOperations.setUserInvitedGuests;

const setCurrentPage = Creators.setCurrentPage;
const setCurrentHomeInfo = Creators.setCurrentHomeInfo;
const setCurrentFeature = Creators.setCurrentFeature;
const changeFeatureDataKey = Creators.changeFeatureDataKey;
const setCurrentFeatureDistance = Creators.setCurrentFeatureDistance;
const setCurrentPropertyPages = Creators.setCurrentPropertyPages;
const setGAPILoaded = Creators.setGAPILoaded;
const setTOS = Creators.setTOS;
const setCurrentMoreLinks = Creators.setCurrentMoreLinks;
const setCurrentBookNowLinks = Creators.setCurrentBookNowLinks;
const updateCurrentFeatureAddress = Creators.updateCurrentFeatureAddress;
const updateCurrentDestinationData = Creators.updateCurrentDestinationData;

const clearCurrentPage = Creators.clearCurrentPage;

const generateCurrPageObj = (dashboardItems, pageKey) => {
  const pageItem = dashboardItems.find((page) => page.key === pageKey);

  if (!pageItem && pageKey) {
    return null;
  }

  const currPageItems = dashboardItems.filter((page) => {
    if (page.parent === (pageKey ? pageKey : "home")) {
      return true;
    }
    return false;
  });

  let currPageName, parentKey, page;

  if (pageKey) {
    const currPageNameArray = dashboardItems.find((item) => {
      return item.key === pageKey;
    }, null);

    if (currPageNameArray) {
      currPageName = currPageNameArray.name;
      parentKey = currPageNameArray.parent;
	  page = currPageNameArray;
    }
  } else {
    currPageName = "home";
    parentKey = null;
  }

  return {
    pageKey,
    parentKey,
    currPageName,
    currPageItems,
	page
  };
};

const setOpenApp = (homeId, guestId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .setOpenApp(homeId, guestId)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const refreshInitData = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { currentHomeId, previousPath } = getState().dashboardReducer;
    const { pageKey } = getState().dashboardReducer.currentPage;
    dispatch(setLoading("refreshInit", "REQUEST"));
    api
      .getPropertyInfo(currentHomeId)
      .then((data) => {
        const {
          houseName: name,
          host,
          address,
          guestId,
        } = data.payload.propertyInfo;
        const { dashboard, guestType, region, isProspective } = data.payload;

        // update home info
        if (name)
          dispatch(
            setCurrentHomeInfo(
              name,
              host,
              address,
              guestId,
              guestType,
              isProspective,
              region
            )
          );

        // get dashboard items with ride sharing icons
        const composedDashboardItems = dashboard.items;

        //if dashboard items are present, set current property pages
        if (dashboard.items)
          dispatch(setCurrentPropertyPages(composedDashboardItems));

        if (dashboard.more_links)
          dispatch(setCurrentMoreLinks(dashboard.more_links));

        if (dashboard.book_now_links) {
          dispatch(setCurrentBookNowLinks(dashboard.book_now_links));
        }

        const currentPageObj = generateCurrPageObj(
          composedDashboardItems,
          pageKey
        );
        //dispatch action to set current page info - set timeout to allow for page transition

        if (!currentPageObj) {
          dispatch(setLoading("refreshInit", "FAILED"));
          reject({ message: "Couldnt find page" });
          return;
        }

        dispatch(
          setCurrentPage(
            currentHomeId,
            currentPageObj.pageKey,
            currentPageObj.parentKey,
            currentPageObj.currPageName,
            currentPageObj.currPageItems,
            previousPath,
			currentPageObj.page
          )
        );
        dispatch(setLoading("refreshInit", "SUCCESS"));
        //set load success
        resolve();
      })
      .catch((err) => {
        dispatch(setLoading("refreshInit", "FAILED"));
        reject({ message: "Unable to fetch property info.", err });
      });
  });
};

const fetchInitData = (homeId) => (dispatch) => {
  //Handles succesful fetch to init endpoint

  return new Promise((resolve, reject) => {
    dispatch(setLoading("init", "REQUEST"));
    dispatch(setLoading("page", "REQUEST"));
    const handleInitPayload = (data) => {
      return new Promise((resolve, reject) => {
        // Property name from init endpoint

        // get name, host, dashboard object from payload.propertyInfo

        const {
          houseName: name,
          host,
          address,
          guestId,
          has_opened_app,
        } = data.payload.propertyInfo;
        const {
          dashboard,
          guestType,
          invitedGuests,
          isProspective,
          region,
        } = data.payload;

        //if property name and host obj are present, set current home info

        if (name)
          dispatch(
            setCurrentHomeInfo(
              name,
              host,
              address,
              guestId,
              guestType,
              isProspective,
              region
            )
          );

        //if dashboard items are present, set current property pages
        if (dashboard.items) dispatch(setCurrentPropertyPages(dashboard.items));

        if (dashboard.more_links)
          dispatch(setCurrentMoreLinks(dashboard.more_links));

        if (dashboard.book_now_links) {
          dispatch(setCurrentBookNowLinks(dashboard.book_now_links));
        }

        if (invitedGuests) dispatch(setUserInvitedGuests(invitedGuests));

        if (has_opened_app === 0 || has_opened_app === false) {
          dispatch(setOpenApp(homeId, guestId));
        }

        //set load success
        resolve();
      });
    };

    //handles network error for fetch

    // Fetch from init endpoint, handle data response
    api
      .getPropertyInfo(homeId)
      .then((initPayload) => {
        dispatch(setLoading("init", "SUCCESS"));
        handleInitPayload(initPayload)
          .then((data) => {
            dispatch(setLoading("page", "SUCCESS"));
            resolve(data);
          })
          .catch((err) => {
            if (err === "Couldnt find page") {
              dispatch(clearCurrentPage(homeId));
              dispatch(setLoading("page", "FAILED"));
            } else {
              window.console.error(err);
              dispatch(clearCurrentPage(homeId));
              dispatch(setLoading("page", "FAILED"));
              reject(err);
            }
          });
      })
      .catch((err) => {
        dispatch(setLoading("init", "FAILED"));
        dispatch(setLoading("page", "FAILED"));

        if (
          _get(err, "response.status") === 401 &&
          _get(err, "response.data.error") === "token_invalid"
        ) {
          api
            .manualLogout()
            .then(() => {
              dispatch(setLogout());
              reject(err);
            })
            .catch((err) => reject(err));
        } else {
          reject(err);
        }
      });
  });
};

const changeCurrentPage = (homeId, pageKey, previousPath) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("page", "REQUEST"));

    const { dashboardItems } = getState().dashboardReducer;
    if (!dashboardItems) {
      dispatch(setLoading("page", "FAILED"));
      reject("No dashboard items");
    }

    const currentPageObj = generateCurrPageObj(dashboardItems, pageKey);

    if (!currentPageObj) {
      dispatch(clearCurrentPage(homeId));
      dispatch(setLoading("page", "FAILED", 'clearCurrentPage'));
      reject();
    }

    dispatch(
      setCurrentPage(
        homeId,
        currentPageObj.pageKey,
        currentPageObj.parentKey,
        currentPageObj.currPageName,
        currentPageObj.currPageItems,
        previousPath,
		currentPageObj.page
      )
    );
    dispatch(setLoading("page", "SUCCESS"));
    resolve();
  });
};

const fetchCurrentFeatureDistance = (featureLatLng, propertyLatLng) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    // if (!window.google) return null;
    dispatch(setCurrentFeatureDistance(null, null));
    dispatch(setLoading("featureDistance", "REQUEST"));
    const service = new google.maps.DistanceMatrixService();
    const currentDate = new Date();

    const originLatLng = featureLatLng;
    const destinationLatLng = propertyLatLng;

    service.getDistanceMatrix(
      {
        origins: [originLatLng],
        destinations: [destinationLatLng],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        drivingOptions: {
          departureTime: currentDate,
        },
      },
      (response, status) => {
        if (status === "OK") {
          const {
            duration: responseDuration,
            distance: responseDistance,
          } = response.rows[0].elements[0];
          if (!responseDuration || !responseDistance) {
            dispatch(setLoading("featureDistance", "FAILED"));
            reject();
          } else {
            const duration = responseDuration.text;
            const distance = responseDistance.text;
            dispatch(setCurrentFeatureDistance(duration, distance));
            dispatch(setLoading("featureDistance", "SUCCESS"));
            resolve();
          }
        } else {
          dispatch(setLoading("featureDistance", "FAILED"));
          reject();
        }
      }
    );
  });
};

const fetchCurrentFeature = (homeId, featureObjectType, featureId) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("feature", "REQUEST"));

    if (featureId && featureObjectType) {
      // const featureCache = getState().dashboardReducer.featureCache;

      // if (featureCache) {
      //   const cache = featureCache[`${featureObjectType}-${featureId}`];
      //   if (cache) {
      //     dispatch(setLoading("feature", "REQUEST"));

      //     dispatch(
      //       setCurrentFeature(
      //         cache.featureId,
      //         cache.featureKey,
      //         cache.featureName,
      //         cache.featureData,
      //         cache.featureType,
      //         cache.featureObjectType
      //       )
      //     );
      //     dispatch(setLoading("feature", "SUCCESS"));
      //     return;
      //   }
      // }

      const handleFeaturePayload = (data) => {
        if (data.payload) {
          const { name, key, type, image } = data.payload;
          const featureData = data.payload.data;

          dispatch(
            setCurrentFeature(
              data.id,
              key,
              name,
              featureData,
              type,
              featureObjectType,
              image
            )
          );
          dispatch(setLoading("feature", "SUCCESS"));
          resolve();
        } else {
          dispatch(setLoading("feature", "FAILED"));
          reject("no payload given.");
        }
      };

      api
        .getPropertyFeature(homeId, featureId, featureObjectType)
        .then((json) => {
          handleFeaturePayload(json);
        })
        .catch((err) => {
          dispatch(setLoading("feature", "FAILED"));
          reject(err);
        });
    } else {
      dispatch(setLoading("feature", "FAILED"));
      Promise.reject("Not given feature id or feature object type.");
    }
  });
};

const storeCachedDestinationData = ({
  placeId,
  phone,
  name,
  website,
  openingHours,
  fullAddress,
}) => (dispatch) => {
  dispatch(
    updateCurrentDestinationData(placeId, {
      phone,
      name,
      website,
      openingHours,
      fullAddress,
    })
  );
};

const getTOS = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .getTOS(1)
      .then(({ privacy_policy, terms_of_service, contact_email }) => {
        dispatch(setTOS({ privacy_policy, terms_of_service, contact_email }));
        resolve({ privacy_policy, terms_of_service, contact_email });
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export default {
  setCurrentPage,
  setCurrentHomeInfo,
  setCurrentFeature,
  setCurrentFeatureDistance,
  setCurrentPropertyPages,
  fetchInitData,
  fetchCurrentFeature,
  changeFeatureDataKey,
  updateCurrentFeatureAddress,
  changeCurrentPage,
  getTOS,
  updateCurrentDestinationData,
  fetchCurrentFeatureDistance,
  setGAPILoaded,
  storeCachedDestinationData,
  refreshInitData,
};
