// Helper function to detect if
export default function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export function isIOSIpad() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /ipad/.test(userAgent);
}

export function isIOSSafari() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const iOS = isIOS();
  const webkit = !!userAgent.match(/WebKit/i);

  return iOS && webkit && !userAgent.match(/CriOS/i);
}

export function isAndroid() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("android") > -1;
}

export const isInStandaloneMode = () =>
  "standalone" in window.navigator && window.navigator.standalone;
