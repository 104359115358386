import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GuestCreatePageForm from "./GuestCreatePageForm";
import GuestHousePreview from "./GuestHousePreview";
import { useQueryParam, StringParam } from "use-query-params";
import { prePromptOperation } from "./duck";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as HCLogo } from "../common/svg/HC_Logo.svg";
import { toastOperations } from "../toast/duck";

const GuestHouseRegisterError = () => {
  return (
    <div className="guest-house-register-error">
      <div className="guest-house-register-error__inner">
        <h2>Looks like this house registration link is not valid.</h2>
        <p className="gray-p">
          Please contact your host or house manager for a valid link.
        </p>
        <p>
          Have an account?<Link to="/manage/login">Sign in here.</Link>
        </p>
      </div>
    </div>
  );
};

const GuestHouseRegisterForm = ({ onSubmit, houseName }) => {
  return (
    <>
      <div className="guest-create-page">
        <div className="guest-create-page__inner">
          <div className="guest-create-page__header">
            <div className="guest-create-page__brand">
              <HCLogo />
            </div>
            <h4>
              Sign up to view <strong>{houseName}</strong>
            </h4>
            <p>
              You will be given access to the brochure version for{" "}
              <strong>{houseName}</strong>.
            </p>
          </div>
          <div className="guest-create-page__form">
            <GuestCreatePageForm onSubmit={onSubmit} withExtraFields={true} />
          </div>

          <div className="guest-create-page__signin">
            <p>
              Have an account? <Link to="/manage/login">Sign in here.</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function GuestHouseRegisterPage({
  getHouseRegisterInfo,
  registerHouseGuest,
  addToast,
}) {
  const [initLoading, setInitLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);
  const [houseData, setHouseData] = useState();
  const [uniqueHouseId] = useQueryParam("id", StringParam);

  const history = useHistory();

  const handleRegisterHouseGuest = (values, actions) => {
    registerHouseGuest(houseData.id, values)
      .then((user) => {
        actions.setSubmitting(false);

        history.push({
          state: { fadeTransition: false },
          pathname: `/app/${houseData.id}`,
        });
      })
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
        if (error.response.status === 409) {
          addToast({
            intent: "error",
            text:
              "You are already added as a guest to this house. Please login to view the home.",
          });
        } else {
          addToast({ intent: "error", text: error.message });
        }
      });
  };

  const renderStep = (step) => {
    if (!houseData) return null;

    if (step === 1) {
      return (
        <GuestHousePreview
          title={houseData.houseName}
          description={() => (
            <>
              Everything about YOUR vacation home <br></br>all in one place.
            </>
          )}
          isHawaii={houseData.regionName.toLowerCase() === "kona"}
          housePhoto={houseData.housePhoto}
          onSubmit={() => setCurrentStep(currentStep + 1)}
        />
      );
    }

    if (step === 2) {
      return (
        <GuestHouseRegisterForm
          houseName={houseData.houseName}
          onSubmit={handleRegisterHouseGuest}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    getHouseRegisterInfo(uniqueHouseId)
      .then(({ housePhoto, houseName, id, regionName }) => {
        setHouseData({ housePhoto, houseName, id, regionName });
        setInitLoading(false);
      })
      .catch((err) => {
        setInitLoading(false);
        setError(err);
      });
  }, [getHouseRegisterInfo, uniqueHouseId]);

  if (error) return <GuestHouseRegisterError />;
  if (initLoading) return <p>Loading...</p>;
  return (
    <div className="guest-house-register-page">
      <div className="guest-house-register-page__inner">
        {renderStep(currentStep)}
      </div>
    </div>
  );
}

GuestHouseRegisterPage.propTypes = {
  getHouseRegisterInfo: PropTypes.func.isRequired,
  registerHouseGuest: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  getHouseRegisterInfo: (uniqueHouseId) =>
    dispatch(prePromptOperation.getHouseRegisterInfo(uniqueHouseId)),
  registerHouseGuest: (houseId, values) =>
    dispatch(prePromptOperation.registerHouseGuest(houseId, values)),
  addToast: (options) => dispatch(toastOperations.addToast(options)),
});

export default connect(null, mapDispatch)(GuestHouseRegisterPage);
