import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { globalOperations } from "../../duck";
import SwitchHousesTable from "../common/SwitchHousesTable";
import SwitchHousesTableItem from "../common/SwitchHousesTableItem";
function SwitchHouses({ currentHomeId, userData, fetchUserHouses, houses }) {
  useEffect(() => {
    const settingsPageWrapper = document.querySelector(
      ".settings-page-content"
    );
    if (settingsPageWrapper) {
      disableBodyScroll(settingsPageWrapper);
    }
    return () => {
      enableBodyScroll(settingsPageWrapper);
    };
  });

  useEffect(() => {
    // update house data when mounting switch houses component
    fetchUserHouses();
  }, [fetchUserHouses]);

  return (
    <div className="switch-houses" data-testid="switch-houses">
      <div className="switch-houses__inner">
        <div className="switch-houses__list">
          <SwitchHousesTable>
            {houses.map((house) => (
              <SwitchHousesTableItem
                id={house.id}
                image={house.image}
                permission={house}
                houseName={house.houseName}
                fullAddress={house.address.fullAddress}
                link={`/app/${house.id}`}
                disabled={Number(currentHomeId) === Number(house.id)}
              />
            ))}
          </SwitchHousesTable>
        </div>
      </div>
    </div>
  );
}

SwitchHouses.propTypes = {
  currentHomeId: PropTypes.number,
  userData: PropTypes.object,
};

const mapState = (state) => ({
  currentHomeId: state.dashboardReducer.currentHomeId,
  userData: state.userReducer.user,
  houses: state.userReducer.houses,
});

const mapDispatch = (dispatch) => ({
  fetchUserHouses: () => dispatch(globalOperations.fetchUserHouses()),
});

export default connect(mapState, mapDispatch)(SwitchHouses);
