import React from "react";
import PropTypes from "prop-types";

function ListItem({ children, index }) {
  return (
    <li className="list-item">
      <div className="list-item__number">
        <span>{index}</span>
      </div>
      <div className="list-item__content">{children}</div>
    </li>
  );
}

ListItem.propTypes = {
  index: PropTypes.number.isRequired,
};

export default ListItem;
