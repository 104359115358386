import React, {useEffect} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import _get from "lodash.get";
import Actions from '../dashboard/duck/actions';
import {connect, useDispatch} from "react-redux";
import { ReactComponent as SwipeSVG } from "../../assets/icons/explore-360clean.svg";

function FeatureEmbed({ url, isPopup = false, setPreviousPath }) {

  const dispatch = useDispatch();

  useEffect(() => {

	if (window.location.search) {
	  // 360s like this: /app/13/houseFeatures/inHouse?feature=null&objectType=houseFeatures&type=feature&id=762
	  // so we set the path name to previous to return
	  // if it doesn't have query params it's an ovverride on a dashbox so we don't need to update the path
	  setPreviousPath(window.location.pathname);
	}

	const handleHotspotClick = (event) => {
	  const source = _get(event, 'data.source');

	  if (source && source.includes('react-devtools')) {
		return;
	  }

	  if (event.data === "hotspot-click") {
		document.getElementById('swipe-arrow').style.display = 'none';
		dispatch(Actions.toggleHideBack(true));
	  }

	  if (event.data === "hotspot-close") {
		document.getElementById('swipe-arrow').style.display = 'block';
		dispatch(Actions.toggleHideBack(false));
	  }
	};

	window.addEventListener("message", handleHotspotClick);

	return () => {
	  dispatch(Actions.toggleHideBack(false));
	  dispatch({
		type: 'CLEAR_CURRENT_FEATURE',
	  });
	  window.removeEventListener("message", handleHotspotClick);
	};
  }, []);

  return (
	<div className={clsx({
	  'feature-embed-container': true,
	  'feature-embed-popup' : isPopup
	})}>
	  <iframe
		src={url}
		width="100%"
		height="100%"
		data-testid="use-embed-standard"
		frameBorder="0"
		style={{
		  height: '100% !important'
		}}
		webkitallowfullscreen="true"
		mozallowfullscreen="true"
		allowFullScreen={true}
	  ></iframe>
	  <div id="swipe-arrow" style={{
		position: 'fixed',
		bottom: '10px',
		zIndex: 9999,
		left: 'calc(50% - 80px)',
		width: '160px',
		height: 'auto',
		color: '#fff',
		fill: '#fff'
	  }}>
		<SwipeSVG />
	  </div>
	</div>
  );
}

FeatureEmbed.propTypes = {
  url: PropTypes.string.isRequired,
  isPopup: PropTypes.bool
};

const mapDispatch = (dispatch) => ({
  setPreviousPath: (path) =>
	dispatch({
	  type: 'SET_PREVIOUS_PATH',
	  previousPath: path
	}),
});

export default connect(null, mapDispatch)(FeatureEmbed);
