import React, { useRef, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";

import MapContentMarkup from "../app/common/MapContentMarkup";

/* useGoogleMapDirections
 *  A hook for rendering a google map with direction route between an origin and destination.
 *
 *  Arguments:
 *
 *  mapNode<React RefObject> - A react ref for the element that you would like to
 *  attach the map to.
 *
 *
 *  originObject<HCLocationObject> - An object literal that describes the origin
 *  of the route. See below for HCLocationObject shape. This value should be memoized.
 *
 *
 *  destinationObject<HCLocationObject> - An object literal that describes of destination
 *  of the route. See below for HCLocationObject shape.
 *
 *
 *
 *
 *  Types:
 *
 *  HCLocationObject - an object literal describing a location. It has 5 propertys:
 *    - name(required): A string that describes the location. This will be used as a fallback
 *      during geocoding if no address or lat/lng is given.
 *    - address: A string giving the address of the location.
 *    - lat: the latitude of the location
 *    - lng: the longitude of the location. Used together with lat during geocoding
 *      if no address is given.
 *    - type(required): A string denoting if this is a property or feature location.
 *    - isProtected(required): A boolean that denotes if the address or other sensitive info need to be
 *      hidden from the user.
 */

const useGoogleMapDirections = (mapNode, originObject, destinationObject) => {
  const geocoderInstance = useRef(null);
  const infoWindowInstance = useRef(null);
  const directionsServiceInstance = useRef(null);
  const mapInstance = useRef(null);
  const markersInstance = useRef({ feature: null, property: null });
  const clickListeners = useRef([]);

  const [startAddress, setStartAddress] = useState();
  const [endAddress, setEndAddress] = useState();

  useEffect(() => {
    // config for google.maps.Map instance
    const mapConfig = {
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
    };

    // generating markup for infowindow
    const generateContentMarkup = (header, formattedAddress, destinationObject) => {
      const content = ReactDOMServer.renderToString(
        <MapContentMarkup.Property
          address={formattedAddress}
          propertyName={header}
		  destination={destinationObject}
        />
      );

      return content;
    };

    // handler for setting content on marker click
    const handleMarkerClick = (type) => {
      // generate markup for the current marker type

      const isProtected = markersInstance.current[type].customData.isProtected;

      const contentMarkup = generateContentMarkup(
        markersInstance.current[type].customData.name,
        isProtected ? null : markersInstance.current[type].customData.address,
		destinationObject
      );

      // set infowindow content
      infoWindowInstance.current.setContent(contentMarkup);

      // open infowindow
      infoWindowInstance.current.open(
        mapInstance.current,
        markersInstance.current[type]
      );
    };

    // create marker based on type, with location object
    const createMarkerByType = (
      type,
      { lat, lng, address, name, isProtected }
    ) => {
      // set the marker in the markersInstance ref
      markersInstance.current[type] = new window.google.maps.Marker({
        map: mapInstance.current,
        position: { lat, lng },
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0), // anchor
        icon: {
          url:
            type === "property"
              ? "/assets/map/propertyPin.png"
              : "/assets/map/featurePin.png",
          scaledSize: new window.google.maps.Size(37.5, 60),
        },
        customData: {
          address,
          lat,
          lng,
          name,
          isProtected,
        },
      });

      // if their is no event listener, return
      if (!window.google.maps.event) return;

      const handleMarkerClickWithType = handleMarkerClick.bind(this, type);

      // handle when marker is clicked
      clickListeners.current = [
        ...clickListeners.current,
        window.google.maps.event.addListener(
          markersInstance.current[type],
          "click",
          handleMarkerClickWithType
        ),
      ];
    };

    // calculate route for the property -> feature
    const calculateRoute = (directionsDisplay, origin, destination) => {
      // if there is no given full address, use its address as destination. Otherwise, use the lat lng

      directionsServiceInstance.current.route(
        {
          origin: origin.formatted_address,
          destination: destination.formatted_address,
          travelMode: "DRIVING",
        },
        (response, status) => {
          if (status === "OK") {
            // if ok, get the leg of the route
            const route = response.routes[0].legs[0];

            // get lat, lng, and geocoded addr for property
            const updatedOriginData = {
              ...origin,
              lat: route.start_location.lat(),
              lng: route.start_location.lng(),
              address: route.start_address,
            };
            setStartAddress(route.start_address);

            // get lat, lng, and geocoded addr for feature
            const updatedDestinationData = {
              ...destination,
              lat: route.end_location.lat(),
              lng: route.end_location.lng(),
              address: route.end_address,
            };
            setEndAddress(route.end_address);

            // Set directions
            directionsDisplay.setDirections(response);

            // create custom markers for property + feature
            createMarkerByType("property", updatedOriginData);
            createMarkerByType("feature", updatedDestinationData);
          } else {
            // const originGeocode = geocodeQuery({
            //   query: origin.address.query,
            //   type: origin.address.type
            // });
            // const destinationGeocode = geocodeQuery({
            //   query: destination.address.query,
            //   type: destination.address.type
            // });

            createMarkerByType("property", {
              lat: origin.geometry.location.lat(),
              lng: origin.geometry.location.lng(),
              name: origin.name,
              address: origin.formatted_address,
              isProtected: false,
            });
            // setStartAddress(originResult.address);
            createMarkerByType("feature", {
              lat: destination.geometry.location.lat(),
              lng: destination.geometry.location.lng(),
              name: destination.name,
              address: destination.formatted_address,
              isProtected: false,
            });
            // setEndAddress(destinationResult.address);
            const markerKeys = Object.keys(markersInstance.current);
            const bounds = new window.google.maps.LatLngBounds();
            for (var i = 0; i < markerKeys.length; i++) {
              if (markersInstance.current[markerKeys[i]]) {
                bounds.extend(
                  markersInstance.current[markerKeys[i]].getPosition()
                );
              }
            }

            mapInstance.current.fitBounds(bounds);
          }
        }
      );
    };

    // make sure that google is initialized and that the map node exists
    if (window.google && mapNode.current) {
      // Alias for google.maps handler
      const maps = window.google.maps;

      // DirectionsService instance
      directionsServiceInstance.current = new maps.DirectionsService();

      // geocoder instance
      geocoderInstance.current = new maps.Geocoder();

      // inforwindow instance
      infoWindowInstance.current = new window.google.maps.InfoWindow();

      // initialize the google map with the node and given config
      mapInstance.current = new maps.Map(mapNode.current, mapConfig);

      // create directionsRenderer instance
      const directionsDisplay = new maps.DirectionsRenderer({
        map: mapInstance.current,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#4880EA",
        },
      });

      // set the target map to directions renderer
      directionsDisplay.setMap(mapInstance.current);
      calculateRoute(directionsDisplay, originObject, destinationObject);
    }

    return () => {
      // remove all marker listeners

      if (clickListeners.current && clickListeners.current.length > 0) {
        clickListeners.current.forEach((listener) => {
          window.google.maps.event.removeListener(listener);
        });
      }
    };
  }, [destinationObject, mapNode, originObject]);

  return [mapInstance.current, startAddress, endAddress];
};

export default useGoogleMapDirections;
