import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PillButton from "../common/PillButton";
import { Redirect } from "react-router-dom";

import _get from "lodash.get";

function DashboardNotFound({ firstUserHouseId }) {
  if (!firstUserHouseId) {
    return <Redirect to="/manage/login" />;
  }

  return (
    <div className="dashboard-not-found" data-testid="dashboard-not-found">
      <h2>Uh Oh.</h2>
      <p>There was an issue getting your requested page. </p>
      <p>
        Make sure that the link you are requesting is valid. If you believe this
        is an error on our part, please contact{" "}
        <a href="mailto:help@homeconcierge.com">help@homeconcierge.com</a>.
      </p>
      <PillButton link={{ pathname: `/app/${firstUserHouseId}` }} color="blue">
        Back To Home
      </PillButton>
    </div>
  );
}

DashboardNotFound.propTypes = {
  match: PropTypes.object,
  firstUserHouseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const mapState = state => ({
  firstUserHouseId: _get(state.userReducer, "houses[0].id", null)
});
export default connect(mapState)(DashboardNotFound);
