import React, { useState } from "react";
import PropTypes from "prop-types";
import SettingsButton from "../SettingsButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { globalOperations } from "../../../duck";
import _get from "lodash.get";

import SettingsSection from "../SettingsSection";
import isHomeAdminSelector from "../../../selectors/isHomeAdmin";
import hasManagedHomeSelector from "../../../selectors/HasManagedHomeSelector";

function RootContent({
  history,
  logoutUser,
  logoutLoading,
  currentHomeId,
  isHomeAdmin,
  currentPlatform,
  currentCameraHomeId,
  hasManagedHome,
}) {
  const [platform] = useState(currentPlatform);

  const handleLogout = () => {
    logoutUser()
      .then(() => {
        history.push("/manage/login");
      })
      .catch((err) => {
        window.console.error(err);
        history.push("/manage/login");
      });
  };

  return (
    <>
      <div className="settings-container">
        <SettingsSection>
          <SettingsButton
            settingKey="help"
            type="page"
            url="/userSettings/contact"
          >
            Contact
          </SettingsButton>
          <SettingsButton
            settingKey="privacy-policy"
            type="page"
            url={"/userSettings/privacy-policy"}
          >
            Privacy Policy
          </SettingsButton>
          <SettingsButton
            settingKey="terms-and-conditions"
            type="page"
            url={"/userSettings/terms-and-conditions"}
          >
            Terms & Conditions
          </SettingsButton>
        </SettingsSection>
        <SettingsSection>
          {isHomeAdmin && platform === "camera" && (
            <SettingsButton
              settingKey="backToApp"
              type="action"
              url={`/app/${currentHomeId || currentCameraHomeId || ""}`}
            >
              Back to app
            </SettingsButton>
          )}
          <SettingsButton
            settingKey="switch-houses"
            type="page"
            url="/userSettings/switch-houses"
          >
            Switch Homes
          </SettingsButton>
          <SettingsButton
            onClick={handleLogout}
            settingKey="logout"
            loading={logoutLoading}
            type="action"
          >
            Logout
          </SettingsButton>
        </SettingsSection>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  cameraToggle: state.settingsPageReducer.cameraToggle,
  logoutLoading: _get(state.loadingReducer, "logout.loading", false),
  currentHomeId: _get(state.dashboardReducer, "currentHomeId", false),
  isHomeAdmin: isHomeAdminSelector(state),
  currentPlatform: _get(state.userReducer, "currentPlatform", null),
  currentCameraHomeId: _get(state.camera, "currentHomeId", null),
  hasManagedHome: hasManagedHomeSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(globalOperations.logoutUser()),
});
RootContent.propTypes = {
  cameraToggle: PropTypes.bool,

  history: PropTypes.object,
  logoutUser: PropTypes.func,
  logoutLoading: PropTypes.bool,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootContent)
);
