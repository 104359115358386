import React from "react";

function SwitchHousesTable({ children }) {
  return (
    <div className="switch-houses-table">
      <div className="switch-houses-table__header">
        <h5>House</h5>
        <h5>Address</h5>
      </div>
      <div className="switch-houses-list__content">{children}</div>
    </div>
  );
}

export default SwitchHousesTable;
