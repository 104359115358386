import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import NoHousePhotos169SVG from "./svg/no-house-photos-16-9.svg";

function ResponsiveImage({ imageArray, ...rest }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    setError(true);
    setLoading(false);
  };

  const mobilePicture = imageArray
    ? imageArray.find(({ size }) => size === "mobile")
    : null;
  const desktopPicture = imageArray
    ? imageArray.find(({ size }) => size === "tablet")
    : null;

  return imageArray && imageArray.length > 0 && error === false ? (
    <div className="responsive-image">
      <CSSTransition in={loading} classNames="fade" timeout={250} unmountOnExit>
        <div className="responsive-image__loader">
          <div className="spinner-loader" />
        </div>
      </CSSTransition>

	  <picture {...rest}>
		{mobilePicture && (
		  <source
			media="(max-width: 769px)"
			srcSet={encodeURI(mobilePicture.url)}
		  />
		)}
		{desktopPicture && (
		  <>
			<source
			  media="(min-width: 770px)"
			  srcSet={encodeURI(desktopPicture.url)}
			/>
			<img
			  src={encodeURI(desktopPicture.url)}
			  alt="Slider"
			  onLoad={onLoad}
			  onError={onError}
			  data-testid="responsive-image-img"
			/>
		  </>
		)}
	  </picture>
    </div>
  ) : (
	<></>
  );
}

ResponsiveImage.propTypes = {
  imageArray: PropTypes.array
};

export default memo(ResponsiveImage);
