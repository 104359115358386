import React from "react";
import PropTypes from "prop-types";

function PillButtonWrapper({ onClick, url, children }) {
  if (onClick && !url) {
    return <button onClick={onClick}>{children}</button>;
  }
  if (url) {
    return (
      <a
        href={url}
        onClick={onClick ? onClick : null}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }
  return null;
}

function PopupPill({ onClick, type, url, children, ...rest }) {
  return (
    <div className={"pill-item " + type} {...rest}>
      <PillButtonWrapper onClick={onClick} url={url}>
        {children}
      </PillButtonWrapper>
    </div>
  );
}

PopupPill.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  url: PropTypes.string,
  type: PropTypes.string,
};

export default PopupPill;
