import React from "react";
import openMap from "../../utilities/openMap";
import computeAddrString from "../../utilities/computeAddrString";

function ContentMarkupProperty({ isRestricted, address, propertyName, destination }) {
  return (
    <div className="content-markup-inner">
      <h3>{propertyName}</h3>
      {!isRestricted && address && (
		<p>
		  {address}
		  <a
			href={openMap(computeAddrString(
			  { types: destination.types, formatted_address: address, name: propertyName },
			  { withName: true }
			))}
			rel="noopener noreferrer" style={{marginTop: '5px', display: 'block'}}
		  >
			Get Directions
		  </a>
		</p>
	  )}
	</div>
  );
}

function ContentMarkupDestination({destination}) {
  const currDayOfWeek = new Date().getDay();

  const currDayOfWeekKey = (currDayKey) => {
	const dayArray = [6, 0, 1, 2, 3, 4, 5];
    if (dayArray) return dayArray[currDayKey];
  };

  const calculateDayInitial = (dayIndex) => {
    switch (dayIndex) {
      case 0: {
        return <span>M</span>;
      }
      case 1: {
        return <span>T</span>;
      }
      case 2: {
        return <span>W</span>;
      }
      case 3: {
        return <span>TH</span>;
      }
      case 4: {
        return <span>F</span>;
      }
      case 5: {
        return <span>S</span>;
      }
      case 6: {
        return <span>S</span>;
      }
      default: {
        return null;
      }
    }
  };
  const stripDayHours = (weekdayText) => {
    const regex = /Monday:|Tuesday:|Wednesday:|Thursday:|Friday:|Saturday:|Sunday:|AM|PM/g;

    const newText = weekdayText.replace(regex, "");

    if (newText === " Open 24 hours") {
      return "Open 24 hrs";
    }

    return newText;
  };

  const { name, website, fullAddress, phone, openingHours } = destination;

  return (
    <div className="content-markup-inner">
      <h3>{name}</h3>
      <p className="destination-directions-website">
        <a rel="noopener noreferrer" href={website}>
          {website}
        </a>
      </p>
      <p className="destination-directions-address">{fullAddress}</p>
      {phone && (
        <p>
          Phone:{" "}
          <a href={`tel:${phone.replace(/[()|/\-|/\s]/g, "")}`}>{phone}</a>
        </p>
      )}
      <p>
        <div className="destination-directions-business-hours">
          {openingHours &&
            openingHours.weekday_text.map((weekdayText, i) => (
              <p
                key={weekdayText}
                className={`destination-directions-day-hours ${
                  i === currDayOfWeekKey(currDayOfWeek) ? "current-day" : ""
                }`}
              >
                {calculateDayInitial(i)} {stripDayHours(weekdayText, i)}
              </p>
            ))}
        </div>
      </p>

      <a
        className="destination-directions-cta"
        href={openMap(destination.fullAddress)}
        target={"_blank"}
        rel="noopener noreferrer"
      >
        Get directions
      </a>
    </div>
  );
}

export default {
  Property: ContentMarkupProperty,
  Destination: ContentMarkupDestination,
};
