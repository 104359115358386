import types from "./types";

let id = 1;
function createToast(options) {
  const defaultOptions = {
    intent: "success",
    text: ""
  };
  return {
    ...defaultOptions,
    ...options,
    id: id++
  };
}

const addToast = (options = {}) => {
  return {
    type: types.ADD_TOAST,
    payload: createToast(options)
  };
};

const removeToast = id => {
  return {
    type: types.REMOVE_TOAST,
    payload: id
  };
};

export default {
  addToast,
  removeToast
};
