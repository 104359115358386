import React, { useRef, useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";

const Context = React.createContext();

export function ModalProvider({ children, lighten = false, darken = false }) {
  const modalRef = useRef();
  const [context, setContext] = useState();

  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <div
      className={clsx({
        "modal-provider": true,
        lighten: lighten && !darken,
        darken
      })}
    >
      <Context.Provider value={context}>{children}</Context.Provider>

      <div className="modal-container" ref={modalRef} />
    </div>
  );
}

export function Modal({
  onClose,
  children,
  className,
  arrow = false,
  ...props
}) {
  const modalNode = useContext(Context);
  const modalContentNode = useRef(null);

  useEffect(() => {
    const checkClick = evt => {
      if (!modalContentNode) return;
      if (!modalContentNode.current) return;
      if (modalContentNode.current.contains(evt.target)) {
        return;
      }
      onClose();
    };
    document.addEventListener("mousedown", checkClick, false);
    return () => {
      document.removeEventListener("mousedown", checkClick, false);
    };
  }, [onClose, modalContentNode]);

  if (!modalNode) return null;

  return ReactDOM.createPortal(
    <div
      className={`modal ${className || ""} ${arrow ? "arrow" : ""}`}
      {...props}
    >
      <div className="modal__inner">
        <div className="modal__content" ref={modalContentNode}>
          <div className="modal__close close-feature close-feature--close">
            <button
              className="close-feature-btn icon-close"
              data-testid="modal-close-btn"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal__children">{children}</div>
        </div>
      </div>
    </div>,
    modalNode
  );
}
