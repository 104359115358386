import React, { useState, useEffect } from "react";

const GeoLocationLoader = () => {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const dotTimeout = setTimeout(() => {
      if (dotCount === 3) {
        setDotCount(1);
        return;
      }
      setDotCount(dotCount + 1);
    }, 400);

    return () => {
      clearTimeout(dotTimeout);
    };
  }, [dotCount]);

  return (
    <div className="geolocation-loading">
      <h2>
        Loading your location
        <span className="geolocation-loading__ellipsis">
          {Array(dotCount)
            .fill(0)
            .map((_, index) => (
              <span key={index}>.</span>
            ))}
        </span>
      </h2>
    </div>
  );
};

export default GeoLocationLoader;
