import React from "react";
import { Formik } from "formik";
import Input from "../common/Input";
import * as Yup from "yup";
import PillButton from "../common/PillButton";
import ErrorRenderer from "../common/ErrorRenderer";
import clsx from "clsx";

const GuestCreatePageFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email Address is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  createPassword: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("You must confirm your password.")
    .test("password-match", "Passwords do not match.", function (password) {
      const { createPassword } = this.parent;
      return createPassword === password;
    }),
});

function GuestCreatePageForm({
  email = "",
  firstName = "",
  lastName = "",
  withExtraFields = false,
  onSubmit,
}) {
  return (
    <div
      className={clsx("guest-create-form", {
        "with-extra-fields": withExtraFields,
      })}
    >
      <Formik
        onSubmit={onSubmit}
        validationSchema={GuestCreatePageFormSchema}
        initialValues={{
          email,
          firstName,
          lastName,
          createPassword: "",
          confirmPassword: "",
        }}
      >
        {({
          handleBlur,
          errors,
          handleChange,
          values,
          handleSubmit,
          touched,
          setFieldValue,
          isSubmitting,
        }) => (
          <>
            <ErrorRenderer touched={touched} errors={errors} />
            <h4>
              {withExtraFields ? "Create your account" : "Create your password"}
            </h4>

            <form onSubmit={handleSubmit}>
              <div className="guest-create-form__fields">
                {withExtraFields && (
                  <>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email Address*"
                      value={values.email}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                    <Input
                      type="first-name"
                      name="firstName"
                      placeholder="First Name*"
                      value={values.firstName}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                    <Input
                      type="last-name"
                      name="lastName"
                      placeholder="Last Name*"
                      value={values.lastName}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </>
                )}
                <Input
                  type="password"
                  name="createPassword"
                  placeholder="Create password*"
                  value={values.createPassword}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <Input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password*"
                  value={values.confirmPassword}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </div>
              <div className="guest-create-form__terms">
                {/* @TODO: Add correct terms and service link */}
                <p>
                  By clicking Sign Up, you agree to Our{" "}
                  <a
                    href="https://homeconcierge.com/t%2cc%2c-priv-guest-app.html"
                    rel="noopener noreferrer"
                  >
                    Terms Of Service
                  </a>{" "}
                  and that you have read our{" "}
                  <a
                    href="https://homeconcierge.com/t%2cc%2c-priv-guest-app.html"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              <div className="guest-create-form__submit">
                <PillButton type="submit">
                  {isSubmitting ? (
                    <div className="spinner-loader" />
                  ) : (
                    "View Home Now"
                  )}
                </PillButton>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
}

export default GuestCreatePageForm;
