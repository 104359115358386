import React, {useEffect, Suspense, useState} from "react";
import { useLocation, Route, useParams, useHistory } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import Helmet from "react-helmet";
import DashboardHeaderContainer from "../common/DashboardHeaderContainer";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { PullToRefresh } from "../../utilities/PullToRefresh";
import DashboardFooterContainer from "../common/DashboardFooterContainer";
import InteractionPopup from "../common/InteractionPopup";

import { connect } from "react-redux";
import { dashboardOperations } from "./duck";
import { globalOperations } from "../../duck";

import _get from "lodash.get";
import { toastOperations } from "../toast/duck";
import DashboardComponent from "./DashboardComponent";
import { ModalProvider } from "../common/Modal";
import OrientationProvider from "../common/OrientationProvider";
import { isCheckedOutSelector } from "../../selectors/isRestrictedSelector";
import RouteProvider from "../common/RouteProvider";
import GeolocationProvider from "../common/GeolocationProvider";
import OutboundWarningProvider from "../common/OutboundWarningProvider";
import InstallPromptProvider from "../common/InstallPromptProvider";
import trimEnd from 'lodash/trimEnd';

const FeatureRoot = React.lazy(() => import("../featurepage/FeatureRoot"));

function DashboardRoot({
  isLoggedIn,
  fetchInitData,
  setCurrentPlatform,
  propertyName,
  pageName,
  isCurrentPageSet,
  initLoading,
  previousPath,
  isHome,
  match,
  pageKey,
  parentKey,
  pageLoadStatus,
  addToast,
  refreshInitData,
  currentHomeId,
  isCheckedOut,
  currentPage,
  currentFeatureAppConfig,
  hideBack
}) {
  const location = useLocation();
  const history = useHistory();
  const { homeId } = useParams();

  const [featurePageName] = useQueryParam("feature", StringParam);
  const [popupName] = useQueryParam("popup", StringParam);

  const [shouldShowFooter, setShouldShowFooter] = useState(true);

  const [componentClass, setComponentClass] = useState('');

  const isIframed = window !== window.parent;

  // fetch initial data on load
  useEffect(() => {
    const handleFetchInitData = () => {
      fetchInitData(homeId, "", "/");
    };

	setCurrentPlatform("app");

	if (parseInt(homeId) !== parseInt(currentHomeId)) handleFetchInitData();
  }, [currentHomeId, fetchInitData, homeId, isLoggedIn, setCurrentPlatform]);

  // scroll to top of dashboard when changing path
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
	const hideFooterOnPage = _get(currentPage, 'guestApp.hideFooter', false);
	const hideFooterOnFeature = _get(currentFeatureAppConfig, 'hideFooter', false);
	if (hideFooterOnPage || hideFooterOnFeature) {
	  setShouldShowFooter(false);
	} else {
	  setShouldShowFooter(true);
	}

	setComponentClass(_get(currentPage, 'guestApp.type', _get(currentFeatureAppConfig, 'type', '')));

  }, [currentFeatureAppConfig, currentPage])

  const handleRefresh = () => {
    return new Promise((resolve, reject) => {
      refreshInitData()
        .then(() => {
          addToast({ text: "Successfully updated app." });
          resolve();
        })
        .catch(({ message, error }) => {
          if (message === "Couldnt find page") {
            // if the current page no longer exists, add toast and redirect to home
            addToast({
              text: "The page you were on no longer exists.",
              intent: "warning",
            });
            history.push({
              pathname: `/app/${homeId || ""}`,
            });
            resolve();
          } else {
            addToast({
              text: "We were unable to refresh your application. Please try again later.",
              intent: "error",
            });
            resolve();
          }
        });
    });
  };

  const metaTitle = 'Home Concierge ' + (propertyName ? pageName && pageName !== "home" ? `| ${propertyName} | ${pageName}`
	  : `| ${propertyName}` : '');

  return (
    <InstallPromptProvider>
      <GeolocationProvider disabled={isCheckedOut}>
        <OrientationProvider landscape={false}>
		  <Helmet>
			<title>
			  {metaTitle}
			</title>
			<meta
			  name="description"
			  content={`Explore ${
				propertyName ? propertyName : ""
			  } for your upcoming vacation!`}
			/>
			<meta property="og:title" content={metaTitle}/>
		  </Helmet>
		  <ModalProvider lighten>
			<RouteProvider>
			  <div
				id="dashboard-main"
				className={"fadeIn" + (isIframed ? ' iframed' : '') + (shouldShowFooter ? ' has-footer ' : ' no-footer ') + componentClass}
                data-testid="dashboard-main"
              >
                <PullToRefresh onRefresh={handleRefresh}>
                  {isCurrentPageSet && !initLoading && (
                    <DashboardHeaderContainer
                      isHome={isHome}
                      platform="dashboard"
                      propertyName={propertyName}
                      pageName={pageName}
                      previousPath={previousPath}
					  hideBack={hideBack}
                    />
                  )}

                  {!initLoading && (
                    <OutboundWarningProvider>
                      <Route
                        path={`${match.path}`}
                        component={DashboardComponent}
                      />
                    </OutboundWarningProvider>
                  )}
                </PullToRefresh>
                <CSSTransition
                  in={featurePageName ? true : false}
                  timeout={{ enter: 250, exit: 200 }}
                  classNames="fade"
                  unmountOnExit
                  appear
                >
                  <Suspense fallback={null}>
                    <OutboundWarningProvider>
                      <FeatureRoot />
                    </OutboundWarningProvider>
                  </Suspense>
                </CSSTransition>
                {isCurrentPageSet && !initLoading && shouldShowFooter && (
                  <DashboardFooterContainer
                    isHome={isHome}
                    currentPageKey={pageKey}
                    currentParentKey={parentKey}
                    status={pageLoadStatus}
                  />
                )}

                {isCurrentPageSet && !initLoading ? (
                  <TransitionGroup component={null}>
                    <CSSTransition
                      key={popupName}
                      timeout={175}
                      classNames="fade"
                      mountOnEnter
                      appear
                      unmountOnExit
                    >
                      <InteractionPopup currentPopupName={popupName} />
                    </CSSTransition>
                  </TransitionGroup>
                ) : null}
              </div>
            </RouteProvider>
          </ModalProvider>
        </OrientationProvider>
      </GeolocationProvider>
    </InstallPromptProvider>
  );
}

const mapState = (state) => ({
  isLoggedIn: Boolean(state.userReducer.user),
  propertyName: state.dashboardReducer.propertyInfo.propertyName,
  pageName: _get(state.dashboardReducer, "currentPage.pageName", null),
  isCurrentPageSet: state.dashboardReducer.currentPage ? true : false,
  initLoading: _get(state.loadingReducer, "init.loading", true),
  previousPath: _get(state.dashboardReducer, "previousPath", null),
  isHome: _get(state.dashboardReducer, "currentPage.pageName", null) === "home",
  pageKey: state.dashboardReducer.currentPage
    ? state.dashboardReducer.currentPage.pageKey
    : null,
  parentKey: state.dashboardReducer.currentPage
    ? state.dashboardReducer.currentPage.parentKey
    : null,
  pageLoadStatus: _get(state.loadingReducer, "page.status", null),
  currentHomeId: _get(state.dashboardReducer, "currentHomeId", null),
  isCheckedOut: isCheckedOutSelector(state),
  user: state.userReducer.user,
  fallbackToken: state.userReducer.fallbackToken,
  currentPage: _get(state.dashboardReducer, "currentPage.page", null),
  currentFeatureAppConfig: _get(state.dashboardReducer, "currentFeature.featureData.guestApp", null),
  hideBack: _get(state.dashboardReducer, "hideBack", false),
});

const mapDispatch = (dispatch) => ({
  fetchInitData: (homeId, pageKey, previousPath) => {
    return dispatch(
      dashboardOperations.fetchInitData(homeId, pageKey, previousPath)
    );
  },
  setCurrentPlatform: (platform) =>
    dispatch(globalOperations.setCurrentPlatform(platform)),
  refreshInitData: () => {
    return dispatch(dashboardOperations.refreshInitData());
  },
  addToast: (options) => dispatch(toastOperations.addToast(options)),
});

export default connect(mapState, mapDispatch)(DashboardRoot);
