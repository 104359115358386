import React from "react";
import PillButton from "./PillButton";

function GeneralError({ currentHomeId }) {
  return (
    <div className="general-error" data-testid="general-error">
      <h2>Uh Oh.</h2>
      <p>There was an issue with displaying the page. </p>
      <p>
        If this issue persists, please contact
        <a href="mailto:help@homeconcierge.com">help@homeconcierge.com</a> for
        assistance.
      </p>
      <PillButton color="blue" onClick={() => (document.location.href = "/")}>
        Return to Dashboard
      </PillButton>
    </div>
  );
}

export default GeneralError;
